// import { DDomain } from "~/services/config/config-types"
import * as server from "../server-api"

export interface StripeCreateSubscriptionParams {
	priceId: string
	paymentMethodId: string
	email?: string
	fbc?: string
	fbp?: string
	amplitudeDeviceId?: string
	amplitudeUserId?: string
}

/**
 * Creates a new subscription.
 * Throws an error if there's an error.
 */
export const stripeCreateSubscription = async ({
	paymentMethodId,
	priceId,
	email,
	fbc,
	fbp,
	amplitudeDeviceId,
	amplitudeUserId,
}: StripeCreateSubscriptionParams) => {
	const domain = process.env.REACT_APP_DOMAIN ?? "course"

	try {
		const response = await server.stripeCreateSubscriptionForAnonymousUser({
			paymentMethodId,
			priceId,
			email,
			fbc,
			fbp,
			amplitudeDeviceId,
			amplitudeUserId,
			domain,
		})

		const { error, subscription } = response

		if (error) {
			const msg = error.message
				? `Server response: ${error.message}`
				: "There's an error when trying to attach the card to a customer."
			throw new Error(msg)
		}

		return subscription
	} catch (ex) {
		console.error(ex)
		throw ex
	}
}
