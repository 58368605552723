import { RefObject } from "react"

import { PopupCloseHandler, PopupRef } from "./popup-component"

class PopupClass {
	ref: RefObject<PopupRef> = { current: null }

	applyRef = (ref: RefObject<PopupRef>) => {
		this.ref = ref
	}

	open = () => {
		this.ref.current?.open()
	}

	close = () => {
		this.ref.current?.close()
	}

	openContent = (el: JSX.Element) => {
		this.ref.current?.openContent(el)
	}

	setNode = (el: JSX.Element) => {
		this.ref.current?.setNode(el)
	}

	setCloseHandler = (handler: PopupCloseHandler) => {
		this.ref.current?.setCloseHandler(handler)
	}
}

export const Popup = new PopupClass()
