import { useState } from "react"
import React, { forwardRef, useCallback } from "react"

type PromocodeNode<A> = (args: A) => React.ReactNode
export type PromocodeProps = {
	closed: PromocodeNode<{
		openPromocode: () => void
	}>
	opened: PromocodeNode<{
		promocode: string
		onChangePromocode: (event: React.ChangeEvent<HTMLInputElement>) => void
		applyPromocode: () => void
	}>
	applied: PromocodeNode<{
		promocode: string
	}>
}
export const Promocode = forwardRef(function Promocode(
	{ closed: closedNode, opened: openedNode, applied: appliedNode }: PromocodeProps,
	ref: React.ForwardedRef<HTMLSpanElement>
) {
	const [state, setState] = useState({ value: "", opened: false, applied: false })

	const openPromocode = useCallback(() => {
		setState({ value: "", opened: true, applied: false })
	}, [])
	const onChangePromocode = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => {
		setState({ value: evt.currentTarget.value, opened: true, applied: false })
	}, [])
	const applyPromocode = useCallback(() => {
		setState((prevState) => ({ value: prevState.value, opened: true, applied: true }))
	}, [])

	return (
		<span ref={ref}>
			{() => {
				if (!state.opened) return closedNode({ openPromocode })
				if (!state.applied) return openedNode({ promocode: state.value, onChangePromocode, applyPromocode })

				return appliedNode({ promocode: state.value })
			}}
		</span>
	)
})
