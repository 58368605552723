import styled from "styled-components"
import { Bubble } from "~/components2/atoms/Bubble"
import { Flexed } from "~/components2/atoms/Flexed"

export const Wrap = styled(Bubble)<{ selected: boolean }>`
	padding: 0;
	border: 2px solid ${({ selected }) => (selected ? "#5856D6" : "transparent")};
	overflow: hidden;
`

export const Title = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;

	margin: 0;

	color: #000000;
`

export const Content = styled(Flexed)<{ isPopular: boolean }>`
	min-width: auto;
	padding: ${({ isPopular }) => (isPopular ? 16 : 24)}px 16px;
`

export const PopularWrap = styled.div`
	padding: 8px 16px;
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;

	color: #ffffff;
	background-color: #5856d6;
`
