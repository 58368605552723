import { I18n, setupI18n, MessageDescriptor } from "@lingui/core"
import { detect, fromNavigator } from "@lingui/detect-locale"
import { defineMessage } from "@lingui/macro"
import * as plural from "make-plural/plurals"
import { II18nService } from "~/contexts/i18n-service-context"
import { useQueryParams } from "~/hooks/use-query-params"
// In case of dinamic load and activate - do not import these messages
import { messages as ar } from "../../locales/ar/messages"
import { messages as de } from "../../locales/de/messages"
import { messages as en } from "../../locales/en/messages"
import { messages as es } from "../../locales/es/messages"
import { messages as fr } from "../../locales/fr/messages"
import { messages as hi } from "../../locales/hi/messages"
import { messages as it } from "../../locales/it/messages"
import { messages as ja } from "../../locales/ja/messages"
import { messages as ko } from "../../locales/ko/messages"
import { messages as pt } from "../../locales/pt/messages"
import { messages as ru } from "../../locales/ru/messages"
import { messages as zh } from "../../locales/zh-CN/messages"

// In case of dinamic load and activate - just create an object with required keys
export const allMessages = { en, fr, it, de, es, ar, pt, ru, hi, ko, ja, "zh-cn": zh }

export type LanguageCode = keyof typeof allMessages

export const languageCodes = Object.keys(allMessages).map((x) => x.toLowerCase().trim()) as LanguageCode[]

export const languageDescriptors: { [key in LanguageCode]: MessageDescriptor } = {
	en: defineMessage({ message: "English" }),
	fr: defineMessage({ message: "French" }),
	it: defineMessage({ message: "Italian" }),
	de: defineMessage({ message: "Deutsch" }),
	es: defineMessage({ message: "Spanish" }),
	ar: defineMessage({ message: "Arabic" }),
	ja: defineMessage({ message: "Japanese" }),
	ko: defineMessage({ message: "Korean" }),
	pt: defineMessage({ message: "Portuguese" }),
	ru: defineMessage({ message: "Russian" }),
	hi: defineMessage({ message: "Hindi" }),
	"zh-cn": defineMessage({ message: "Chinese Simplified" }),
}

export const defaultLangCode: LanguageCode = "en"

export const detectedLangCode = detect(fromNavigator(), defaultLangCode) ?? defaultLangCode

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const languageUrlParam = urlParams.get("lang")

type LangSettings = null | "Default" | "Code" | "Browser"

let langSettingsAnalytics: LangSettings = null

//console.log("___LNG___", urlParams, languageUrlParam, languageUrlParam === null)

export const getNavigatorLangCode: () => LanguageCode | string = () => {
	langSettingsAnalytics = "Default"

	if (!!languageUrlParam && languageCodes.indexOf(languageUrlParam as LanguageCode) >= 0) {
		//console.log("___LNG___", languageUrlParam)
		langSettingsAnalytics = "Code"
		return languageUrlParam
	}
	if (!!languageUrlParam && languageUrlParam === "int") {
		langSettingsAnalytics = "Browser"
		return detectedLangCode
	}

	return "en"
}

export const navigatorLangCode = getNavigatorLangCode()
//console.log("___detectedLangCode___", detectedLangCode, defaultLangCode, languageUrlParam, navigatorLangCode)

export const langSettings: LangSettings = langSettingsAnalytics

//console.log("___NAVIGATOR CODE___", navigatorLangCode)

export const plurals = Object.keys(allMessages).reduce(
	(prev, curr) => ({
		...prev,
		[curr]: { plurals: curr === "zh-cn" ? plural["zh"] : plural[curr as Exclude<LanguageCode, "zh-cn">] },
	}),
	{}
)

export const loadAllLocals = (i18nObj: I18n) => {
	i18nObj.loadLocaleData(plurals)
	i18nObj.load(allMessages)
	// console.log(plurals);
}

export const activate = (i18nObj: I18n, locale: LanguageCode | string) => {
	i18nObj.activate(locale)
}

// export const dynamicLoadAndActivate = async (locale: LanguageCode) => {
//   const { messages } = await import(`./locales/${locale}/messages`);
//   i18nService.load(locale, messages);
//   i18nService.activate(locale);
// };

const missing = (language: string, id: string) => {
	// eslint-disable-next-line no-console
	console.error(`Translation in ${language} for ${id} is missing!`)
	// eslint-disable-next-line no-alert
	// alert(`Translation in ${language} for ${id} is missing!`);
	return `'🚨${id}`
}

export const i18nCustom: I18n = setupI18n({ missing })

// export const i18nCustomService: II18nService<string> = {
// 	loadLangs(): void {
// 		loadAllLocals(i18nCustom)
// 	},
// 	codeList(): string[] {
// 		return languageCodes
// 	},
// 	langList(): Record<string, string> {
// 		const langs: Record<string, string> = Object.keys(languageDescriptors).reduce(
// 			(prev, curr) => ({
// 				...prev,
// 				[curr]: i18nCustom._(languageDescriptors[curr as LanguageCode]),
// 			}),
// 			{}
// 		)

// 		return langs
// 	},
// 	switchLang(langCode: string): boolean {
// 		if ((languageCodes as string[]).includes(langCode)) {
// 			activate(i18nCustom, langCode as LanguageCode)
// 			return true
// 		}
// 		activate(i18nCustom, defaultLangCode())
// 		return false
// 	},
// 	currentCode: i18nCustom.locale,
// 	i18n: i18nCustom,
// }

export class I18nCustomService {
	constructor(defaultLangCode: string) {
		const persistedLang = this.loadPersistedLang()
		let lang = defaultLangCode || "en"
		if (persistedLang != null) {
			lang = persistedLang
		} else if (languageUrlParam != null) {
			lang = languageUrlParam
		}
		this.loadLangs()
		this.switchLang(lang)
		// eslint-disable-next-line max-len
		setTimeout(() => this.persistLang(lang), 1500) // Магия, если не делать по таймауту то результат возвращается сразу же в loadPersistedLang
		/* старая версия с выбором дефолтного языка
		const persistedLang = this.loadPersistedLang()

		// eslint-disable-next-line no-nested-ternary
		console.log("DEBUG", persistedLang, defaultLangCode, languageUrlParam, langSettings)
		// eslint-disable-next-line no-nested-ternary
		const lang = persistedLang ? (langSettings === "Code" ? defaultLangCode : persistedLang) : "en"
		this.loadLangs()
		this.switchLang(lang)
		this.persistLang(lang)
		*/
	}

	persistLang(lang: string) {
		window.localStorage.setItem("app-lang", lang)
	}

	loadPersistedLang() {
		const lng = window.localStorage.getItem("app-lang")
		return lng
	}

	loadLangs(): void {
		loadAllLocals(i18nCustom)
	}

	codeList(): string[] {
		return languageCodes
	}

	langList(): Record<string, string> {
		const langs: Record<string, string> = Object.keys(languageDescriptors).reduce(
			(prev, curr) => ({
				...prev,
				[curr]: i18nCustom._(languageDescriptors[curr as LanguageCode]),
			}),
			{}
		)

		return langs
	}

	switchLang(langCode: string): boolean {
		if ((languageCodes as string[]).includes(langCode)) {
			activate(i18nCustom, langCode as LanguageCode)
			return true
		}
		activate(i18nCustom, defaultLangCode)
		return false
	}

	currentCode = i18nCustom.locale
	i18n = i18nCustom
}

export const i18nCustomService = new I18nCustomService(navigatorLangCode)
