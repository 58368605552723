import { defineMessage, t, Trans } from "@lingui/macro"
import { DSubscriptionValue } from "~/services/config/config-types"
import { i18nCustom } from "~/services/service/i18n-service"
import { StripeSubscriptionPriceInfo } from "~/subscriptions-web/server-api"
import { PriceBubble, PriceTitle, PriceLength, PriceValue, PriceOld, Crossed, PriceSale, Price } from "../styles"

interface PriceTodayProps {
	product: DSubscriptionValue
	paywallPrice: StripeSubscriptionPriceInfo | null
}

export function PriceToday({ product, paywallPrice }: PriceTodayProps) {
	defineMessage({ message: "Days" })
	defineMessage({ message: "Day" })

	return (
		<PriceBubble margin="40px 0 12px">
			<PriceTitle>
				<Trans>Price today</Trans>
			</PriceTitle>
			<Price>
				<span>
					<PriceLength>
						{product?.trial?.trialPeriodDays}-
						{(product?.trial?.trialPeriodDays ?? 0) > 1 ? i18nCustom._("Days") : i18nCustom._("Day")}{" "}
						<Trans>trial</Trans>
					</PriceLength>
					<PriceSale>
						<Trans>Unlocked</Trans>
					</PriceSale>
				</span>
				<span>
					<PriceValue>${((product?.trial?.amount ?? 0) / 100).toFixed(2)}</PriceValue>
					<PriceOld>
						<Crossed>${(product?.oldPrice ?? 0) / 100}</Crossed>
					</PriceOld>
				</span>
			</Price>
		</PriceBubble>
	)
}
