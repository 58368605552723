import { amplitudeService } from "~/services/amplitude"
import { analytics } from "~/services/analytics"
import { useUpgradeAnonymousUser } from "~/subscriptions-web/hooks/use-upgrade-anonymous-user"
import { EmailScreen } from "./email-screen"

export interface IEmailScreenContainerProps {
	label: string
	description: string
	onNext: (email: string) => void
}

export const EmailScreenContainer = (props: IEmailScreenContainerProps) => {
	const { upgradeAnonymousUser } = useUpgradeAnonymousUser()

	const localOnNext = async (email: string) => {
		amplitudeService.setIdentify((identify) => {
			identify.set("Email", email)
		})
		amplitudeService.logEvent("[All] Onboarding | Email", { email })
		analytics.track("sign_up", { email })
		try {
			await upgradeAnonymousUser(email)
		} catch (_) {}
		props.onNext(email)
	}

	return <EmailScreen title={props.label} description={props.description} onNext={localOnNext} />
}
