import { Loader } from "@components/loader/loader"
import { defineMessage, t, Trans } from "@lingui/macro"
import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js"
import { useEffect, useRef, useState } from "react"

import ApplePayLogo from "~/assets/ApplePay.svg"
import BluePlus from "~/assets/BluePlus.svg"
// import DesktopWhiteStar from "~/assets/DesktopWhiteStar.svg"
import GooglePayLogo from "~/assets/GooglePay.svg"
import MastercardPure from "~/assets/MastercardPure.svg"
import PaypalIcon from "~/assets/PayPal.svg"
// import PaywallStar from "~/assets/PaywallStar.svg"
import SSLLogo from "~/assets/SSLLogo.svg"
import StripeLogo from "~/assets/StripeLogo.svg"
import VisaPure from "~/assets/VisaPure.svg"
import { Block } from "~/components2/atoms/Block"
import { Button } from "~/components2/atoms/Button"
import { Flexed } from "~/components2/atoms/Flexed"
import { Input } from "~/components2/atoms/Input"
import { List } from "~/components2/atoms/List"
import { ReviewsCarousel } from "~/components2/atoms/Reviews"
import { TextBody, TextWithIcon, Colored } from "~/components2/atoms/Text"

import { useWindowDimensions } from "~/hooks/use-window-dimensions"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { amplitudeService } from "~/services/amplitude"
import { DPaywallType } from "~/services/config/config-types"
import { i18nCustom } from "~/services/service/i18n-service"
import { PayPalButton } from "~/subscriptions-web/paypal/paypal-button"
import { usePaywall } from "../use-paywall"
import { AvailableOn } from "./atoms/available-on"
import { Copyright } from "./atoms/copyright"
import { PrePaywall } from "./atoms/prepaywall"
import { PrePaywallTime } from "./atoms/prepaywall-time"
import { PriceAfterTrial } from "./atoms/price-after-trial"
import { PriceToday } from "./atoms/price-today"
import { TrialInformation } from "./atoms/trial-info"
import { PaywallZipInput } from "./paywall-zip-input"
import { Promocode } from "./promocode"

import {
	Wrapper,
	ContentWrapper,
	Content,
	Background,
	ExpandedArea,
	Markets,
	MediumBlock,
	charlieBenefits,
	reviewData,
	PaymentBubble,
	PaymentBlock,
	PaymentTitle,
	PaymentLogos,
	PaymentLabel,
	PaymentDataInput,
	PaymentMultiInputWrapper,
	ExpirationInput,
	CVCInput,
	TimerBox,
	PaymentError,
	ReviewsWrapper,
	TryTitle,
	PriceInfo,
	PaymentMethods,
	PaymentMethod,
	ApplePayWrapper,
	ApplePayBtn,
	PayPalButtonWrapper,
	PayPalButtonContainer,
} from "./styles"

interface IProps {
	onNext?: (params?: any) => void
}

export const DeltaPaywall = (props: IProps) => {
	const layout = useWindowLayout()
	const timerRef = useRef<HTMLDivElement>(null)
	const timeRef = useRef<HTMLDivElement>(null)
	const addressZipRef = useRef<HTMLInputElement>(null)

	const windowSize = useWindowDimensions()

	const [focused, setFocused] = useState(false)

	const {
		product,
		paywallPrice,
		// stripeShowPaymentRequestDialog,
		agreed,
		setAgreed,
		timeLeft,
		addressZip,
		visibleTimer,
		submitProgress,
		errorToDisplay,
		stripeElementProps,
		paywallWeeklyPrice,
		createCardPaySubscription,
		createPayPalSubscription,
		handlePayPalApprove,
		handleScroll,
		// stripePaymentAPI,
		logPaypalEvent,
		webPrepaywallType,
	} = usePaywall(DPaywallType.Delta, addressZipRef, timeRef)

	useEffect(() => {
		if (timerRef.current) {
			if (visibleTimer) {
				timerRef.current.style.top = "12px"
			} else {
				timerRef.current.style.top = "-40px"
			}
		}
	}, [visibleTimer])

	useEffect(() => {
		if (agreed) {
			if (timeRef.current) {
				timeRef.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				})
			}
		}
	}, [agreed])

	defineMessage({ message: "Paying..." })
	defineMessage({ message: "Day" })
	defineMessage({ message: "Days" })

	const paywallForm = document.getElementById("paywall-form")

	useEffect(() => {
		if (window.Paddle.Checkout) {
			//console.log("+++CHECKOUT LOADED+++", window.Paddle.Checkout)
			//console.log("+++PAYWALL FORM+++", paywallForm)
			if (paywallForm) {
				// window.Paddle.Setup({ vendor: 149811 })
				//console.log("+++CHECKOUT LOADED 2+++", window.Paddle.Checkout)
				// window.Paddle.Environment.set("sandbox")
				// if (process.env.REACT_APP_PADDLE_ID)
				// window.Paddle.Setup({ vendor: 149811 })
				// window.Paddle.Setup({ vendor: +process.env.REACT_APP_PADDLE_ID })
				window.Paddle.Checkout.open({
					country: "CY",
					email: localStorage.getItem("email"),
					postcode: "",
					// currency: subscriptionInfo.currency,
					method: "inline",
					product: 33033,
					// product: 783113,
					// allowQuantity: false,
					// disableLogout: true,
					frameTarget: "paywall-payment-form",
					frameInitialHeight: 416,
					// Please ensure the minimum width is kept at or above 286px with checkout padding disabled,
					// or 312px with checkout padding enabled.
					// See "General" section under "Branded Inline Checkout" below for more information on checkout padding.
					frameStyle: "width:100%; min-width:480px; background-color: transparent; border: none;",
					// passthrough: { user_id: currentUser!.uid },
					// successCallback: onSubscriptionSuccess,
					// closeCallback: onSubscriptionClose,
				})
				//("+++CHECKOUT LOADED 3+++", window.Paddle.Checkout)
			}
		}
	}, [paywallForm])

	if (webPrepaywallType && product) {
		return (
			<Wrapper windowHeight={windowSize.height}>
				<Background agreed={agreed}>
					{/* <WhiteStarWrapper alt="" src={PaywallStar} /> */}
					{/* <DesktopWhiteStarWrapper alt="" src={DesktopWhiteStar} /> */}
				</Background>
				<ContentWrapper id="wrapper" onScroll={handleScroll}>
					<PrePaywall
						webPrepaywallType={webPrepaywallType}
						agreed={agreed}
						setAgreed={setAgreed}
						paywallPrice={paywallPrice!}
						// ChosenAreas={ChosenAreas}
					/>

					<Content windowHeight={windowSize.height}>
						{agreed && (
							<ExpandedArea>
								<TimerBox ref={timerRef}>
									<p>
										<Trans>Plan reserved for</Trans>{" "}
									</p>
									<span>
										{Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
									</span>
								</TimerBox>

								<PrePaywallTime
									windowHeight={windowSize.height}
									webPrepaywallType={webPrepaywallType}
									ref={timeRef}
									timeLeft={timeLeft}
								/>

								<TryTitle layout={layout} size="big" margin="-40px 0 14px" centered>
									<Trans>Try</Trans> {product.trial?.trialPeriodDays} <Trans>Trial</Trans>
									{(product.trial?.trialPeriodDays ?? 0) > 1 ? "Days" : "Day"} <br />
									<Trans>with</Trans>{" "}
									<Colored color="">
										<Trans>Glowbe Course</Trans>
									</Colored>
								</TryTitle>

								<span id="benefits">
									<MediumBlock>
										<List align="left" items={charlieBenefits} />
									</MediumBlock>
								</span>

								<MediumBlock>
									<PriceToday product={product} paywallPrice={paywallPrice} />

									<PriceInfo>{product.labels.description}</PriceInfo>

									<PriceAfterTrial paywallWeeklyPrice={paywallWeeklyPrice} paywallPrice={paywallPrice} />

									<Promocode
										closed={({ openPromocode }) => (
											<TextWithIcon color="#5856D6" margin="24px 0 40px" icon={BluePlus} onClick={openPromocode}>
												<Trans>Add a promocode</Trans>
											</TextWithIcon>
										)}
										opened={({ promocode, onChangePromocode, applyPromocode }) => (
											<Block margin="20px 0 8px">
												<TextBody size="big" color="rgba(130,134,145,0.5)">
													<Trans>Promocode</Trans>
												</TextBody>
												<Input
													placeholder="6-digits code"
													value={promocode}
													type="other"
													onChange={onChangePromocode}
													width="100%"
													maxLength={6}
												/>
												<Button
													disabled={promocode.length === 0}
													color="blue"
													title="Apply"
													width="100%"
													onClick={applyPromocode}
												/>
											</Block>
										)}
										applied={({ promocode }) => (
											<TextBody centered size="big" margin="20px 0 40px">
												<Trans>You've successfuly applied promocode</Trans>{" "}
												<Colored color="#5856d6">{promocode.toUpperCase()}</Colored>{" "}
												<Trans>and recieved one more month</Trans>{" "}
												<Colored color="#5856d6">
													<Trans>for free.</Trans>
												</Colored>{" "}
												<Trans>Thank you!</Trans>
											</TextBody>
										)}
									/>

									<TextBody centered size="big" color="#828691" margin="40px 0 16px">
										<Trans>Users about Us</Trans>
									</TextBody>

									<ReviewsWrapper>
										<ReviewsCarousel items={reviewData} dots />
									</ReviewsWrapper>
									{!!window.Paddle.Checkout.Loaded && (
										<ApplePayWrapper>
											<p>
												<Trans>Payment Method</Trans>
											</p>
											<small>
												<Trans>
													Start your {product.trial?.trialPeriodDays}{" "}
													{(product.trial?.trialPeriodDays ?? 0) > 1 ? t`Days` : t`Day`} Trial with
												</Trans>
											</small>
											{/* <ApplePayBtn onClick={stripeShowPaymentRequestDialog}>
												<img alt="" src={stripePaymentAPI.paymentType.applePay ? ApplePayLogo : GooglePayLogo} />
											</ApplePayBtn> */}
											<span>
												<p>
													<Trans>or</Trans>
												</p>
											</span>
										</ApplePayWrapper>
									)}
									<PaymentMethods>
										<PaymentMethod active>
											<img alt="" src={VisaPure} />
											<img alt="" src={MastercardPure} />
										</PaymentMethod>
										<PayPalButtonWrapper>
											<img alt="" src={PaypalIcon} />
											<PayPalButtonContainer>
												<PayPalButton
													options={{
														vault: true,
														intent: "subscription",
														clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
													}}
													style={{ color: "white", size: "responsive" }}
													createSubscription={createPayPalSubscription}
													onApprove={handlePayPalApprove}
													onClick={logPaypalEvent}
												/>
											</PayPalButtonContainer>
										</PayPalButtonWrapper>
									</PaymentMethods>

									{/* <form id="payment-form" onSubmit={createCardPaySubscription}>
										<PaymentBubble>
											<PaymentTitle>
												<Trans>Payment Info</Trans>
											</PaymentTitle>
											<PaymentLogos paywallType="bravo">
												<img alt="" src={StripeLogo} />
												<img alt="" src={SSLLogo} />
											</PaymentLogos>
											<PaymentBlock fullwidth>
												<PaymentLabel>
													<Trans>Card</Trans>
												</PaymentLabel>
												<PaymentDataInput>
													<CardNumberElement
														{...stripeElementProps}
														onFocus={() => {
															if (errorToDisplay) {
																setFocused(true)
															}
															amplitudeService.logEvent("[All] Paywall | Card number")
														}}
														onBlur={() => setFocused(false)}
													/>
												</PaymentDataInput>
											</PaymentBlock>
											<PaymentBlock fullwidth>
												<PaymentLabel>
													<Trans>Expires on</Trans>
												</PaymentLabel>
												<PaymentMultiInputWrapper>
													<ExpirationInput>
														<CardExpiryElement
															{...stripeElementProps}
															onFocus={() => {
																if (errorToDisplay) {
																	setFocused(true)
																}
																amplitudeService.logEvent("[All] Paywall | Expires date")
															}}
															onBlur={() => setFocused(false)}
														/>
													</ExpirationInput>
													<CVCInput>
														<CardCvcElement
															{...stripeElementProps}
															onFocus={() => {
																if (errorToDisplay) {
																	setFocused(true)
																}
																amplitudeService.logEvent("[All] Paywall | CVC")
															}}
															onBlur={() => setFocused(false)}
														/>
													</CVCInput>
													<PaywallZipInput
														ref={addressZipRef}
														addressZip={addressZip}
														onFocus={() => {
															if (errorToDisplay) {
																setFocused(true)
															}
														}}
														onBlur={() => setFocused(false)}
													/>
												</PaymentMultiInputWrapper>
											</PaymentBlock>
										</PaymentBubble>

										<Button
											type="submit"
											color="blue"
											margin="24px 0 24px"
											title={submitProgress ? i18nCustom._("Paying...") : product.labels.button}
											width="100%"
										/>

										{errorToDisplay && errorToDisplay.length && !focused && (
											<PaymentError>{errorToDisplay}</PaymentError>
										)}
									</form> */}

									<div id="paywall-form" className="paywall-payment-form"></div>

									<Markets />

									<TrialInformation
										paywallType={DPaywallType.Delta}
										trialPeriodDays={product.trial.trialPeriodDays}
										paywallPrice={paywallPrice}
									/>

									<Flexed direction="row" justify="center">
										<PaymentLogos paywallType="bravo">
											<img alt="" src={StripeLogo} />
											<img alt="" src={SSLLogo} />
										</PaymentLogos>
									</Flexed>
								</MediumBlock>

								<AvailableOn />

								<Copyright />
							</ExpandedArea>
						)}
					</Content>
				</ContentWrapper>
			</Wrapper>
		)
	} else return <Loader />
}
