import { Trans } from "@lingui/macro"
import { useState, useEffect, useCallback } from "react"
import { useHistory } from "react-router"
import styled from "styled-components"
import { Header } from "~/components2/atoms/Header"
import { useAuthContext, usePreferenceContext } from "~/subscriptions-web/context"
import { ConfirmEmailScreenContainer } from "~/views/success/screens/confirm-email-screen/confirm-email-screen.container"
import { DownloadAppScreenContainer } from "~/views/success/screens/download-screen/download-app-screen.container"

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background-color: #f6f6f6;
	box-sizing: border-box;

	z-index: 50;
`

export const ConfirmEmailScreenFlow = () => {
	const [currentStep, setCurrentStep] = useState(0)
	// const { currentUser } = useAuthContext()
	// const { preferences } = usePreferenceContext()
	const email = localStorage.getItem("email") ?? ""
	// const email = localStorage.getItem("email") ?? currentUser?.email ?? preferences.email ?? ""

	const history = useHistory()

	const onNext = useCallback(() => {
		//console.log("set cur step 1")
		// setCurrentStep(1)
		history.push("/download")
	}, [history])

	const onPrev = useCallback(() => {
		//console.log("set cur step 0")
		setCurrentStep(0)
	}, [])

	useEffect(() => {
		//console.log("email", localStorage.getItem("email"))
	}, [])

	switch (currentStep) {
		case 0:
			return (
				<Wrapper>
					<Header lightBurger desktopSimplified />
					<ConfirmEmailScreenContainer currentEmail={email} onNext={onNext} />
				</Wrapper>
			)
		case 1:
			return (
				<Wrapper>
					<Header lightBurger desktopSimplified />
					<DownloadAppScreenContainer onPrev={onPrev} />
				</Wrapper>
			)
	}

	return (
		<>
			<Trans>Loading your purchase.... (SHOWN IN A MOMENT BETWEEN currentStep -1 and 0)</Trans>
		</>
	)
}
