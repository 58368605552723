import styled from "styled-components"
import { typography } from "~/components/shared/typography"

export const AnimatedTextWrapper = styled.div<{ isBottom?: boolean }>`
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	/* height: 140px; */
	width: 100%;
	overflow: hidden;
	top: ${({ isBottom }) => (isBottom ? "calc(50% - 0px)" : "calc(50% - 180px)")};
	left: 0;
	padding: 0;
	z-index: 9999;
`

export const AnimatedText = styled.p`
	display: inline-block;
	position: relative;
	width: auto;
	top: 0;
	left: 0;
	color: #fff;

	box-sizing: border-box;
	padding: 0 20px;
	margin: 0;

	font-family: ${typography.family.inter};
	font-style: ${typography.fontStyle.default};
	font-weight: ${typography.fontWeight.extrabold};
	font-size: 32px;
	line-height: 110%;

	text-align: center;
	letter-spacing: ${typography.letterSpacing.h}em;
`

export const ActionButtonWrapper = styled.div`
	position: absolute;
	width: 100%;
	height: 200px;
	bottom: 0;
	left: 0;

	display: flex;
	justify-content: center;
	align-items: center;
`
