import { ApiResponse, API_RESPONSE_STATUS, ErrorData } from "~/subscriptions-web/paypal/api/api-response"
import { callFirebaseFunction } from "../call-firebase-function"

export interface IGetPayLinkRequest {
	sortIndex: number
	domain: string
	email: string
	// @ts-check
	returnUrl?: string
	analytics: {
		fbc: string
		fbp: string
		amplitudeDeviceId: string
		amplitudeUserId: string
	}
}

export interface IGetPayLinkResponse {
	success?: boolean
	response?: {
		url: string
	}
}

export const paddleSubscriptionCreate = async (params: IGetPayLinkRequest): Promise<IGetPayLinkResponse> => {
	const response = await callFirebaseFunction<IGetPayLinkRequest, ApiResponse<IGetPayLinkResponse>>(
		"paddleSubscriptionCreateV3",
		params
	)
	if (response.status !== API_RESPONSE_STATUS.SUCCESS) {
		throw new Error(
			`SERVER returned error: status:${response.status} message:${(response.data as ErrorData).message} code:${
				(response.data as ErrorData).error
			}.`
		)
	}
	return response.data as IGetPayLinkResponse
}
