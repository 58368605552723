export enum DDomain {
	Course = "course",
	Yoga = "yoga",
}

export enum DLandingType {
	Alpha = "landing-alpha",
	Bravo = "landing-bravo",
	Charlie = "landing-charlie",
	Delta = "landing-delta",
	Echo = "landing-echo",
}

export enum DPrepaywallType {
	Alpha = "prepaywall-alpha",
	Bravo = "prepaywall-bravo",
}

export interface DPayproConfig {
	templateId: string
	accountIdField: string
	deviceIdField: string
}

export enum DPaywallType {
	Alpha = "paywall-alpha",
	Bravo = "paywall-bravo",
	Charlie = "paywall-charlie",
	Delta = "paywall-delta",
	Echo = "paywall-echo",
	Foxtrot = "paywall-foxtrot",
	Hotel = "paywall-hotel", // TODO:  у нас нет HOTEL  !!!!!!!
	India = "paywall-india",
	Juliett = "paywall-juliett",
	Lima = "paywall-lima",
	Monaco = "paywall-monaco",
	Nepal = "paywall-nepal",
}

export type DOnboardingType = `onboarding-${string}`

export interface SubscriptionValueLabel {
	title: string
	description: string
	button: string
}

export interface DSubscriptionValue {
	paddleProductId: number
	payproPriceId: number
	payproCoupon?: string
	priceId: string
	planId: string
	oldPrice: number
	trial: {
		amount: number
		currency: string
		trialPeriodDays: number
	}
	plan?: {
		popular?: boolean
		name: string
		amount: number
		days: number
	}
	paywall?: DPaywallType
	labels: SubscriptionValueLabel
	locales: Record<string, SubscriptionValueLabel>
}

export type DPaywallFirstScreenValue = "no" | "yes"

export type DWebLandingSkip = true | false
export interface IDConfig {
	webLandingSkip: DWebLandingSkip
	webLandingType: DLandingType
	webOnboardingType: DOnboardingType
	webOnboardingTypeMan: DOnboardingType
	webPrepaywallType: DPrepaywallType
	webPaywallType: DPaywallType
	webSubscription: DSubscriptionValue
	paywallFirstScreen: DPaywallFirstScreenValue
	payproConfig: DPayproConfig
}

export interface IDConfigService {
	fetchConfig(langCode?: string): Promise<IDConfig>
}
