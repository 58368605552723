import { Trans } from "@lingui/macro"
import { forwardRef, ReactElement, Ref } from "react"

import ArrowDown from "~/assets/ArrowDown.svg"
import { Colored, TextBody, TextHead } from "~/components2/atoms/Text"
import { DPrepaywallType } from "~/services/config/config-types"

import { MobileBreak, TimeBlock, TimeWrapper } from "../styles"

type Variants = "primary" | "secondary"

export interface PrePaywallTimeProps {
	windowHeight: number
	webPrepaywallType: DPrepaywallType
	timeLeft: number
	showDownText?: boolean
	variant?: Variants
	discountPercent?: number
}

export const PrePaywallTime = forwardRef<HTMLDivElement, PrePaywallTimeProps>(
	(
		{
			windowHeight,
			webPrepaywallType,
			timeLeft,
			showDownText = true,
			variant = "primary",
			discountPercent,
		}: PrePaywallTimeProps,
		ref: Ref<HTMLDivElement>
	): ReactElement => {
		const isPrimary = variant === "primary"
		// TODO: Remove hardcode when localization is ready
		return (
			<TimeWrapper ref={ref} windowHeight={windowHeight} isDark={webPrepaywallType === DPrepaywallType.Bravo}>
				<TimeBlock>
					<TextBody size="big">
						{isPrimary ? (
							<Trans>Your personal course has been reserved for the next</Trans>
						) : (
							<>
								<Trans>Your personal course has been reserved for the next</Trans> <Trans>15 minutes!</Trans>
							</>
						)}{" "}
						<Colored color="#6967ec">
							<MobileBreak />
							{isPrimary ? (
								<Trans>15 minutes!</Trans>
							) : (
								<>
									<Trans>with</Trans> {discountPercent} <Trans>discount</Trans>
								</>
							)}
						</Colored>
					</TextBody>
					<TextHead size="big" color="#5856EA">
						{Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
					</TextHead>
					{showDownText && (
						<span style={{ display: "flex", flexDirection: "row" }}>
							<TextBody size="big" color="#828691">
								<Trans>Keep it to yourself below</Trans>{" "}
							</TextBody>
							<img alt="" src={ArrowDown} />
						</span>
					)}
				</TimeBlock>
			</TimeWrapper>
		)
	}
)
