import { LayoutType } from "@components/shared/layouts"
import { Trans } from "@lingui/macro"

import { MobileWrapper, DescriptionWithLinks, DesktopWrapper, Description, Links } from "./copyrights.styles"

export interface ICopyrightsProps {
	size: keyof typeof LayoutType
}

const termsLink = "https://docs.glowbe.com/terms.pdf"
const privacyLink = "https://docs.glowbe.com/privacy.pdf"

export const Copyrights = (props: ICopyrightsProps) => {
	if (props.size === LayoutType.Mobile) {
		return (
			<MobileWrapper>
				<DescriptionWithLinks>
					<Trans>By clicking “See my Results” below you agree to Glowbe's </Trans>
					<a href={termsLink} target="_blank" rel="noreferrer">
						{" "}
						<Trans>Terms and Conditions</Trans>
					</a>{" "}
					<Trans>and</Trans>{" "}
					<a href={privacyLink} target="_blank" rel="noreferrer">
						<Trans>Privacy Policy</Trans>
					</a>
					.
				</DescriptionWithLinks>
			</MobileWrapper>
		)
	}
	return (
		<DesktopWrapper>
			<Description>
				<Trans>
					*Glowbe does not share any personal information. We'll email you a copy of your results for conventiont
					access.
				</Trans>
			</Description>
			<Links>
				<a href={privacyLink} target="_blank" rel="noreferrer">
					<Trans>Privacy Policy</Trans>
				</a>
				<a href={termsLink} target="_blank" rel="noreferrer">
					<Trans>Terms and Conditions</Trans>
				</a>
			</Links>
		</DesktopWrapper>
	)
}
