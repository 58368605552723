import { Loader } from "@components/loader/loader"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useConfig } from "~/contexts/config-context"
import { useYandexMetrika } from "~/contexts/yandex-metrika-context"
import { useQueryParams } from "~/hooks/use-query-params"
import { DLandingType } from "~/services/config/config-types"
import { useAuthContext } from "~/subscriptions-web/context"
import { callFirebaseFunction } from "~/subscriptions-web/server-api/call-firebase-function"
import { AlphaLandingContainer } from "~/views/landings/screens/alpha-landing/alpha-landing.container"
import { BravoLandingContainer } from "~/views/landings/screens/bravo-landing/bravo-landing.container"
import { CharlieLandingContainer } from "~/views/landings/screens/charlie-landing/charlie-landing.container"
import { DeltaLandingContainer } from "~/views/landings/screens/delta-landing/delta-landing.container"
import { EchoLandingContainer } from "~/views/landings/screens/echo-landing/echo-landing.container"

export const LandingPage = () => {
	const remoteConfig = useConfig()
	const { currentUser } = useAuthContext()
	const [, setTkn] = useState("")
	const history = useHistory()
	const onboardingType = useQueryParams().get("onboardingType")
	useEffect(() => {
		if (remoteConfig?.webLandingSkip) {
			const search =
				history.location.search == null || history.location.search.length === 0 ? null : history.location.search
			if (search) {
				history.push(`/personal-course${search}`)
			} else {
				history.push("/personal-course")
			}
		}
	}, [history, remoteConfig, remoteConfig?.webLandingSkip, onboardingType])
	// useEffect(() => {
	// 	if (remoteConfig?.web_landing_type_v2 === LandingType.Echo) {
	// 		history.push(`/personal-course${onboardingType ? `?onboardingType=${onboardingType}` : ""}`)
	// 	}
	// }, [history, remoteConfig, remoteConfig?.web_landing_type_v2, onboardingType])

	if (currentUser) {
		currentUser.getIdTokenResult(true).then((token1) => {
			setTkn(token1.token)
		})
	}

	useEffect(() => {
		localStorage.setItem("goalsNames", "")
		localStorage.setItem("areasNames", "")
		localStorage.setItem("goalsIds", "")
		localStorage.setItem("areasIds", "")
	}, [])
	//const urlSearchParams = new URLSearchParams(window.location.search).get("onboardingType")
	//console.log("globalThis", globalThis.location.search)
	//console.log("urlSearchParams", typeof urlSearchParams)
	if (!remoteConfig?.webLandingSkip) {
		switch (remoteConfig?.webLandingType) {
			case DLandingType.Alpha:
				return <AlphaLandingContainer />
			case DLandingType.Bravo:
				return <BravoLandingContainer />
			case DLandingType.Charlie:
				return <CharlieLandingContainer />
			case DLandingType.Delta:
				return <DeltaLandingContainer />
			case DLandingType.Echo:
				return <EchoLandingContainer />
			default:
				return <Loader />
		}
	} else {
		return <Loader />
	}
}
