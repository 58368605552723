import { AnimatePresence, motion } from "framer-motion"
import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { Popup } from "~/components/layouts/popup"

import { useConfig } from "~/contexts/config-context"
import { amplitudeService } from "~/services/amplitude"
import { analytics } from "~/services/analytics"
import { SortBox, SubscriptionValueDTO } from "~/services/config/config-dto-types"

import { useAuthContext } from "~/subscriptions-web/context"
import { usePayproEvent } from "~/subscriptions-web/hooks"
import { buildPayproParams } from "~/subscriptions-web/paypro/paypro-link-create"
import { callFirebaseFunction } from "~/subscriptions-web/server-api/call-firebase-function"
import { CountLoader } from "./count-loader"

import { Card, Container, AbsoluteFill, PayproContainer } from "./styles"

type Props = {
	product: SortBox<SubscriptionValueDTO>
}

export const PaymentView = ({ product }: Props) => {
	const history = useHistory()
	const config = useConfig()
	const [isEmailSent, setIsEmailSent] = useState(false)
	const [payproSrc, setPayproSrc] = useState("")
	const [isLoading, setIsLoading] = useState(false)
	const { currentUser } = useAuthContext()
	localStorage.setItem("analitycs_events_sended", "false")
	useEffect(() => {
		if (!config) {
			return
		}
		const src = buildPayproParams({
			payproPriceId: product.value.payproPriceId.toString(),
			payproCoupon: product.value.payproCoupon,
			email: localStorage.getItem("email")!,
			accountId: currentUser?.uid!,
			currency: product.value.trial.currency,
			...config.payproConfig,
		})
		setPayproSrc(src)
		localStorage.setItem("latestProduct", JSON.stringify(product))

	}, [product, config])

	const onSuccess = () => {
		try {
			const _product = product.value
			const amount = _product.trial.amount
			amplitudeService.logEvent("[Subscription] Start trial", { ..._product, trial: { ..._product.trial, amount: amount }, source: "paypro" })
			analytics.track("purchase", {
				product: { ..._product, trial: { ..._product.trial, amount: amount } },
				orderID: "",
			})
			analytics.track("checkout", {
				..._product,
				trial: { ..._product.trial, amount: amount },
			})
			analytics.track("start_trial", {
				..._product,
				trial: { ..._product.trial, amount: amount },
			})
			localStorage.setItem("analitycs_events_sended", "true")
			sendEmail()
		} catch (_) {}
		history.push("/confirm")
		Popup.close()
	}
	usePayproEvent({ handlePaymentSuccess: onSuccess, handlePaymentWaiting: onSuccess })

	const sendEmail = useCallback(async () => {
		if (!isEmailSent) {
			setIsEmailSent(true)
			await callFirebaseFunction("sendManualEmailUser", { email: localStorage.getItem("email") })
		}
	}, [isEmailSent])

	return (
		<Container>
			<AnimatePresence exitBeforeEnter>
				{isLoading && (
					<motion.div exit={{ opacity: 0 }}>
						<AbsoluteFill>
							<CountLoader />
						</AbsoluteFill>
					</motion.div>
				)}
			</AnimatePresence>
			<Card>{payproSrc && <PayproContainer src={payproSrc} onLoad={() => setIsLoading(false)} />}</Card>
		</Container>
	)
}
