import { ChoiceButton } from "@components/buttons"
import { ActionButton } from "@components/buttons"
import { OverlapLayout } from "@components/layouts"
import { Trans } from "@lingui/macro"
import { forwardRef, PropsWithChildren, useState } from "react"
import { useQuestions } from "~/contexts/questions-context/questions-context"
import { useWindowLayout } from "~/hooks/use-window-layout"

import { Wrapper, Question, Shadow } from "./checklist-with-picture-screen.styles"

export interface IChecklistWithPictureScreenProps {
	id: string
	question: string
	imageSrc: string
	options: string[]
	onNext: (props?: { skip?: number, option?: string, options?: string[] }) => void
}

export const ChecklistWithPictureScreen = forwardRef<
	unknown,
	PropsWithChildren<IChecklistWithPictureScreenProps & JSX.IntrinsicElements["div"]>
>(({ ...rest }, ref: any) => {
	const size = useWindowLayout()
	const localAreas = [...useQuestions().areas]
	const localGoals = [...useQuestions().goals]

	const [checked, setChecked] = useState([] as number[])

	const onCheck = (index: number) => {
		const alreadyChecked = checked.indexOf(index)

		if (alreadyChecked === -1) {
			setChecked([...checked, index])
		} else {
			setChecked([...checked].filter((_, index) => index !== alreadyChecked))
		}
	}

	const onClickNext = () => {
		const checkedValues = checked.map((check) => rest.options[check])
		if (rest.id === "areas" || rest.id === "areas_1") {
			const chosenAreas = localAreas.filter((area) => checkedValues.indexOf(area.name) >= 0)
			const chosenAreasNames = chosenAreas.map((area) => area.name)
			const chosenAreasIds = chosenAreas.map((area) => area.id)

			localStorage.setItem("areasNames", chosenAreasNames.join(", "))
			localStorage.setItem("areasIds", chosenAreasIds.join(", "))
			rest.onNext({ options: chosenAreasNames })
		} else if (rest.id === "goals" || rest.id === "goals_1") {
			const chosenGoals = localGoals.filter((goal) => checkedValues.indexOf(goal.name) >= 0)
			const chosenGoalsNames = chosenGoals.map((goal) => goal.name)
			const chosenGoalsIds = chosenGoals.map((goal) => goal.id)

			localStorage.setItem("goalsNames", chosenGoalsNames.join(", "))
			localStorage.setItem("goalsIds", chosenGoalsIds.join(", "))
			rest.onNext({ options: chosenGoalsNames })
		} else {
			rest.onNext()
		}

	}

	return (
		<Wrapper ref={ref} size={size}>
			<Question size={size}>{rest.question}</Question>
			<OverlapLayout src={rest.imageSrc} size={size}>
				{rest.options.map((option, index) => (
					<ChoiceButton
						key={option}
						size={size}
						isCheckable
						isChecked={checked.indexOf(index) !== -1}
						onClick={() => onCheck(index)}
					>
						{option}
					</ChoiceButton>
				))}
			</OverlapLayout>
			<Shadow>
				{checked.length > 0 && (
					<ActionButton onClick={onClickNext} size={size}>
						<Trans>Continue</Trans>
					</ActionButton>
				)}
			</Shadow>
		</Wrapper>
	)
})
