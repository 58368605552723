import Glamour from "@assets/Glamour.png"

import Human1 from "@assets/Human1.png"
import Human2 from "@assets/Human2.png"
import Human3 from "@assets/Human3.png"
import Human4 from "@assets/Human4.png"
import Human5 from "@assets/Human5.png"
import Human6 from "@assets/Human6.png"
import Human7 from "@assets/Human7.png"
import Human8 from "@assets/Human8.png"

import Line1 from "@assets/Line1.svg"
import Line2 from "@assets/Line2.svg"
import Line3 from "@assets/Line3.svg"

import NewLogo from "@assets/NewLogo.png"
import Vogue from "@assets/Vogue.png"

import { ActionButton } from "@components/buttons"
import { Appearance } from "@components/buttons/action-button/action-button"
import { ScreenLayout } from "@components/layouts"
import { Trans } from "@lingui/macro"
import { forwardRef, PropsWithChildren } from "react"
import { useWindowDimensions } from "~/hooks/use-window-dimensions"
import { useWindowLayout } from "~/hooks/use-window-layout"
import {
	DesktopWrapper,
	CenterContent,
	LeftHumansWrapper,
	RightHumansWrapper,
	LeftHumans,
	RightHumans,
	Logo,
	Title,
	Description,
	FashionLogos,
	Background,
	Line1Wrapper,
	Line2Wrapper,
	Line3Wrapper,
	MobileScreen,
	MobileBackground,
	MobileHumansWrapper,
	MobileShadow,
	MobileLine1Wrapper,
	MobileLine2Wrapper,
	MobileLine3Wrapper,
	MobileActionButtonWrapper,
} from "./bravo-prepaywall.styles"

export interface IBravoPrepaywallProps {
	onClickNext: () => void
	agreed?: boolean
}

export const BravoPrepaywall = forwardRef<
	unknown,
	PropsWithChildren<IBravoPrepaywallProps & JSX.IntrinsicElements["div"]>
>(({ ...rest }, ref: any) => {
	const layoutType = useWindowLayout()
	const windowSize = useWindowDimensions()

	if (layoutType === "Mobile") {
		return (
			// <ScreenLayout ref={ref} size={layoutType}>
			<MobileScreen windowHeight={windowSize.height}>
				<MobileBackground>
					<MobileLine1Wrapper alt="" src={Line1} />
					<MobileLine2Wrapper alt="" src={Line2} />
					<MobileHumansWrapper>
						<span>
							<img alt="" src={Human1} />
							<img alt="" src={Human3} />
						</span>
						<span>
							<img alt="" src={Human2} />
							<img alt="" src={Human4} />
						</span>
					</MobileHumansWrapper>
				</MobileBackground>
				<MobileShadow windowHeight={windowSize.height} agreed={rest.agreed}>
					{/* <MobileShadow device={userAgent.iOS ? "ios" : "android"}> */}
					<span>
						<Logo size={layoutType} alt="" src={NewLogo} />
						<Title size={layoutType}>
							<Trans>Take care of your skin, hair, body and mind</Trans>
						</Title>
						<Description size={layoutType}>
							<Trans>92% people feel more beautiful after the first session</Trans>
						</Description>
						<FashionLogos size={layoutType}>
							<img alt="" src={Vogue} />
							<img alt="" src={Glamour} />
						</FashionLogos>
						<MobileLine3Wrapper alt="" src={Line3} />
					</span>
				</MobileShadow>
				<MobileActionButtonWrapper agreed={rest.agreed}>
					{!rest.agreed && (
						<ActionButton size={layoutType} appearance={Appearance.Pink} onClick={rest.onClickNext}>
							<Trans>Get Started</Trans>
						</ActionButton>
					)}
				</MobileActionButtonWrapper>
			</MobileScreen>
			// </ScreenLayout>
		)
	}
	return (
		<ScreenLayout ref={ref} size={layoutType}>
			<DesktopWrapper>
				<Background>
					<Line1Wrapper alt="" src={Line1} />
					<Line2Wrapper alt="" src={Line2} />
					<Line3Wrapper alt="" src={Line3} />
				</Background>
				<LeftHumansWrapper>
					<LeftHumans>
						<span>
							<img alt="" src={Human1} />
							<img alt="" src={Human3} />
						</span>
						<span>
							<img alt="" src={Human2} />
							<img alt="" src={Human4} />
						</span>
					</LeftHumans>
				</LeftHumansWrapper>

				<CenterContent>
					<Logo size={layoutType} alt="" src={NewLogo} />
					<Title size={layoutType}>
						<Trans>Take care of your skin, hair, body and mind</Trans>
					</Title>
					<Description size={layoutType}>
						<Trans>92% people feel more beautiful after the first session</Trans>
					</Description>
					<FashionLogos size={layoutType}>
						<img alt="" src={Vogue} />
						<img alt="" src={Glamour} />
					</FashionLogos>
					{!rest.agreed && (
						<ActionButton size={layoutType} appearance={Appearance.Pink} onClick={rest.onClickNext}>
							<Trans>Get Started</Trans>
						</ActionButton>
					)}
				</CenterContent>

				<RightHumansWrapper>
					<RightHumans>
						<span>
							<img alt="" src={Human5} />
							<img alt="" src={Human7} />
						</span>
						<span>
							<img alt="" src={Human6} />
							<img alt="" src={Human8} />
						</span>
					</RightHumans>
				</RightHumansWrapper>
			</DesktopWrapper>
		</ScreenLayout>
	)
})
