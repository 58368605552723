import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { Loader } from "~/components/loader/loader"
import { useYandexMetrika } from "~/contexts/yandex-metrika-context"
import { useAuthContext } from "~/subscriptions-web/context"
import { ProgressStatus } from "~/subscriptions-web/hooks"
import { useSignInWithIdToken } from "~/subscriptions-web/hooks/use-signin-with-id-token"

export const MobileProfilePage = () => {
	const history = useHistory()
	const { isUserSignedIn } = useAuthContext()
	const { signInWithIdToken, signInWithIdTokenState } = useSignInWithIdToken()
	const yandexMetrika = useYandexMetrika()

	useEffect(() => {
		yandexMetrika.ymTrackPage("/profile")
	}, [yandexMetrika])

	useEffect(() => {
		if (signInWithIdTokenState.status === ProgressStatus.START) {
			const token = new URLSearchParams(window.location.search).get("token") || "0"
			if (token) {
				signInWithIdToken!(token)
			}
		}
	}, [signInWithIdTokenState, signInWithIdToken])

	if (signInWithIdTokenState.status === ProgressStatus.WORKING) {
		return <Loader />
	}

	if (signInWithIdTokenState.status === ProgressStatus.DONE && isUserSignedIn) {
		history.push("/account")
	}

	return <Loader />
}
