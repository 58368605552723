import { Cache } from "@anion155/rixio-utils"
import amplitude, { Identify } from "amplitude-js"
import DeviceDetector from "device-detector-js"
import { II18nService } from "~/contexts/i18n-service-context"
import { currentUser$ } from "~/subscriptions-web/context/auth-context"
import { dConfigService } from "../config/config-service"
import { IDConfigService } from "../config/config-types"
import { i18nCustomService, langSettings } from "../service/i18n-service"
import { cohortIdentify } from "./cohort-identify"
import { platformIdentify } from "./platform-identify"

export class AmplitudeService {
	private async _setIdentity() {
		const identify = new Identify()
		cohortIdentify(identify)
		platformIdentify(identify)

		identify.set("lang", this._i18nService.currentCode)
		// TODO: эта константа должна браться из сервиса
		identify.set("langSettings", langSettings ?? "")

		const config = await this._configService.fetchConfig(this._i18nService.currentCode)

		const onboardingTypeUrl = new URLSearchParams(window.location.search).get("onboardingType")
		const remoteConfigByOnboardingType =
			onboardingTypeUrl === "man" ? config.webOnboardingTypeMan : config.webOnboardingType

		identify
			.set("Landing Screen Skipped", config.webLandingSkip)
			.set("First Screen Enabled", config.paywallFirstScreen)
			.set("Onboarding Type", remoteConfigByOnboardingType)
			.set("Web Subscription", { ...config.webSubscription, locales: undefined })
			.set("Paywall Type", config.webPaywallType)
			.set("Prepaywall Type", config.webPrepaywallType)
			.set("Landing Type", config.webLandingType)
			.set("Domain", process.env.REACT_APP_DOMAIN ?? "course")
			.set(
				"Environment",
				process.env.REACT_APP_FIREBASE_AUTH_CALLBACK_DOMAIN === "https://glowbe-dev.web.app" ? "dev" : "prod"
			)
		// .set(
		// 	"Environment",
		// 	process.env.REACT_APP_ENVIRONMENT === "course" || process.env.REACT_APP_ENVIRONMENT === "yoga" ? "prod" : "dev"
		// )

		amplitude.getInstance().identify(identify)
	}

	constructor(protected _configService: IDConfigService, protected _i18nService: II18nService<string>) {
		this.init.get()
		currentUser$.subscribe((user) => {
			if (user) {
				this.userId = user.uid
			}
		})
	}

	init = new Cache(async () => {
		console.debug("Initializing AmplitudeService")
		amplitude.getInstance().init(process.env.REACT_APP_AMPLITUDE_KEY!, undefined, {
			includeUtm: true,
			includeFbclid: true,
			includeReferrer: true,
			saveEvents: true,
		})

		await this._setIdentity()

		if (this.isNewSession()) {
			const deviceDetector = new DeviceDetector()
			const userAgent = globalThis.navigator.userAgent
			const device = deviceDetector.parse(userAgent)
			const deviceName = `${device.device?.brand} ${device.device?.model}`
			amplitude.getInstance().identify(new Identify().set("Device", deviceName))
			amplitude.getInstance().logEvent("[All] First launch")
		}
		amplitude.getInstance().logEvent("[All] Start")
		console.debug("Amplitude initiated")
	})

	isNewSession() {
		return amplitude.getInstance().isNewSession()
	}

	get deviceId() {
		return amplitude.getInstance().options.deviceId
	}

	get userId() {
		return amplitude.getInstance().options.userId
	}
	set userId(userId: string | undefined) {
		amplitude.getInstance().setUserId(userId ?? null)
	}

	setIdentify(identify: Identify): Promise<void>
	setIdentify(updater: (identify: Identify) => void | Identify | Promise<void | Identify>): Promise<void>
	setIdentify(arg: Identify | { (identify: Identify): void | Identify | Promise<void | Identify> }) {
		let identify: Identify
		if (typeof arg === "function") {
			identify = new Identify()
			arg(identify)
		} else {
			identify = arg
		}
		return new Promise<void>(async (resolve) => {
			await this.init.get()
			amplitude.getInstance().identify(identify, () => resolve())
		})
	}

	logEvent(event: string, data?: any) {
		return new Promise<void>(async (resolve) => {
			await this.init.get()

			amplitude.getInstance().logEvent(event, data, () => resolve())
		})
	}
}

export const amplitudeService = new AmplitudeService(dConfigService, i18nCustomService)
