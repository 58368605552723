import { ChoiceButton } from "@components/buttons"
import { OverlapLayout } from "@components/layouts"
import { forwardRef, PropsWithChildren } from "react"
import { useWindowLayout } from "~/hooks/use-window-layout"

import { Wrapper, Label } from "./select-with-picture-screen.styles"

export interface ISelectWithPictureScreenProps {
	label: string
	imageSrc: string
	options: string[]
	onNext: (props?: { skip?: number, option?: string, options?: string[] }) => void
}

export const SelectWithPictureScreen = forwardRef<
	unknown,
	PropsWithChildren<ISelectWithPictureScreenProps & JSX.IntrinsicElements["div"]>
>(({ ...rest }, ref: any) => {
	const size = useWindowLayout()

	return (
		<Wrapper ref={ref} size={size}>
			<Label size={size}>{rest.label}</Label>
			<OverlapLayout withAdditionalMargin={false} src={rest.imageSrc} size={size}>
				{rest.options.map((option, index) => (
					// eslint-disable-next-line max-len
					<ChoiceButton key={option} size={size} onClick={() => rest.onNext({ skip: index === 0 ? 1 : 0, option: option } )}>
						{option}
					</ChoiceButton>
				))}
			</OverlapLayout>
		</Wrapper>
	)
})
