//import { RESPONSE_CODE } from "./response-codes"
import { callFirebaseFunction } from "../call-firebase-function"

export interface StripeCreateSubscriptionForAnonymousUserParams {
	priceId: string
	paymentMethodId: string
	email?: string
	fbc?: string
	fbp?: string
	amplitudeDeviceId?: string
	amplitudeUserId?: string
	domain: string
}

export interface StripeCreateSubscriptionForAnonymousUserResponse {
	domain: string
	error: any
	subscription: any
}

//TODO: Try catch, add description for this function
export const stripeCreateSubscriptionForAnonymousUser = async (
	params: StripeCreateSubscriptionForAnonymousUserParams
) => {
	const response = await callFirebaseFunction<
		StripeCreateSubscriptionForAnonymousUserParams,
		StripeCreateSubscriptionForAnonymousUserResponse
	>(
		//"createAnonymousSubscripion2",
		"stripeSubscriptionPayV3",
		params
	)
	return response
}
