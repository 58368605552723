import React from "react"
import styled, { css } from "styled-components"
import { InferStyledComponentProps } from "~/utils/types"

type TSpace = "center" | "space-between" | "space-evenly" | "space-around" | "flex-start" | "flex-end"

interface IFlexProps {
	direction?: "row" | "column"
	justify?: TSpace
	align?: TSpace
	width?: string
	height?: string
	onClick?: () => void
	disabled?: boolean
	gap?: number
}

export interface IFlexedProps extends IFlexProps {
	children: React.ReactNode
	onClick?: () => void
}

const FlexedWrapper = styled.div<IFlexProps>`
	display: flex;
	min-width: ${({ width }) => width || "100%"};
	height: ${({ height }) => height || "auto"};

	gap: ${({ gap = 0 }) => gap}px;

	opacity: ${({ disabled }) => (disabled ? 0.6 : 1)};

	${({ direction, justify, align }) => css`
		flex-direction: ${direction || "row"};
		justify-content: ${justify || "space-between"};
		align-items: ${align || "center"};
	`};
`

export function Flexed(props: InferStyledComponentProps<typeof FlexedWrapper>) {
	return <FlexedWrapper {...props}>{props.children}</FlexedWrapper>
}
Flexed.Spacer = styled.div`
	flex: 1;
`
