import { AnimatePresence, motion } from "framer-motion"
import { useCallback, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { Popup } from "~/components/layouts/popup"
import { useI18nService } from "~/contexts/i18n-service-context"

import { amplitudeService } from "~/services/amplitude"
import { analytics } from "~/services/analytics"
import { SortBox, SubscriptionValueDTO } from "~/services/config/config-dto-types"
import { DPaywallType } from "~/services/config/config-types"
import { cookies } from "~/services/cookies"
import { useAuthContext } from "~/subscriptions-web/context"

import { callFirebaseFunction } from "~/subscriptions-web/server-api/call-firebase-function"
import { usePaywall } from "~/views/paywalls/use-paywall"
import { CountLoader } from "./count-loader"

import { Card, Container, AbsoluteFill } from "./styles"

type Props = {
	product: SortBox<SubscriptionValueDTO>
}

export const PaymentView = ({ product }: Props) => {
	const history = useHistory()
	const [isEmailSent, setIsEmailSent] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const I18nService = useI18nService()

	const { createPaddleSubscription } = usePaywall(
		DPaywallType.Monaco,
		undefined,
		undefined,
		product.value,
		product.sortIndex,
		false
	)

	const { currentUser } = useAuthContext()

	const onSuccess = () => {
		try {
			const _product = product.value
			const amount = _product.trial.amount

			analytics.track("purchase", {
				product: { ..._product, trial: { ..._product.trial, amount: amount } },
				orderID: "",
			})
			analytics.track("checkout", {
				..._product,
				trial: { ..._product.trial, amount: amount },
			})
			analytics.track("start_trial", {
				..._product,
				trial: { ..._product.trial, amount: amount },
			})
			sendEmail()
		} catch (_) {}
		history.push("/confirm")
		Popup.close()
	}

	useEffect(() => {
		setIsLoading(true)
		const paywallForm = document.getElementById("paywall-form")
		const fbc = cookies.get("_fbc")
		const fbp = cookies.get("_fbp")
		const amplitudeDeviceId = amplitudeService.deviceId
		const amplitudeUserId = amplitudeService.userId
		if (currentUser) {
			const unformattedPassthrough = {
				priceId: product.value.priceId,
				amount: product.value.trial.amount,
				currency: product.value.trial.currency,
				user_id: currentUser.uid,
				email: localStorage.getItem("email"),
				fbc,
				fbp,
				amplitudeDeviceId,
				amplitudeUserId,
			}

			const passthrough = JSON.stringify(unformattedPassthrough)

			if (window.Paddle.Checkout && currentUser && currentUser.uid && product) {
				if (paywallForm && process.env.REACT_APP_PADDLE_PRODUCT_ID) {
					let params = new URLSearchParams()
					params.set("payment_type", "paddle")
					params.set("amount", product.value.trial.amount.toString())
					const lang = I18nService.i18n._locale

					const successRedirectLink = `${window.location.origin}/confirm?${params.toString()}&lang=${lang}&currency=${
						product.value.trial.currency
					}`
					createPaddleSubscription(successRedirectLink)
						.then((response) => {
							if (response.response && response.success) {
								window.Paddle.Checkout.open({
									override: response.response.url,
									country: "US",
									method: "overlay",
									frameTarget: "paywall-payment-form",
									frameInitialHeight: 680,
									frameStyle: "position: static; width: 100%; min-height: 800px; overflow: hidden;",

									// success: successRedirectLink,

									// successCallback: () => {
									// 	// amplitudeService.logEvent("[Paddle Test] Paddle onSuccess log")
									// 	onSuccess()
									// },
									loadCallback: () => {
										setIsLoading(false)
									},
								})
							}
						})
						.catch((err) => err)
				}
			}
		}
	}, [currentUser, product, createPaddleSubscription, history])

	const sendEmail = useCallback(async () => {
		if (!isEmailSent) {
			setIsEmailSent(true)
			await callFirebaseFunction("sendManualEmailUser", { email: localStorage.getItem("email") })
		}
	}, [isEmailSent])

	if (window.Paddle.Checkout && window.Paddle.Checkout.Close) {
		amplitudeService.logEvent("[Paddle Test] Paddle checkout closed")
	}
	if (window.Paddle.Checkout && window.Paddle.Checkout.Complete) {
		amplitudeService.logEvent("[Paddle Test] Paddle checkout completed")
		onSuccess()
	}
	if (window.Paddle.Checkout && window.Paddle.Checkout.User && window.Paddle.Checkout.User.Subscribed) {
		amplitudeService.logEvent("[Paddle Test] Paddle user subscribed")
		onSuccess()
	}
	if (window.Paddle.Checkout && window.Paddle.Checkout.Error) {
		amplitudeService.logEvent("[Paddle Test] Paddle checkout error")
	}
	if (window.Paddle.Checkout && window.Paddle.Checkout.PaymentComplete) {
		amplitudeService.logEvent("[Paddle Test] Paddle payment completed")
		onSuccess()
	}

	return (
		<Container>
			<AnimatePresence exitBeforeEnter>
				{isLoading && (
					<motion.div exit={{ opacity: 0 }}>
						<AbsoluteFill>
							<CountLoader />
						</AbsoluteFill>
					</motion.div>
				)}
			</AnimatePresence>
			<Card>
				<div style={{ minHeight: 400 }} id="paywall-form" className="paywall-payment-form"></div>
			</Card>
		</Container>
	)
}
