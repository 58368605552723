import { Trans } from "@lingui/macro"
import { SortBox, SubscriptionValueDTO } from "~/services/config/config-dto-types"
import { i18nCustom } from "~/services/service/i18n-service"

import { formatToDailyAmount } from "~/utils/formaters"

import { Crossed, PriceOld, PriceValue } from "../../../styles"

import { Content, PopularWrap, Title, Wrap } from "./styles"

type Props = {
	isPopular: boolean
	selected: boolean
	product: SortBox<SubscriptionValueDTO>
	onClick: (p: SortBox<SubscriptionValueDTO>) => void
}

function getLocalizedPlanName(name: string): string {
	if (!name.includes("-")) return ""

	const [num, title] = name.split("-")
	return `${num}-${i18nCustom._(title)}`
}

export const PlanItem = ({ product, isPopular, selected, onClick }: Props) => {
	const { amount, days, name } = product.value.plan!
	const { oldPrice } = product.value!
	return (
		<Wrap selected={selected} onClick={() => onClick(product)}>
			{isPopular && (
				<PopularWrap>
					<Trans>Most popular</Trans> 🔥
				</PopularWrap>
			)}
			<Content isPopular={isPopular} align="flex-start">
				<div>
					<Title>
						{getLocalizedPlanName(name)} <Trans>plan</Trans>
					</Title>
					<span>
						<PriceOld>
							<Crossed color={"#FF7070"}>${(oldPrice ?? 0) / 100}</Crossed>
						</PriceOld>{" "}
						<PriceOld>${(amount ?? 0) / 100}</PriceOld>
					</span>
				</div>
				<div>
					<PriceOld>
						<Crossed color={"#FF7070"}>${formatToDailyAmount(oldPrice, days)}</Crossed>{" "}
					</PriceOld>
					<PriceValue>
						${formatToDailyAmount(amount, days)}/<Trans>day</Trans>
					</PriceValue>
				</div>
			</Content>
		</Wrap>
	)
}
