//import { RESPONSE_CODE } from "./response-codes"
import { callFirebaseFunction } from "../call-firebase-function"

export interface StripeRetrySubscriptionInvoiceParams {
	invoiceId: string
	paymentMethodId: string
	email?: string
	fbc?: string
	fbp?: string
	domain: string
}

export interface StripeRetrySubscriptionInvoiceResponse {
	error: any
	invoice: any
}

//TODO: Try catch, add description for this function
export const stripeRetrySubscriptionInvoice = async (
	params: StripeRetrySubscriptionInvoiceParams
): Promise<StripeRetrySubscriptionInvoiceResponse> => {
	const response = await callFirebaseFunction<
		StripeRetrySubscriptionInvoiceParams,
		StripeRetrySubscriptionInvoiceResponse
	>("stripeSubscriptionInvoiceRetryV3", params)
	return response
}
