import { AnalyticsPlugin } from "analytics"
import type { SnapchatPixelWrapOptions } from "react-snapchat-pixel"

export type SCPixelConfig = {
	pixelId: string
	matching?: Record<string, string>
	options?: SnapchatPixelWrapOptions
}
export function snapchatPixelPlugin(config: SCPixelConfig): AnalyticsPlugin {
	const { pixelId, matching, options } = config
	let instance: typeof import("react-snapchat-pixel")
	let loaded = false
	return {
		name: "snapchat-ads",
		config,
		initialize: async () => {
			const module = require("react-snapchat-pixel")
			instance = module.default
			//("Initialize snapchat pixel")
			instance.init(pixelId, matching, options)
			loaded = true
		},
		page: () => {
			instance.pageView()
		},
		track: ({ payload: { event, properties } }: any) => {
			instance.track(event, properties)
		},
		identify: () => {},
		loaded: () => loaded,
	}
}
