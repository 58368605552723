import styled, { css } from "styled-components"

export const Container = styled.div`
	position: relative;
	display: flex;
	flex: 1;
	flex-direction: column;

	background-color: #f2f4f8;

	@media only screen and (hover: none) and (pointer: coarse) {
		/* min-height: 100vh; */
		height: -webkit-fill-available;
		overflow: scroll;
	}
`
const flexCenter = css`
	display: flex;
	justify-content: center;
	align-items: center;
`

export const AbsoluteFill = styled.div`
	position: absolute;
	inset: 0 0 0 0;

	background-color: white;

	${flexCenter};
	z-index: 100;
`

export const LoaderWrap = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	background-color: white;

	${flexCenter};
`

export const HeaderContainer = styled.div`
	position: relative;
	height: 56px;
	width: 100%;
	background-color: white;
	${flexCenter};
`

export const TitleWrapper = styled.div`
	${flexCenter};
	position: absolute;
	width: auto;
	left: 50%;
	transform: translate(-50%, -0%);
`

export const Title = styled.h1`
	font-family: "Inter";
	font-style: normal;
	font-weight: 600;
	font-size: 17px;
	line-height: 24px;

	color: #000000;
`

export const LeftWrapper = styled.div`
	${flexCenter};
	position: absolute;
	left: 20px;
`

export const CloseButtonWrap = styled.button`
	border: none;
	transition: all 0.3s linear;
	cursor: pointer;
	padding: 0px;
	background: transparent;
	display: flex;

	&:hover {
		opacity: 0.5;
	}
`

export const Card = styled.div`
	margin-top: 8px;
	padding: 24px 20px;

	background-color: #ffffff;
	border-radius: 16px;
`
