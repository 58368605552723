import OrangeCircle from "@assets/OrangeCirclePaywall.svg"
import PaywallCard from "@assets/PaywallCard.png"
import PaywallCardMan from "@assets/PaywallCard_man.png"
import PinkCircle from "@assets/PinkCirclePaywall.svg"
import PinkStar from "@assets/PinkStar.svg"
import WhiteStarDesktop from "@assets/WhiteStarDesktop.svg"
import WhiteStarMobile from "@assets/WhiteStarMobile.svg"
import { ActionButton } from "@components/buttons"
import { Appearance } from "@components/buttons/action-button/action-button"
import { defineMessage, t, Trans } from "@lingui/macro"
import { forwardRef, PropsWithChildren } from "react"
import { useQuestions } from "~/contexts/questions-context/questions-context"
import { useQueryParams } from "~/hooks/use-query-params"
import { useWindowDimensions } from "~/hooks/use-window-dimensions"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { i18nCustom } from "~/services/service/i18n-service"

import {
	Wrapper,
	Background,
	Content,
	Title,
	Description,
	Areas,
	PinkCircleWrapper,
	OrangeCircleWrapper,
	MobileWhiteStarWrapper,
	DesktopWhiteStarWrapper,
	PinkStarWrapper,
	ActionButtonWrapper,
	PaywallCardWrapper,
} from "./alpha-prepaywall.styles"

export interface IAlphaPrepaywallProps {
	// paywallPrice: any
	onClickNext: () => void
	agreed?: boolean
	paywallPrice?: JSX.Element
}

export const AlphaPrepaywall = forwardRef<
	unknown,
	PropsWithChildren<IAlphaPrepaywallProps & JSX.IntrinsicElements["div"]>
>(({ onClickNext, ...rest }, ref: any) => {
	const layoutType = useWindowLayout()
	const windowSize = useWindowDimensions()
	const onboardingType = useQueryParams().get("onboardingType")

	defineMessage({ message: "Continue" })

	const allAreas = [...useQuestions().areas]
	const allGoals = [...useQuestions().goals]

	const localAreas = localStorage.getItem("areasNames")
	const areas = localAreas
		? localAreas.split(", ")
		: [t`Forehead`, t`Eye areas`, t`Cheeks and mouth`, t`Chin`, t`Jawline`, t`Neck`]

	const localGoals = localStorage.getItem("goalsNames")
	const goals = localGoals
		? localGoals.split(", ")
		: [
				t`Lift my face`,
				t`Boost my skin health`,
				t`Get rid of wrinkles`,
				t`Reduce puffiness`,
				t`Slim down my face`,
				t`Improve my complexion`,
		  ]

	const coverSrc =
		i18nCustom._("paywall.banner.female") === "paywall.banner.female"
			? "https://glowbeweb.s3.us-east-2.amazonaws.com/Card_en.png"
			: i18nCustom._("paywall.banner.female")

	return (
		// <ScreenLayout ref={ref} size={layoutType}>
		<Wrapper windowHeight={windowSize.height}>
			<Background>
				{layoutType === "Desktop" && (
					<>
						<PinkCircleWrapper alt="" src={PinkCircle} />
						<OrangeCircleWrapper alt="" src={OrangeCircle} />
						<PinkStarWrapper alt="" src={PinkStar} />
						<DesktopWhiteStarWrapper alt="" src={WhiteStarDesktop} />
					</>
				)}
			</Background>
			<Content size={layoutType}>
				{false && <Trans>paywall.banner.female</Trans>}
				<PaywallCardWrapper size={layoutType} alt="" src={coverSrc} />
				{layoutType === "Mobile" && <MobileWhiteStarWrapper alt="" src={WhiteStarMobile} />}
				<Title size={layoutType}>{rest.paywallPrice}</Title>
				<Description size={layoutType}>
					<Trans>Сollected based on your answers and preferences</Trans>
				</Description>
				<Areas size={layoutType}>
					<small>
						<Trans>Areas</Trans>
					</small>
					<span>
						{areas.map((area) => (
							<p key={area}>
								<Trans>{area}</Trans>
							</p>
						))}
					</span>
				</Areas>{" "}
				<Areas size={layoutType}>
					<small>
						<Trans>Goals</Trans>
					</small>
					<span>
						{goals.map((goal) => (
							<p key={goal}>
								<Trans>{goal}</Trans>
							</p>
						))}
					</span>
				</Areas>
			</Content>
			{!rest.agreed && (
				<ActionButtonWrapper size={layoutType}>
					{/* // <ActionButtonWrapper size={layoutType} device={userAgent.iOS ? "ios" : "android"}> */}
					<ActionButton isStretch ref={ref} size={layoutType} appearance={Appearance.Pink} onClick={onClickNext}>
						{i18nCustom._("Continue")}
					</ActionButton>
				</ActionButtonWrapper>
			)}
		</Wrapper>
		// </ScreenLayout>
	)
})
