import { callFirebaseFunction } from "../call-firebase-function"

export interface StripeSubscriptionPriceInfo {
	priceId: string
	active: boolean
	amount: number
	currency: string
	interval: string
	intervalCount: number
}

export interface StripeGetSubscriptionPricesParams {
	domain: string
	priceIds: string[]
}

export interface StripeGetSubscriptionPricesResponse {
	data: StripeSubscriptionPriceInfo[]
	error: string
}

/**
 * Returns prices info for specified price ids.
 */
export const stripeGetSubscriptionPrices = async (
	params: StripeGetSubscriptionPricesParams
): Promise<StripeGetSubscriptionPricesResponse> => {
	try {
		const response = await callFirebaseFunction<StripeGetSubscriptionPricesParams, StripeGetSubscriptionPricesResponse>(
			"stripeSubscriptionPricesListV3",
			params
		)
		return response
	} catch (err) {
		let msg = (err as any).message ?? err
		msg = typeof msg === "object" ? JSON.stringify(msg) : msg
		console.error(`Error while calling server method stripeGetSubscriptionPrices: ${msg}`)
		throw err
	}
}
