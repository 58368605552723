import { AnalyticsPlugin } from "analytics"
import { AnalyticsEventsMap } from "./analytics"
import { trackMapped } from "./wrapper"

declare global {
	interface Window {
		dataLayer: any[]
	}
}

export function gtagPlugin(config: GTagConfig): AnalyticsPlugin {
	let gtag: (...args: any) => void
	return {
		name: `gtag-${config.measurementId}`,
		config: { measurementId: config.measurementId },
		initialize: async ({ config: { measurementId } }: any) => {
			window.dataLayer = window.dataLayer || []
			gtag = function gtag() {
				// @ts-ignore
				dataLayer.push(arguments)
			}
			gtag("js", new Date())
			gtag("config", measurementId, { send_page_view: false })
			if (!scriptLoaded(measurementId)) {
				const script = document.createElement("script")
				script.async = true
				script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`
				document.head.appendChild(script)
			}
		},
		page: ({ config: { measurementId } }: any) => {
			gtag("event", "page_view", {
				page_title: document.title,
				page_location: document.location.origin,
				page_path: document.location.pathname,
				send_to: measurementId,
			})
		},
		track: trackMapped<AnalyticsEventsMap, GTagEventsMap>()(
			{
				sign_up: () => ["sign_up"],
				add_to_cart: (product) => [
					"add_to_cart",
					{
						event_category: "ecommerce",
						currency: product?.trial?.currency?.toUpperCase() ?? "",
						value: product?.trial?.amount / 100 ?? 0,
						items: [
							{
								item_id: product ? product.planId : "",
								item_name: product ? product.planId : "",
							},
						],
					},
				],
				purchase: ({ product, orderID }) => [
					"purchase",
					{
						event_category: "ecommerce",
						currency: product?.trial?.currency?.toUpperCase() ?? "",
						value: 59.99,
						transaction_id: orderID,
						items: [
							{
								item_id: product ? product.planId : "",
								item_name: product ? product.priceId : "",
							},
						],
					},
				],
				checkout: (product) => [
					"begin_checkout",
					{
						event_category: "ecommerce",
						currency: product?.trial?.currency?.toUpperCase() ?? "",
						value: product?.trial?.amount / 100 ?? 0,
						items: [
							{
								item_id: product ? product.planId : "",
								item_name: product ? product.priceId : "",
							},
						],
					},
				],
			},
			(_, event, payload) => {
				gtag("event", event, payload)
			}
		),
		loaded: () => typeof gtag !== undefined,
	}
}

const regexCache = (measurementId: string) => {
	return new RegExp("googletagmanager\\.com\\/gtag\\/js.*[?&]id=" + measurementId)
}
function scriptLoaded(measurementId: string) {
	const regex = regexCache(measurementId)
	const scripts = document.querySelectorAll<HTMLScriptElement>("script[src]")
	return !!Array.from(scripts).filter((script) => script.src.match(regex)).length
}

export type GTagConfig = {
	measurementId: string
}
export type GTagEventNames =
	| "add_payment_info"
	| "add_to_cart"
	| "add_to_wishlist"
	| "begin_checkout"
	| "checkout_progress"
	| "generate_lead"
	| "login"
	| "purchase"
	| "refund"
	| "remove_from_cart"
	| "search"
	| "select_content"
	| "set_checkout_option"
	| "share"
	| "sign_up"
	| "view_item"
	| "view_item_list"
	| "view_promotion"
	| "view_search_results"
export type GTagEventCategories = "ecommerce" | "engagement"
/* eslint-disable camelcase */
export type GTagEvent = {
	event_category?: GTagEventCategories | (string & {})
	event_label?: string
	value?: number
	non_interaction?: boolean
} & {
	[K: string]: any
}
/* eslint-enable camelcase */

/* eslint-disable camelcase */
export type GTagEventsMapKnown = {
	sign_up: void
	add_to_cart: GTagEvent & {
		currency: string
		items: GTagProductItem[]
	}
	purchase: GTagEvent & {
		currency: string
		transaction_id: string
		items: GTagProductItem[]
	}
	begin_checkout: GTagEvent & {
		currency: string
		items: GTagProductItem[]
	}
}
export type GTagEventsMap = GTagEventsMapKnown &
	{
		[K in Exclude<GTagEventNames, keyof GTagEventsMapKnown>]: GTagEvent
	}
/* eslint-enable camelcase */
/* eslint-disable camelcase */
export type GTagProductItem =
	| {
			item_id: string
			item_name?: string
	  }
	| {
			item_id?: string
			item_name: string
	  }
/* eslint-enable camelcase */
