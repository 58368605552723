import { Review } from "@components/reviews"
import { Spacer } from "@components/spacer"
import React, { createRef, useCallback, useEffect, useRef, useState } from "react"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { LottieCircle } from "./components/lotties/lottie-circle"
import { LottieCircleDot } from "./components/lotties/lottie-circle-dot"
import { LottiePercentage } from "./components/lotties/lottie-percentage"
import { ScreenTitle } from "./components/screen-title"

import {
	Wrapper,
	Screen,
	ScreenContent,
	ProgressItemDiv,
	ProgressItemTextSpan,
	ProgressListContentDiv,
	ProgressListDiv,
} from "./final-loader-screen.styles"

export interface IFinalLoaderScreenProps {
	label: string
	readyForAnimation: boolean
	items: string[]
	reviewTitle: string
	reviewText: string
	reviewCreated: string
	reviewAuthor: string
	onNext?: () => void
}

export const FinalLoaderScreen = ({
	label,
	readyForAnimation,
	items,
	reviewTitle,
	reviewText,
	reviewCreated,
	reviewAuthor,
	onNext,
}: IFinalLoaderScreenProps) => {
	const size = useWindowLayout()
	const itemsCount = items.length

	const [isStopped, setStopped] = useState<boolean[]>([...items.map(() => true)])
	const [isStarted, setStarted] = useState(false)

	const progressRef = useRef<HTMLDivElement>(null)
	const [itemSpanRefs, setItemSpanRefs] = React.useState<React.RefObject<HTMLSpanElement>[]>([])

	// 1. Create array of refs
	React.useEffect(() => {
		// add or remove refs
		setItemSpanRefs((prev) =>
			Array(itemsCount)
				.fill(null)
				.map((_, i) => prev[i] || createRef<HTMLSpanElement>())
		)
	}, [itemsCount])

	// 1. Show title when ready
	useEffect(() => {
		if (readyForAnimation) {
			setStarted(true)
		}
	}, [readyForAnimation, setStarted])

	// 2. Show list
	const showProgressList = useCallback(
		(event: React.TransitionEvent<HTMLDivElement>) => {
			//console.log("!!!!! FINISH")
			if (!!progressRef.current) {
				progressRef.current.style.opacity = "1" // from 0
				progressRef.current.style.top = "152px" // from 242px
			}
		},
		[progressRef]
	)

	// 3. Animate item 1
	const animateItem1 = useCallback(
		(event: React.TransitionEvent<HTMLDivElement>) => {
			setStopped((prev) => [false, ...prev.slice(1)])
			if (itemSpanRefs[0].current) {
				itemSpanRefs[0].current.style.color = "#5856d6"
			}
		},
		[setStopped, itemSpanRefs]
	)

	// 4. On finish item animation - start next item animation
	const onFinishItemAnimation = useCallback(
		(idx: number) => {
			setStopped((prev) => {
				if (prev[idx + 1]) {
					const newArr = [...prev]
					newArr[idx + 1] = false
					return newArr
				}
				return prev
			})
			if (!!itemSpanRefs && !!itemSpanRefs[idx + 1] && !!itemSpanRefs[idx + 1].current) {
				// @ts-ignore
				itemSpanRefs[idx + 1].current.style.color = "#5856d6"
			}
		},
		[setStopped, itemSpanRefs]
	)

	if (!readyForAnimation) {
		return (
			<Screen>
				<ScreenContent></ScreenContent>
			</Screen>
		)
	}

	return (
		<Wrapper size={size}>
			<ScreenTitle z={1} size={size} isStarted={isStarted} title={label} onTransitionEnd={showProgressList} />
			<ProgressListDiv z={1} ref={progressRef} onTransitionEnd={animateItem1}>
				<ProgressListContentDiv>
					{items.map((item, idx, arr) => {
						return (
							<ProgressItemDiv isLast={idx === arr.length - 1} key={`progressItem${idx}`}>
								<LottiePercentage z={2} isStopped={isStopped[idx]} />
								{idx + 1 === arr.length ? (
									<LottieCircle z={1} isStopped={isStopped[idx]} onFinished={onNext} />
								) : (
									<LottieCircleDot
										key={idx}
										z={1}
										isStopped={isStopped[idx]}
										onFinished={() => {
											onFinishItemAnimation(idx)
										}}
									/>
								)}
								<ProgressItemTextSpan size={size} ref={itemSpanRefs[idx]}>
									{item}
								</ProgressItemTextSpan>
							</ProgressItemDiv>
						)
					})}
				</ProgressListContentDiv>
				<Spacer height={"54px"} />
				<Review title={reviewTitle} review={reviewText} created={reviewCreated} author={reviewAuthor} size={size} />
			</ProgressListDiv>
		</Wrapper>
	)
}
