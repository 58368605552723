import CircleAnimation from "@assets/lotties/LandingDeltaCircle.json"
import LinesAnimation from "@assets/lotties/LandingDeltaLines.json"
import LogoAnimation from "@assets/lotties/LandingEchoLogo.json"
import { Trans } from "@lingui/macro"
import { gsap } from "gsap"
import { useLayoutEffect, useMemo, useRef, useState } from "react"
import Lottie, { Options as LottieOptions } from "react-lottie"
import { ActionButton } from "~/components/buttons"
import { Appearance } from "~/components/buttons/action-button/action-button"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { amplitudeService } from "~/services/amplitude"
import { Wrapper, Background, LandingWrapper } from "../delta-landing/delta-landing.styles"
import { AnimatedTextWrapper, AnimatedText, ActionButtonWrapper } from "./echo-landing.styles"

const startY = 175
const endY = -175

const linesOptions: LottieOptions = {
	loop: false,
	autoplay: true,
	animationData: LinesAnimation,
	rendererSettings: {
		preserveAspectRatio: "none meet", // More info about the setting https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/preserveAspectRatio
	},
}
const circleOptions: LottieOptions = {
	loop: false,
	autoplay: false,
	animationData: CircleAnimation,
	rendererSettings: {
		preserveAspectRatio: "none slice",
	},
}

const lottieStyle: React.CSSProperties = {
	position: "absolute",
	top: 0,
	left: 0,
	right: 0,
	bottom: 0,
}

const logoStyle: React.CSSProperties = { ...lottieStyle, top: 50 }

export interface IEchoLandingProps {
	onNext: () => void
	onboardingType: string | null
}

export const EchoLanding = (props: IEchoLandingProps) => {
	const [isStoppedCircleAnimation, setIsStoppedCircleAnimation] = useState<boolean>(true)
	const layoutType = useWindowLayout()
	const helloAnimatedRef = useRef(null)
	const weAnimatedRef = useRef(null)
	const careAnimatedRef = useRef(null)
	const chosenAnimatedRef = useRef(null)
	const genderAnimatedRef = useRef(null)

	const logoOptions = useMemo(
		() => ({
			loop: false,
			autoplay: true,
			animationData: LogoAnimation,
			rendererSettings: {
				preserveAspectRatio: "xMidyMid meet",
				clearCanvas: true,
				hideOnTransparent: true,
			},
		}),
		[]
	)

	useLayoutEffect(() => {
		if (helloAnimatedRef && helloAnimatedRef.current) {
			gsap.from(helloAnimatedRef.current, {
				y: startY,
			})
			gsap.to(helloAnimatedRef.current, {
				y: endY,
				delay: 1.5,
			})
		}
		if (weAnimatedRef && weAnimatedRef.current) {
			gsap.from(weAnimatedRef.current, {
				y: startY,
				delay: 1.6,
			})
			gsap.to(weAnimatedRef.current, {
				y: endY,
				delay: 3,
			})
		}
		if (careAnimatedRef && careAnimatedRef.current) {
			gsap.from(careAnimatedRef.current, {
				y: startY,
				delay: 3.1,
			})
			gsap.to(careAnimatedRef.current, {
				y: endY,
				delay: 6.5,
			})
		}
		if (chosenAnimatedRef && chosenAnimatedRef.current) {
			gsap.from(chosenAnimatedRef.current, {
				y: startY,
				delay: 7,
			})
			gsap.to(chosenAnimatedRef.current, {
				y: endY,
				delay: 12,
			})
		}
		if (genderAnimatedRef && genderAnimatedRef.current) {
			gsap.from(genderAnimatedRef.current, {
				y: startY,
				delay: 7.8,
			})
			gsap.to(genderAnimatedRef.current, {
				y: endY,
				delay: 12,
			})
		}
	}, [])

	return (
		<Wrapper>
			<Background />
			<LandingWrapper style={{ position: "relative" }}>
				<AnimatedTextWrapper>
					<AnimatedText ref={helloAnimatedRef}>
						<Trans>Hello!</Trans>
					</AnimatedText>
				</AnimatedTextWrapper>
				<AnimatedTextWrapper>
					<AnimatedText ref={weAnimatedRef}>
						<Trans>We are Glowbe!</Trans>
					</AnimatedText>
				</AnimatedTextWrapper>
				<AnimatedTextWrapper>
					<AnimatedText ref={careAnimatedRef}>
						<Trans>Take care of your skin, hair, body and mind</Trans>
					</AnimatedText>
				</AnimatedTextWrapper>
				<AnimatedTextWrapper>
					<AnimatedText ref={chosenAnimatedRef}>
						<Trans>Chosen by</Trans>
					</AnimatedText>
				</AnimatedTextWrapper>
				<AnimatedTextWrapper isBottom>
					<AnimatedText ref={genderAnimatedRef}>
						{!props.onboardingType ? <Trans>Women</Trans> : <Trans>Men</Trans>}
					</AnimatedText>
				</AnimatedTextWrapper>
				{/* // ))} */}
				<Lottie
					options={linesOptions}
					isPaused={false}
					isStopped={false}
					style={lottieStyle}
					isClickToPauseDisabled={true}
					eventListeners={[
						{
							eventName: "complete",
							callback: () => {
								setIsStoppedCircleAnimation(false)
							},
						},
					]}
					width="100%"
					height="100%"
				/>
				<Lottie
					options={logoOptions}
					isStopped={false}
					isPaused={false}
					style={logoStyle}
					isClickToPauseDisabled={true}
				/>
				<Lottie
					options={circleOptions}
					isStopped={isStoppedCircleAnimation}
					isPaused={false}
					style={lottieStyle}
					isClickToPauseDisabled={true}
					eventListeners={[
						{
							eventName: "complete",
							callback: () => props.onNext(),
						},
					]}
				/>
				<ActionButtonWrapper>
					<ActionButton
						size={layoutType}
						appearance={Appearance.Blue}
						onClick={() => {
							amplitudeService.logEvent("[All] Skip Loader")
							props.onNext()
						}}
					>
						<Trans>Skip</Trans>
					</ActionButton>
				</ActionButtonWrapper>
			</LandingWrapper>
		</Wrapper>
	)
}
