import { Trans } from "@lingui/macro"
import { useEffect } from "react"
import { Popup } from "~/components/layouts/popup"
import { amplitudeService } from "~/services/amplitude"
import { SortBox, SubscriptionValueDTO } from "~/services/config/config-dto-types"
import { ICross } from "./cross-icon"
import { PaymentView } from "./payment-view"
import { CloseButtonWrap, Container, HeaderContainer, LeftWrapper, Title, TitleWrapper } from "./styles"

type Props = {
	product: SortBox<SubscriptionValueDTO>
}

export const PaymentPopup = ({ product }: Props) => {
	useEffect(() => {
		amplitudeService.logEvent("[All] Paywall | Open Popup")
	}, [])

	return (
		<Container>
			<HeaderContainer>
				<LeftWrapper>
					<CloseButtonWrap onClick={Popup.close}>
						<ICross />
					</CloseButtonWrap>
				</LeftWrapper>
				<TitleWrapper>
					<Title>
						<Trans>Payment info</Trans>
					</Title>
				</TitleWrapper>
			</HeaderContainer>
			<PaymentView product={product} />
		</Container>
	)
}
