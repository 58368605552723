import { Loader } from "@components/loader/loader"
import { defineMessage, t, Trans } from "@lingui/macro"
import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js"
import { useEffect, useRef, useState } from "react"

import ApplePayLogo from "~/assets/ApplePay.svg"
import BluePlus from "~/assets/BluePlus.svg"
import DesktopWhiteStar from "~/assets/DesktopWhiteStar.svg"
import PaywallStar from "~/assets/PaywallStar.svg"
import SSLLogo from "~/assets/SSLLogo.svg"
import StripeLogo from "~/assets/StripeLogo.svg"
import { Block } from "~/components2/atoms/Block"
import { Button } from "~/components2/atoms/Button"
import { Flexed } from "~/components2/atoms/Flexed"
import { Input } from "~/components2/atoms/Input"
import { List } from "~/components2/atoms/List"
import { ReviewsCarousel } from "~/components2/atoms/Reviews"
import { TextBody, TextWithIcon, Colored } from "~/components2/atoms/Text"

import { useWindowDimensions } from "~/hooks/use-window-dimensions"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { amplitudeService } from "~/services/amplitude"
import { DPaywallType } from "~/services/config/config-types"
import { i18nCustom } from "~/services/service/i18n-service"
import { usePaywall } from "../use-paywall"
import { AvailableOn } from "./atoms/available-on"
import { Copyright } from "./atoms/copyright"
import { PrePaywall } from "./atoms/prepaywall"
import { PrePaywallTime } from "./atoms/prepaywall-time"
import { PriceAfterTrial } from "./atoms/price-after-trial"
import { PriceToday } from "./atoms/price-today"
import { TrialInformation } from "./atoms/trial-info"
import { PaywallZipInput } from "./paywall-zip-input"
import { Promocode } from "./promocode"

import {
	Wrapper,
	ContentWrapper,
	Content,
	Background,
	WhiteStarWrapper,
	DesktopWhiteStarWrapper,
	ExpandedArea,
	Markets,
	MediumBlock,
	charlieBenefits,
	reviewData,
	PaymentBubble,
	PaymentBlock,
	PaymentTitle,
	PaymentLogos,
	PaymentLabel,
	PaymentDataInput,
	PaymentMultiInputWrapper,
	ExpirationInput,
	CVCInput,
	TimerBox,
	PaymentError,
	ReviewsWrapper,
	TryTitle,
	PriceInfo,
	ApplePayWrapper,
	ApplePayBtn,
} from "./styles"

interface IProps {
	onNext?: (params?: any) => void
}

export const CharliePaywall = (props: IProps) => {
	const layout = useWindowLayout()
	const timerRef = useRef<HTMLDivElement>(null)
	const timeRef = useRef<HTMLDivElement>(null)
	const addressZipRef = useRef<HTMLInputElement>(null)

	const windowSize = useWindowDimensions()

	const [focused, setFocused] = useState(false)

	const {
		product,
		paywallPrice,
		// stripeShowPaymentRequestDialog,
		agreed,
		setAgreed,
		timeLeft,
		addressZip,
		visibleTimer,
		submitProgress,
		errorToDisplay,
		stripeElementProps,
		// ChosenAreas,
		paywallWeeklyPrice,
		createCardPaySubscription,
		handleScroll,
		handleContinue,
		webPrepaywallType,
	} = usePaywall(DPaywallType.Charlie, addressZipRef, timeRef)

	useEffect(() => {
		if (timerRef.current) {
			if (visibleTimer) {
				timerRef.current.style.top = "12px"
			} else {
				timerRef.current.style.top = "-40px"
			}
		}
	}, [visibleTimer])

	useEffect(() => {
		if (agreed) {
			if (timeRef.current) {
				timeRef.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				})
			}
		}
	}, [agreed])

	defineMessage({ message: "Paying..." })
	defineMessage({ message: "Start 3 Days Trial" })

	if (webPrepaywallType && product) {
		return (
			<Wrapper windowHeight={windowSize.height}>
				<Background agreed={agreed}></Background>
				<ContentWrapper id="wrapper" onScroll={handleScroll}>
					<PrePaywall
						webPrepaywallType={webPrepaywallType}
						agreed={agreed}
						setAgreed={setAgreed}
						paywallPrice={paywallPrice!}
					/>

					<Content windowHeight={windowSize.height}>
						{agreed && (
							<ExpandedArea>
								<TimerBox ref={timerRef}>
									<p>
										<Trans>Plan reserved for</Trans>{" "}
									</p>
									<span>
										{Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
									</span>
								</TimerBox>

								<PrePaywallTime
									windowHeight={windowSize.height}
									webPrepaywallType={webPrepaywallType}
									ref={timeRef}
									timeLeft={timeLeft}
								/>

								<TryTitle layout={layout} size="big" margin="-40px 0 14px" centered>
									<Trans>Try</Trans> {product?.trial?.trialPeriodDays} <Trans>Trial</Trans>
									{(product?.trial?.trialPeriodDays ?? 0) > 1 ? "Days" : "Day"} <br />
									<Trans>with</Trans>{" "}
									<Colored color="">
										<Trans>Glowbe Course</Trans>
									</Colored>
								</TryTitle>

								<span id="benefits">
									<MediumBlock>
										<List align="left" items={charlieBenefits} />
									</MediumBlock>
								</span>

								<MediumBlock>
									<PriceToday product={product} paywallPrice={paywallPrice} />

									<PriceInfo>{product?.labels?.description}</PriceInfo>

									<PriceAfterTrial paywallWeeklyPrice={paywallWeeklyPrice} paywallPrice={paywallPrice} />

									<Promocode
										closed={({ openPromocode }) => (
											<TextWithIcon color="#5856D6" margin="24px 0 40px" icon={BluePlus} onClick={openPromocode}>
												<Trans>Add a promocode</Trans>
											</TextWithIcon>
										)}
										opened={({ promocode, onChangePromocode, applyPromocode }) => (
											<Block margin="20px 0 8px">
												<TextBody size="big" color="rgba(130,134,145,0.5)">
													<Trans>Promocode</Trans>
												</TextBody>
												<Input
													placeholder="6-digits code"
													value={promocode}
													type="other"
													onChange={onChangePromocode}
													width="100%"
													maxLength={6}
												/>
												<Button
													disabled={promocode.length === 0}
													color="blue"
													title="Apply"
													width="100%"
													onClick={applyPromocode}
												/>
											</Block>
										)}
										applied={({ promocode }) => (
											<TextBody centered size="big" margin="20px 0 40px">
												<Trans>You've successfuly applied promocode</Trans>{" "}
												<Colored color="#5856d6">{promocode.toUpperCase()}</Colored>{" "}
												<Trans>and recieved one more month</Trans>{" "}
												<Colored color="#5856d6">
													<Trans>for free.</Trans>
												</Colored>{" "}
												<Trans>Thank you!</Trans>
											</TextBody>
										)}
									/>

									<TextBody centered size="big" color="#828691" margin="40px 0 16px">
										<Trans>Users about Us</Trans>
									</TextBody>

									<ReviewsWrapper>
										<ReviewsCarousel items={reviewData} />
									</ReviewsWrapper>
									<ApplePayWrapper>
										<p>
											<Trans>Payment Method</Trans>
										</p>
										<small>
											<Trans>Start your 3 Days Trial with</Trans>
										</small>
										{/* <ApplePayBtn onClick={stripeShowPaymentRequestDialog}>
											<img alt="" src={ApplePayLogo} />
										</ApplePayBtn> */}
										<span>
											<p>
												<Trans>or</Trans>
											</p>
										</span>
									</ApplePayWrapper>

									<form id="payment-form" onSubmit={createCardPaySubscription}>
										<PaymentBubble>
											<PaymentTitle>
												<Trans>Payment Info</Trans>
											</PaymentTitle>
											<PaymentLogos paywallType="bravo">
												<img alt="" src={StripeLogo} />
												<img alt="" src={SSLLogo} />
											</PaymentLogos>
											<PaymentBlock fullwidth>
												<PaymentLabel>
													<Trans>Card</Trans>
												</PaymentLabel>
												<PaymentDataInput>
													<CardNumberElement
														{...stripeElementProps}
														onFocus={() => {
															if (errorToDisplay) {
																setFocused(true)
															}
															amplitudeService.logEvent("[All] Paywall | Card number")
														}}
														onBlur={() => setFocused(false)}
													/>
												</PaymentDataInput>
											</PaymentBlock>
											<PaymentBlock fullwidth>
												<PaymentLabel>
													<Trans>Expires on</Trans>
												</PaymentLabel>
												<PaymentMultiInputWrapper>
													<ExpirationInput>
														<CardExpiryElement
															{...stripeElementProps}
															onFocus={() => {
																if (errorToDisplay) {
																	setFocused(true)
																}
																amplitudeService.logEvent("[All] Paywall | Expires date")
															}}
															onBlur={() => setFocused(false)}
														/>
													</ExpirationInput>
													<CVCInput>
														<CardCvcElement
															{...stripeElementProps}
															onFocus={() => {
																if (errorToDisplay) {
																	setFocused(true)
																}
																amplitudeService.logEvent("[All] Paywall | CVC")
															}}
															onBlur={() => setFocused(false)}
														/>
													</CVCInput>
													<PaywallZipInput
														ref={addressZipRef}
														addressZip={addressZip}
														onFocus={() => {
															if (errorToDisplay) {
																setFocused(true)
															}
														}}
														onBlur={() => setFocused(false)}
													/>
												</PaymentMultiInputWrapper>
											</PaymentBlock>
										</PaymentBubble>

										<Button
											type="submit"
											color="blue"
											margin="24px 0 24px"
											title={submitProgress ? i18nCustom._("Paying...") : i18nCustom._("Start 3 Days Trial")}
											width="100%"
										/>

										{errorToDisplay && errorToDisplay.length && !focused && (
											<PaymentError>{errorToDisplay}</PaymentError>
										)}
									</form>

									<Markets />

									<TrialInformation
										paywallType={DPaywallType.Charlie}
										trialPeriodDays={product.trial.trialPeriodDays}
										paywallPrice={paywallPrice}
									/>

									<Flexed direction="row" justify="center">
										<PaymentLogos paywallType="bravo">
											<img alt="" src={StripeLogo} />
											<img alt="" src={SSLLogo} />
										</PaymentLogos>
									</Flexed>
								</MediumBlock>

								<AvailableOn />

								<Copyright />
							</ExpandedArea>
						)}
					</Content>
				</ContentWrapper>
			</Wrapper>
		)
	} else return <Loader />
}
