import { ActionButton } from "@components/buttons"
import { Loader } from "@components/loader/loader"
import { defineMessage, t, Trans } from "@lingui/macro"
import { CardNumberElement, CardCvcElement, CardExpiryElement } from "@stripe/react-stripe-js"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useHistory } from "react-router-dom"

import AndroidIcon from "~/assets/Android.svg"
import IosIcon from "~/assets/BlackIos.svg"
import BluePlus from "~/assets/BluePlus.svg"
import FiveStars from "~/assets/FiveStars.png"
import MastercardPure from "~/assets/MastercardPure.svg"
import PaypalIcon from "~/assets/PayPal.svg"
import SSLLogo from "~/assets/SSLLogo.svg"
import StripeLogo from "~/assets/StripeLogo.svg"
import VisaPure from "~/assets/VisaPure.svg"
import { Appearance } from "~/components/buttons/action-button/action-button"
import { Block } from "~/components2/atoms/Block"
import { Button } from "~/components2/atoms/Button"
import { Flexed } from "~/components2/atoms/Flexed"
import { Input } from "~/components2/atoms/Input"
import { List } from "~/components2/atoms/List"
import { TextBody, TextWithIcon, Colored } from "~/components2/atoms/Text"

import { useQueryParams } from "~/hooks/use-query-params"
import { useWindowDimensions } from "~/hooks/use-window-dimensions"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { amplitudeService } from "~/services/amplitude"
import { DPaywallType } from "~/services/config/config-types"
import { cookies } from "~/services/cookies"
import { i18nCustom } from "~/services/service/i18n-service"
import { useAuthContext } from "~/subscriptions-web/context"
import { PayPalButton } from "~/subscriptions-web/paypal/paypal-button"
import { callFirebaseFunction } from "~/subscriptions-web/server-api/call-firebase-function"
import { usePaywall } from "../../use-paywall"
import { PrePaywall } from "../atoms/prepaywall"
import { PrePaywallTime } from "../atoms/prepaywall-time"
import { PriceAfterTrial } from "../atoms/price-after-trial"
import { PriceToday } from "../atoms/price-today"
import { TrialInformation } from "../atoms/trial-info"
import { PaywallZipInput } from "../paywall-zip-input"
import { Promocode } from "../promocode"
import {
	Wrapper,
	ContentWrapper,
	Content,
	ExpandedArea,
	TrialInfo,
	MediumBlock,
	charlieBenefits,
	PaymentBubble,
	PaymentBlock,
	PaymentTitle,
	PaymentLogos,
	PaymentLabel,
	PaymentDataInput,
	PaymentMultiInputWrapper,
	ExpirationInput,
	CVCInput,
	MobileTrialText,
	TimerBox,
	PaymentError,
	InfoBreak,
	PaywallFooter,
	TryTitle,
	PaymentMethods,
	MarketsSimple,
	MarketBubble,
	InfoTitle,
	PayPalButtonContainer,
	PriceInfo,
} from "../styles"
import { PaywallProps } from "../types"
import RecommendsGlobe from "./assets/recommends.png"
import ReviewIcon from "./assets/review.png"
import { reviews, reviewsMan } from "./reviews"
import { EchoUserReviews, PaymentMethod, PayPalButtonWrapper } from "./styles"

export function LimaPaywall({ onNext }: PaywallProps) {
	const layout = useWindowLayout()
	const timerRef = useRef<HTMLDivElement>(null)
	const timeRef = useRef<HTMLDivElement>(null)
	const addressZipRef = useRef<HTMLInputElement>(null)
	const onboardingType = useQueryParams().get("onboardingType")

	const windowSize = useWindowDimensions()

	const [focused, setFocused] = useState(false)
	const [method, setMethod] = useState<"paypal" | "card">("paypal")

	const [reviewsHidden, setReviewsHidden] = useState(true)
	const toggleReviews = useCallback(() => {
		setReviewsHidden((v) => !v)
	}, [])
	const shownReviews = useMemo(() => {
		const reviewsByOnboardingType = onboardingType === "man" ? reviewsMan : reviews

		return reviewsHidden ? reviewsByOnboardingType.slice(0, 3) : reviewsByOnboardingType
	}, [reviewsHidden, onboardingType])

	const {
		addressZip,
		product,
		paywallPrice,
		agreed,
		setAgreed,
		timeLeft,
		visibleTimer,
		submitProgress,
		errorToDisplay,
		stripeElementProps,
		paywallWeeklyPrice,
		createCardPaySubscription,
		createPayPalSubscription,
		handlePayPalApprove,
		handleScroll,
		logPaypalEvent,
		webPrepaywallType,
		createPaddleSubscription,
	} = usePaywall(DPaywallType.Lima, addressZipRef, timeRef)

	useEffect(() => {
		if (timerRef.current) {
			if (visibleTimer) {
				timerRef.current.style.top = "12px"
			} else {
				timerRef.current.style.top = "-40px"
			}
		}
	}, [visibleTimer])

	useEffect(() => {
		if (agreed) {
			if (timeRef.current) {
				timeRef.current.scrollIntoView({
					behavior: "smooth",
					block: "start",
				})
			}
		}
	}, [agreed])

	defineMessage({ message: "Paying..." })
	defineMessage({ message: "View more" })
	defineMessage({ message: "Show less" })
	defineMessage({ message: "Day" })
	defineMessage({ message: "Days" })

	shownReviews.forEach((r) => {
		defineMessage({ message: r.name })
		defineMessage({ message: r.message })
	})

	//("___PRODUCTTT___", product)

	const paywallForm = document.getElementById("paywall-form")

	const history = useHistory()

	// const onSubscriptionSuccess = () => {
	// 	history.push("/confirm")
	// }

	const { currentUser } = useAuthContext()

	const fbc = cookies.get("_fbc")
	const fbp = cookies.get("_fbp")
	const amplitudeDeviceId = amplitudeService.deviceId
	const amplitudeUserId = amplitudeService.userId

	//console.log("+++CURRENT USER+++", currentUser)

	useEffect(() => {
		if (currentUser) {
			const unformattedPassthrough = {
				priceId: product.priceId,
				amount: product.trial.amount,
				currency: product.trial.currency,
				user_id: currentUser.uid,
				email: localStorage.getItem("email"),
				fbc,
				fbp,
				amplitudeDeviceId,
				amplitudeUserId,
			}

			const passthrough = JSON.stringify(unformattedPassthrough)

			if (window.Paddle.Checkout && currentUser && currentUser.uid && product) {
				if (paywallForm && process.env.REACT_APP_PADDLE_PRODUCT_ID) {
					createPaddleSubscription()
						.then((response) => {
							//console.log("+++PAD RESPONSE 2+++", response)

							if (response.response && response.success) {
								window.Paddle.Checkout.open({
									override: response.response.url,
									country: "US",
									/* email: localStorage.getItem("email"), */
									/* postcode: "", */
									// currency: subscriptionInfo.currency,
									method: "inline",
									/* product: +process.env.REACT_APP_PADDLE_PRODUCT_ID, */
									// allowQuantity: false,
									// disableLogout: true,
									frameTarget: "paywall-payment-form",
									frameInitialHeight: 680,
									// Please ensure the minimum width is kept at or above 286px with checkout padding disabled,
									// or 312px with checkout padding enabled.
									// See "General" section under
									// "Branded Inline Checkout" below for more information on checkout padding.
									frameStyle:
										layout === "Mobile"
											? "width:100%; max-width: 98vw; background-color: transparent; border: none"
											: "margin-left: -25%; width:150%; max-width: 98vw; background-color: transparent; border: none",
									/* title: "start trial",
							upsellAction: "start trial",
							action: "start trial", */

									successCallback: async () => {
										await callFirebaseFunction("sendManualEmailUser", { email: localStorage.getItem("email") })
										history.push("/confirm")
									},
									// closeCallback: onSubscriptionClose,
									/* passthrough: {
								user_id: currentUser.uid,
								email: localStorage.getItem("email"),
								fbc,
								fbp,
								amplitudeDeviceId,
								amplitudeUserId,
							}, */
								})
							}
						})
						.catch((err) => console.log("+++PAD ERR+++", err))
					//console.log("+++CHECKOUT LOADED 2+++", window.Paddle.Checkout)
				}
			}
		}
	}, [
		paywallForm,
		currentUser,
		amplitudeDeviceId,
		amplitudeUserId,
		fbc,
		fbp,
		product,
		createPaddleSubscription,
		history,
	])

	if (window.Paddle.Checkout && window.Paddle.Checkout.Close) {
		amplitudeService.logEvent("[Paddle Test] Paddle checkout closed")
	}
	if (window.Paddle.Checkout && window.Paddle.Checkout.Complete) {
		amplitudeService.logEvent("[Paddle Test] Paddle checkout completed")
		history.push("/confirm")
	}
	if (window.Paddle.Checkout && window.Paddle.Checkout.User && window.Paddle.Checkout.User.Subscribed) {
		amplitudeService.logEvent("[Paddle Test] Paddle user subscribed")
		history.push("/confirm")
	}
	if (window.Paddle.Checkout && window.Paddle.Checkout.Error) {
		amplitudeService.logEvent("[Paddle Test] Paddle checkout error")
	}
	if (window.Paddle.Checkout && window.Paddle.Checkout.PaymentComplete) {
		amplitudeService.logEvent("[Paddle Test] Paddle payment completed")
		history.push("/confirm")
	}

	const [activePayment, setActivePayment] = useState("paypal")

	let paypalBtnRef = useRef<HTMLDivElement>(null)

	const triggerPayPalClick = () => {
		//console.log("+++PAYPAL CLICK+++")
		if (paypalBtnRef.current) {
			//console.log("+++PAYPAL CLICK 2+++")
			// e.preventDefault()
			paypalBtnRef.current.click()
			return false
		}
	}

	if (webPrepaywallType && product) {
		return (
			<Wrapper windowHeight={windowSize.height}>
				<ContentWrapper id="wrapper" onScroll={handleScroll}>
					<PrePaywall
						webPrepaywallType={webPrepaywallType}
						agreed={agreed}
						setAgreed={setAgreed}
						paywallPrice={paywallPrice!}
					/>
					<Content windowHeight={windowSize.height}>
						{agreed && (
							<ExpandedArea>
								<TimerBox ref={timerRef}>
									<p>
										<Trans>Plan reserved for</Trans>{" "}
									</p>
									<span>
										{Math.floor(timeLeft / 60)}:{timeLeft % 60 < 10 ? `0${timeLeft % 60}` : timeLeft % 60}
									</span>
								</TimerBox>
								<PrePaywallTime
									windowHeight={windowSize.height}
									webPrepaywallType={webPrepaywallType}
									ref={timeRef}
									timeLeft={timeLeft}
								/>
								<TryTitle layout={layout} size="big" margin="-40px 0 14px" centered>
									<Trans>Try</Trans> {product?.trial?.trialPeriodDays} <Trans>Trial</Trans>{" "}
									{(product?.trial?.trialPeriodDays ?? 0) > 1 ? i18nCustom._("Days") : i18nCustom._("Day")} <br />
									<Trans>with</Trans>{" "}
									<Colored color="">
										<Trans>Glowbe Course</Trans>
									</Colored>
								</TryTitle>

								<span id="benefits">
									<MediumBlock>
										<List align="left" items={charlieBenefits} />
									</MediumBlock>
								</span>

								<MediumBlock>
									<PriceToday product={product} paywallPrice={paywallPrice} />

									<PriceInfo>{product.labels.description}</PriceInfo>

									<PriceAfterTrial isSpecialOffer paywallWeeklyPrice={paywallWeeklyPrice} paywallPrice={paywallPrice} />

									<Promocode
										closed={({ openPromocode }) => (
											<TextWithIcon
												color="#5856D6"
												margin="24px 0 40px"
												icon={BluePlus}
												centered
												style={{ whiteSpace: "nowrap" }}
												onClick={openPromocode}
											>
												<Trans>Add a promocode</Trans>
											</TextWithIcon>
										)}
										opened={({ promocode, onChangePromocode, applyPromocode }) => (
											<Block margin="20px 0 8px">
												<TextBody size="big" color="rgba(130,134,145,0.5)">
													<Trans>Promocode</Trans>
												</TextBody>
												<Input
													placeholder="6-digits code"
													value={promocode}
													type="other"
													onChange={onChangePromocode}
													width="100%"
													maxLength={6}
												/>
												<Button
													disabled={promocode.length === 0}
													color="blue"
													title="Apply"
													width="100%"
													onClick={applyPromocode}
												/>
											</Block>
										)}
										applied={({ promocode }) => (
											<TextBody centered size="big" margin="20px 0 40px">
												<Trans>You've successfuly applied promocode</Trans>{" "}
												<Colored color="#5856d6">{promocode.toUpperCase()}</Colored>{" "}
												<Trans>and recieved one more month</Trans>{" "}
												<Colored color="#5856d6">
													<Trans>for free.</Trans>
												</Colored>{" "}
												<Trans>Thank you!</Trans>
											</TextBody>
										)}
									/>

									<EchoUserReviews>
										<div className="title">
											<Trans>User reviews</Trans>
										</div>
										<div className="reviews">
											{shownReviews.map((r) => (
												<div key={r.name} className="review">
													<div className="row">
														<img className="avatar" src={r.avatar} alt={r.name} />
														<div className="col">
															<div className="name">
																{i18nCustom._(r.name)}
																<img src={ReviewIcon} alt="" />
															</div>
															<div className="recommends">
																<img src={RecommendsGlobe} alt="" />
																<span>
																	<Trans>recommends</Trans>{" "}
																	<span className="power">
																		<Trans>Glowbe</Trans>
																	</span>
																</span>
															</div>
														</div>
													</div>
													<div className="message">{i18nCustom._(r.message)}</div>
												</div>
											))}
										</div>
										<button className="view-more" onClick={toggleReviews}>
											{reviewsHidden ? i18nCustom._("View more") : i18nCustom._("Show less")}
										</button>
									</EchoUserReviews>

									<PaymentMethods>
										<PaymentMethod active={activePayment === "card"} onClick={() => setActivePayment("card")}>
											<img alt="" src={VisaPure} />
											<img alt="" src={MastercardPure} />
										</PaymentMethod>
										<PayPalButtonWrapper active={activePayment === "paypal"} onClick={() => setActivePayment("paypal")}>
											<img alt="" src={PaypalIcon} />
											{/* <PayPalButtonContainer>
												<PayPalButton
													// ref={paypalBtnRef}
													// options={{
													// 	vault: true,
													// 	intent: "subscription",
													// 	clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
													// }}
													// style={{ color: "white", size: "responsive" }}
													// createSubscription={createPayPalSubscription}
													// onApprove={handlePayPalApprove}
													// onClick={() => setActivePayment("paypal")}
												/>
											</PayPalButtonContainer> */}
										</PayPalButtonWrapper>
									</PaymentMethods>

									{/* PADDLE FORM: */}

									{activePayment === "paypal" && (
										// <PayPalButtonWrapper fullWidth>
										// 	<p>Pay with PayPal</p>
										// 	<PayPalButtonContainer>
										// 		<PayPalButton
										// 			// ref={paypalBtnRef}
										// 			options={{
										// 				vault: true,
										// 				intent: "subscription",
										// 				clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
										// 			}}
										// 			style={{ color: "white", size: "responsive" }}
										// 			createSubscription={createPayPalSubscription}
										// 			onApprove={handlePayPalApprove}
										// 			onClick={logPaypalEvent}
										// 		/>
										// 	</PayPalButtonContainer>
										// </PayPalButtonWrapper>
										<div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
											<ActionButton
												// style={{ width: "200px !important" }}
												size={layout}
												appearance={Appearance.Blue}
												onClick={(e: any) => {
													e.preventDefault()
													triggerPayPalClick()
												}}
											>
												<p>Pay with PayPal</p>
												<PayPalButtonContainer>
													<PayPalButton
														// ref={paypalBtnRef}
														options={{
															vault: true,
															intent: "subscription",
															clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
														}}
														style={{ color: "white", size: "responsive" }}
														createSubscription={createPayPalSubscription}
														onApprove={handlePayPalApprove}
														onClick={logPaypalEvent}
													/>
												</PayPalButtonContainer>
											</ActionButton>
										</div>
									)}

									{/* <ActionButton
										style={{ width: "100% !important" }}
										size={layout}
										appearance={Appearance.Blue}
										onClick={() => {}}
									>
										<p>Pay with PayPal</p>
										<PayPalButtonContainer>
											<PayPalButton
												// ref={paypalBtnRef}
												options={{
													vault: true,
													intent: "subscription",
													clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
												}}
												style={{ color: "white", size: "responsive" }}
												createSubscription={createPayPalSubscription}
												onApprove={handlePayPalApprove}
												onClick={logPaypalEvent}
											/>
										</PayPalButtonContainer>
									</ActionButton> */}

									{activePayment === "card" && <div id="paywall-form" className="paywall-payment-form"></div>}

									{/* <form id="payment-form" onSubmit={createCardPaySubscription}>
										<PaymentBubble>
											<PaymentTitle>
												<Trans>Payment Info</Trans>
											</PaymentTitle>
											<PaymentLogos paywallType="bravo">
												<img alt="" src={StripeLogo} />
												<img alt="" src={SSLLogo} />
											</PaymentLogos>
											<PaymentBlock fullwidth>
												<PaymentLabel>
													<Trans>Card</Trans>
												</PaymentLabel>
												<PaymentDataInput>
													<CardNumberElement
														{...stripeElementProps}
														onFocus={() => {
															if (errorToDisplay) {
																setFocused(true)
															}
															amplitudeService.logEvent("[All] Paywall | Card number")
														}}
														onBlur={() => setFocused(false)}
													/>
												</PaymentDataInput>
											</PaymentBlock>
											<PaymentBlock fullwidth>
												<PaymentLabel>
													<Trans>Expires on</Trans>
												</PaymentLabel>
												<PaymentMultiInputWrapper>
													<ExpirationInput>
														<CardExpiryElement
															{...stripeElementProps}
															onFocus={() => {
																if (errorToDisplay) {
																	setFocused(true)
																}
																amplitudeService.logEvent("[All] Paywall | Expires date")
															}}
															onBlur={() => setFocused(false)}
														/>
													</ExpirationInput>
													<CVCInput>
														<CardCvcElement
															{...stripeElementProps}
															onFocus={() => {
																if (errorToDisplay) {
																	setFocused(true)
																}
																amplitudeService.logEvent("[All] Paywall | CVC")
															}}
															onBlur={() => setFocused(false)}
														/>
													</CVCInput>
													<PaywallZipInput
														ref={addressZipRef}
														addressZip={addressZip}
														onFocus={() => {
															if (errorToDisplay) {
																setFocused(true)
															}
														}}
														onBlur={() => setFocused(false)}
													/>
												</PaymentMultiInputWrapper>
											</PaymentBlock>
										</PaymentBubble>

										<Button
											type="submit"
											color="blue"
											margin="24px 0 24px"
											title={submitProgress ? i18nCustom._("Paying...") : product.labels.button}
											width="100%"
										/>

										{errorToDisplay && errorToDisplay.length && !focused && (
											<PaymentError>{errorToDisplay}</PaymentError>
										)}
									</form> */}

									<MarketsSimple style={{ marginTop: 40 }}>
										<div>
											<span>
												<img alt="" src={AndroidIcon} />
												<p>
													<Trans>Google Play</Trans>
												</p>
											</span>
											<span>
												<img alt="" src={IosIcon} />
												<p>
													<Trans>App Store</Trans>
												</p>
											</span>
										</div>
										<div>
											<MarketBubble>
												<img alt="" src={FiveStars} />
												<p>
													4.8<span>/5</span>
												</p>
											</MarketBubble>
										</div>
										<div>
											<MarketBubble>
												<p>
													1M+{" "}
													<span>
														<Trans>downloads</Trans>
													</span>
												</p>
											</MarketBubble>
											<MarketBubble>
												<p>
													12K{" "}
													<span>
														<Trans>5-star ratings</Trans>
													</span>
												</p>
											</MarketBubble>
										</div>
									</MarketsSimple>

									<TrialInformation
										paywallType={DPaywallType.Lima}
										trialPeriodDays={product.trial.trialPeriodDays}
										paywallPrice={paywallPrice}
									/>

									{/* {method === "card" ? ( */}
									<Flexed direction="row" justify="center">
										<PaymentLogos paywallType="bravo">
											<img alt="" src={StripeLogo} />
											<img alt="" src={SSLLogo} />
										</PaymentLogos>
									</Flexed>
									{/* ) : null} */}
								</MediumBlock>

								<PaywallFooter>
									<p>
										{/* Copyright ® 2019-2022 Glowbe. */}
										<Trans>Copyright</Trans> ® 2022 Glowbe.
										<br />
										<Trans>All Rights Reserved</Trans>
									</p>
								</PaywallFooter>
							</ExpandedArea>
						)}
					</Content>
				</ContentWrapper>
			</Wrapper>
		)
	} else return <Loader />
}
