import { motion } from "framer-motion"
import styled from "styled-components"

export const Backdrop = styled(motion.div).attrs({
	initial: { opacity: 0 },
	animate: { opacity: 1 },
	exit: { opacity: 0 },
	transition: { type: "tween", duration: 0.3 },
})`
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	display: flex;
	justify-content: center;
	align-items: center;

	background-color: rgba(0, 0, 0, 0.3);

	z-index: 1000;

	overflow: scroll;
`

export const ModalContainer = styled(motion.div).attrs({
	initial: { y: 300 },
	animate: { y: 0 },
	exit: { y: 300 },
	transition: { type: "tween", duration: 0.3 },
})`
	max-width: 500px;
	width: 100%;
	margin: auto;
	background-color: white;
`
