import { useEffect } from "react"
import { amplitudeService } from "~/services/amplitude"
import { analytics } from "~/services/analytics"
import { useUpgradeAnonymousUser } from "~/subscriptions-web/hooks/use-upgrade-anonymous-user"
import { callFirebaseFunction } from "~/subscriptions-web/server-api/call-firebase-function"

import { ConfirmEmailScreen } from "./confirm-email-screen"

export interface IConfirmEmailScreenContainerProps {
	currentEmail: string
	onNext: () => void
	onPrev?: () => void
}

export const ConfirmEmailScreenContainer = (props: IConfirmEmailScreenContainerProps) => {
	const { upgradeAnonymousUser } = useUpgradeAnonymousUser(props.onNext)
	useEffect(() => {
		amplitudeService.logEvent("[All] Check email")

		let url = new URL(window.location.href)
		let params = new URLSearchParams(url.search)

		const paymentType = params.get("payment_type")
		const _amount = params.get("amount")
		const currency = params.get("currency")

		if (paymentType === "paddle" && _amount) {
			const amount = parseInt(_amount)
			analytics.track("purchase", {
				// @ts-ignore
				product: { trial: { amount, currency } },
				orderID: "",
			})
			analytics.track("checkout", {
				// @ts-ignore
				trial: { amount, currency },
			})
			analytics.track("start_trial", {
				// @ts-ignore
				trial: { amount, currency },
			})
			callFirebaseFunction("sendManualEmailUser", { email: localStorage.getItem("email") })
		}
		let latestProduct = localStorage.getItem("latestProduct") == null ? undefined : JSON.parse(localStorage.getItem("latestProduct") as string)
		let anEventSended = localStorage.getItem("analitycs_events_sended") == null ? undefined : localStorage.getItem("analitycs_events_sended") === "true"
		if (latestProduct != null && anEventSended === false) {
			const _product = latestProduct.value
			const amount = _product.trial.amount
			amplitudeService.logEvent("[Subscription] Start trial", { ..._product, trial: { ..._product.trial, amount: amount }, source: "paypro" })
			analytics.track("purchase", {
				product: { ..._product, trial: { ..._product.trial, amount: amount } },
				orderID: "",
			})
			analytics.track("checkout", {
				..._product,
				trial: { ..._product.trial, amount: amount },
			})
			analytics.track("start_trial", {
				..._product,
				trial: { ..._product.trial, amount: amount },
			})
			localStorage.setItem("analitycs_events_sended", "true")
			callFirebaseFunction("sendManualEmailUser", { email: localStorage.getItem("email") })
		}
	}, [])

	const confirmEmail = async (email?: string) => {
		if (email) {
			localStorage.setItem("email", email)

			await upgradeAnonymousUser(email)
				.then(() => console.log("___SUCCESS UPDATING EMAIL___"))
				.catch(() => {
					//console.log("email confirmation failed")
					console.log("___FAIL UPDATING EMAIL___")
				})

			amplitudeService.setIdentify((identify) => {
				identify.set("Email", email)
			})
			amplitudeService.logEvent("[All] Button | Confirm email", { email })

			props.onNext()
		}
	}

	return <ConfirmEmailScreen currentEmail={props.currentEmail} confirmEmail={confirmEmail} onNext={props.onNext} />
}
