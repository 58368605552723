import { ActionButton } from "@components/buttons"
import { EmailInput } from "@components/inputs"
import { Copyrights } from "@components/license/copyrights/copyrights"
import { Trans } from "@lingui/macro"
import { forwardRef, PropsWithChildren, useEffect, useState } from "react"
import { useQueryParams } from "~/hooks/use-query-params"
import { useWindowLayout } from "~/hooks/use-window-layout"
import LockIcon from "./assets/Lock.svg"
import {
	Wrapper,
	Top,
	Bottom,
	Title,
	Description,
	MobileButton,
	DesktopButton,
	LockBlock,
	SmallDescription,
} from "./email-screen.styles"

export interface IEmailScreenProps {
	title: string
	description: string
	onNext: (email: string) => void
}
const checkIsNotValid = (val: string) => {
	return (
		val.length < 0 ||
		!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/.test(
			val
		)
	)
}

export const EmailScreen = forwardRef<unknown, PropsWithChildren<IEmailScreenProps & JSX.IntrinsicElements["div"]>>(
	({ ...rest }, ref: any) => {
		const queryString = window.location.search
		const urlParams = new URLSearchParams(queryString)
		const userEmail = urlParams.get("user_email")
		let baseEmail = ""
		if (userEmail != null) {
			baseEmail = decodeURI(userEmail)
		}
		const size = useWindowLayout()
		const [email, setEmail] = useState(baseEmail)
		const [isValid, setIsValid] = useState(checkIsNotValid(baseEmail))
		const [clicked, setClicked] = useState(baseEmail.length > 1)
		useEffect(() => {
			if (userEmail != null && !checkIsNotValid(userEmail)) {
				localStorage.setItem("email", email)
				rest.onNext(email)
			}
		}, [])
		const onChangeEmail = (val: string, isNotValid: boolean) => {
			setEmail(val)
			setIsValid(!isNotValid)
			setClicked(false)
		}

		const onClickNext = () => {
			setClicked(true)

			if (isValid) {
				localStorage.setItem("email", email)
				rest.onNext(email)
			}
		}

		return (
			<Wrapper ref={ref} size={size}>
				<Top>
					<Title size={size}>{rest.title}</Title>
					<Description size={size}>{rest.description}</Description>
					<EmailInput
						size={size}
						value={email}
						placeholder="youremail@gmail.com"
						onChange={(val, isValid) => onChangeEmail(val, isValid)}
						showError={clicked}
					/>
					{size === "Desktop" && (
						<LockBlock size={size}>
							<img alt="" src={LockIcon} />
							<SmallDescription>
								<Trans>
									We store your information securely and won't share it with third parties. We may e-mail you about
									Glowbe services, and you can unsubscribe at any time.
								</Trans>
							</SmallDescription>
						</LockBlock>
					)}
					{size === "Mobile" && (
						<>
							<LockBlock size={size}>
								<img alt="" src={LockIcon} />
								<SmallDescription>
									<Trans>
										We store your information securely and won't share it with third parties. We may e-mail you about
										Glowbe services, and you can unsubscribe at any time.
									</Trans>
								</SmallDescription>
							</LockBlock>
							<MobileButton size={size}>
								<ActionButton size={size} onClick={onClickNext}>
									<Trans>See my Results</Trans>
								</ActionButton>
							</MobileButton>
						</>
					)}
				</Top>
				<Bottom>
					<Copyrights size={size} />
					{size === "Desktop" && (
						<DesktopButton size={size}>
							<ActionButton size={size} onClick={onClickNext}>
								<Trans>See my Results</Trans>
							</ActionButton>
						</DesktopButton>
					)}
				</Bottom>
			</Wrapper>
		)
	}
)
