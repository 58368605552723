import { PayproSubscription } from "./types"

interface WithResult<T> {
	result: T
}

type Maybe<T> = T | null

// TODO: abstract common repository logic and extend these classes
class PayproRepository {
	constructor(private coreUrl: string, private appId: string) {}

	public async cancelSubscription(userId: string, subscriptionId: string) {
		const res = await fetch(`${this.coreUrl}/api/v1/glowbe/paypro/cancel/${this.appId}/${userId}`, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ subscriptionId }),
		})
		if (!res.ok) {
			throw Error("Could not cancel paypro subscription")
		}
		return await res.json()
	}

	public async getCurrentSubscription(userId: string) {
		const res = await fetch(`${this.coreUrl}/api/v1/glowbe/paypro/${this.appId}/${userId}`)
		if (!res.ok) {
			throw Error("Could not get paypro subscription")
		}

		const _data: WithResult<Maybe<PayproSubscription>> = await res.json()

		return _data.result
	}
}

export const payproRepository = new PayproRepository(
	process.env.REACT_APP_CORE_API_URL!,
	process.env.REACT_APP_APPLICATION_ID!
)
