import { Trans } from "@lingui/macro"
import styled from "styled-components"
import { Flexed } from "~/components2/atoms/Flexed"

const Check = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M9.05717 11.3602C8.59337 10.8868 7.83361 10.879 7.3602 11.3428C6.8868 11.8067 6.87902 12.5664 7.34283 13.0398L10.3428 16.1019C10.8337 16.6029 11.6479 16.5779 12.1071 16.0477L17.3571 9.98561C17.791 9.48463 17.7366 8.72678 17.2356 8.29291C16.7346 7.85904 15.9768 7.91344 15.5429 8.41443L11.1456 13.4919L9.05717 11.3602Z"
			fill="#15CE28"
		/>
	</svg>
)

const ListWrap = styled.div`
	width: auto;
	margin: 0 auto;
	padding: 12px 4px;
`

const PWrap = styled.div`
	margin: 0 0 12px;
`

const Text = styled.p<{ color?: string }>`
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	width: auto;
	margin: 0;

	color: ${({ color = "#828691" }) => color};
`

export const FooterText = ({ dailyPrice, name, oldPrice }: { dailyPrice: string; name: string; oldPrice: number }) => {
	return (
		<div>
			<ListWrap>
				<Flexed style={{ marginBottom: 8 }} align="flex-start" justify="flex-start" gap={8}>
					<div style={{ width: 24, height: 24 }}>
						<Check />
					</div>
					<Text>
						{name} <Trans>plan for</Trans> ${dailyPrice}/<Trans>day</Trans>
					</Text>
				</Flexed>
				<Flexed style={{ marginBottom: 8 }} align="flex-start" justify="flex-start" gap={8}>
					<div style={{ width: 24, height: 24 }}>
						<Check />
					</div>
					<Text>
						<Trans>After your</Trans> {name}{" "}
						<Trans>introductory offer your subscription will renew automatically for</Trans> ${oldPrice}{" "}
						<Trans>the full not discounted price each Month until you cancel</Trans>
					</Text>
				</Flexed>
				<Flexed style={{ marginBottom: 8 }} align="flex-start" justify="flex-start" gap={8}>
					<div style={{ width: 24, height: 24 }}>
						<Check />
					</div>
					<Text>
						<Trans>
							If you want to avoid the renewal charge, make sure to cancel the subscription at any time at least 24
							hours before the due date. If you are unsure how to cancel, you can check our
						</Trans>{" "}
						<a href={"https://docs.glowbe.com/terms.pdf"} style={{ color: "#000000", textDecoration: "none" }} target="_blank" rel="noreferrer">
							<Trans>Terms & Conditions</Trans>{" "}
						</a>
						<Trans>or contact our support department</Trans>
					</Text>
				</Flexed>
			</ListWrap>
			<PWrap>
				<Text>
					<Trans>You will need an iPhone or Android smartphone to use Glowbe</Trans>
				</Text>
			</PWrap>
			<PWrap>
				<Text color="#000">
					<Trans>Your information is safe</Trans>
				</Text>
				<Text>
					<Trans>We will not sell or rent your personal contact infromation for any marketing purposes</Trans>
				</Text>
			</PWrap>
			<PWrap>
				<Text color="#000">
					<Trans>Secure checkout</Trans>
				</Text>
				<Text>
					<Trans>All information is encrypted and transmitted using Secure Sockets Layer protocol</Trans>
				</Text>
			</PWrap>
		</div>
	)
}
