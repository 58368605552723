/* eslint-disable camelcase */
import { paypalSubscriptionGetCurrent } from "../paypal/paypal-subscription-get-current"
import { payproRepository } from "../paypro/repository"
import { callFirebaseFunction } from "../server-api/call-firebase-function"
import { getFirestoreDocument } from "../server-api/get-firestore-document"
import { StripeCustomerRecord, stripeSubscriptionsGetCurrent } from "../stripe/get-current-subscription"
import { Subscription, SubscriptionProvider } from "./subscription"
import { subscriptionFromPaypal } from "./subscription-converter.ts/subscription-from-paypal"
import { subscriptionFromPaypro } from "./subscription-converter.ts/subscription-from-paypro"
import { subscriptionFromStripe } from "./subscription-converter.ts/subscription-from-stripe"

export const subscriptionsGetCurrent = async (userId: string): Promise<Subscription[]> => {
	//console.log("+++SUB USER ID+++", userId)
	const payproData = await payproRepository.getCurrentSubscription(userId)

	const subscriptionsPaypalPromise = paypalSubscriptionGetCurrent(userId).then((paypalSubscriptionList) => {
		const subscriptions: Subscription[] = []

		paypalSubscriptionList.forEach((paypalSubscriptionItem) => {
			const subscription = subscriptionFromPaypal(paypalSubscriptionItem)
			subscriptions.push(subscription)
		})

		//console.log("+++SUBS PAYPAL+++", subscriptions)

		return subscriptions
	})

	const subscriptionsStripePromise = stripeSubscriptionsGetCurrent(userId).then((stripeSubscriptionList) => {
		const subscriptions: Subscription[] = []

		stripeSubscriptionList.forEach((stripeSubscriptionItem) => {
			const subscription = subscriptionFromStripe(stripeSubscriptionItem)
			subscriptions.push(subscription)
		})

		//console.log("+++SUBS STRIPE+++", subscriptions)

		return subscriptions
	})

	const getCurrentSubscription = async (userId: string) => {
		try {
			const stripeDoc = await getFirestoreDocument<any>("PADDLE_SUBSCRIPTIONS", userId)

			//console.log("+++SUBS PADDLE DOC+++", stripeDoc)
		} catch (ex) {
			console.error(ex)
			throw ex
		}
	}

	getCurrentSubscription(userId)
	getCurrentSubscription("RVdZilswIbOc8FXzsH5qep6vYUE3")

	const subscriptions: Subscription[] = []

	const paddleSubscriptionGetCurrent = async (params: any): Promise<any> => {
		const response = await callFirebaseFunction("paddleSubscriptionGetInfo", params)
		// if (response.status !== API_RESPONSE_STATUS.SUCCESS) {
		// 	throw new Error(
		// 		`SERVER returned error: status:${response.status} message:${(response.data as ErrorData).message} code:${
		// 			(response.data as ErrorData).error
		// 		}.`
		// 	)
		// }
		//console.log("+++RESPONSE+++", response)

		if (response && (response as any).data) {
			const {
				checkout_id,
				subscriptionStartDate,
				subscriptionEndDate,
				status,
				subscription_id,
				subscription_plan_id,
				cancel_url,
			} = (response as any).data

			const startDate = new Date(1970, 0, 1)
			const endDate = new Date(1970, 0, 1)

			startDate.setSeconds(subscriptionStartDate._seconds)
			endDate.setSeconds(subscriptionEndDate._seconds)

			const subscription: Subscription = {
				id: checkout_id,
				periodStart: startDate,
				periodEnd: endDate,
				status: status,
				provider: SubscriptionProvider.Paddle,
				planId: subscription_plan_id,
				productId: subscription_id,
				cancelUrl: cancel_url,
			}

			subscriptions.push(subscription)
		}

		// return (response as any).data
		// return response.data as IGetPayLinkResponse
	}

	paddleSubscriptionGetCurrent({})

	// paddleSubscriptionGetInfo

	// const paddleSubscriptionsResult = paddleSubscriptionGetCurrent({})

	// console.log("+++PADDLE RESPONSE+++", paddleSubscriptionsResult)

	const result = await Promise.all([
		subscriptionsPaypalPromise,
		subscriptionsStripePromise,
		// paddleSubscriptionGetCurrent,
	])

	result.forEach((subscriptionList) => {
		subscriptions.push(...subscriptionList)
	})

	if (payproData) {
		const subscription = subscriptionFromPaypro(payproData)
		subscriptions.push(subscription)
	}

	//console.log("+++SUBSCRS+++", subscriptions)

	return subscriptions
}
