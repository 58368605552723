import { ChoiceButton } from "@components/buttons"
import { forwardRef, PropsWithChildren } from "react"
import { useWindowLayout } from "~/hooks/use-window-layout"

import { Wrapper, Question, Options, Shadow } from "./select-picture-screen.styles"

export interface ISelectPictureScreenProps {
	label: string
	options: string[]
	attachments: string[]
	onNext: (result: {option: string}) => void
}

export const SelectPictureScreen = forwardRef<
	unknown,
	PropsWithChildren<ISelectPictureScreenProps & JSX.IntrinsicElements["div"]>
>(({ ...rest }, ref: any) => {
	const size = useWindowLayout()
	return (
		<Wrapper ref={ref} size={size}>
			<Question size={size}>{rest.label}</Question>
			<Options>
				{rest.options.map((option, index) => (
					// eslint-disable-next-line max-len
					<ChoiceButton key={option} size={size} onClick={() => rest.onNext({option: option})} picture={rest.attachments[index]}>
						{option}
					</ChoiceButton>
				))}
				<Shadow />
			</Options>
		</Wrapper>
	)
})
