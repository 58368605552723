import { amplitudeService } from "~/services/amplitude"
import { payproCheckoutUrl } from "./constants"
import { serializeQueryParams } from "./utils"

export const buildPayproParams = ({
	payproPriceId,
	email,
	accountId,
	currency,
	templateId,
	accountIdField,
	deviceIdField,
	payproCoupon,
}: {
	payproPriceId: string
	payproCoupon?: string
	email: string
	accountId: string
	currency: string
	templateId: string
	accountIdField: string
	deviceIdField: string
}) => {
	const IS_DEV = process.env.REACT_APP_APPLICATION_ENV === "sandbox"
	const SECRET_KEY = process.env.REACT_APP_PAYPRO_SECRET_KEY

	const params = {
		"products[1][id]": payproPriceId!,
		"page-template": templateId,
		"billing-email": email,
		[`custom-fields[${accountIdField}][]`]: accountId,
		[`custom-fields[${deviceIdField}][]`]: amplitudeService.deviceId!,
		"enable-auto-renewal": "TRUE",
		"payment-method": "1", // credit card
		"use-test-mode": IS_DEV ? "TRUE" : "FALSE",
		currency,
		exfo: 742, // param to open checkout as an iframe
	}
	if (IS_DEV && SECRET_KEY) {
		params["secret-key"] = SECRET_KEY!
	}
	if (payproCoupon) {
		params["coupon-code-to-add"] = payproCoupon
	}

	return `${payproCheckoutUrl}?${serializeQueryParams(params)}`
}
