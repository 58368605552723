export interface HandleRequiresPaymentMethodParams {
	paymentIntent: any
}

export const handleRequiresPaymentMethod = ({ paymentIntent }: HandleRequiresPaymentMethodParams) => {
	if (paymentIntent.status === "requires_payment_method") {
		// Using localStorage to manage the state of the retry here,
		// feel free to replace with what you prefer.
		// Store the latest invoice ID and status.
		// localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
		// localStorage.setItem(
		//     'latestInvoicePaymentIntentStatus',
		//     subscription.latest_invoice.payment_intent.status
		// );

		// Commented above as i use server side saving

		throw new Error("Your card was declined.")
	}
}
