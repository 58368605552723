import { getABIndex } from "./ab-test"
import { configDTOService } from "./config-dto-service"
import { DomainBox, SortBox } from "./config-dto-types"
import { DDomain, IDConfig, IDConfigService } from "./config-types"

export class DConfigService implements IDConfigService {
	private _currentDomain = process.env.REACT_APP_DOMAIN as DDomain

	private _getDomainUnbox<T>(values: DomainBox<T>[]): T {
		if (values.length === 0) {
			throw new Error("Firebase remote config не содержит массив значений типа Domain Value")
		}
		const foundValue = values.find((valueItem) => valueItem.domain === this._currentDomain)
		if (!!foundValue) {
			return foundValue.value
		}

		return values[0].value
	}

	private _getSortUnbox<T>(values: SortBox<T>[], sortIndex: number): T {
		if (values.length === 0) {
			throw new Error("Firebase remote config не содержит массив значений типа Sort Value")
		}
		const foundValue = values.find((valueItem) => valueItem.sortIndex === sortIndex)
		if (!!foundValue) {
			return foundValue.value
		}

		return values[0].value
	}

	private _getSortUboxDefault<T>(values: SortBox<T>[]): T {
		return this._getSortUnbox(values, 0)
	}
	private _getValueUnbox<T>(valueDTO: DomainBox<SortBox<T>[]>[]): T {
		return this._getSortUboxDefault(this._getDomainUnbox(valueDTO))
	}

	public async fetchWebSubscriptions() {
		const configDTO = await configDTOService.fetchConfig()

		return this._getDomainUnbox(configDTO.webSubscription)
	}

	public async fetchConfig(langCode?: string) {
		/* ----------------------- */
		/* fetch raw remote config */
		/* ----------------------- */
		const configDTO = await configDTOService.fetchConfig()

		/* ----------------------- */
		/* get config for specific domain and sort index */
		/* ----------------------- */
		// @ts-ignore
		let config: IDConfig = {
			webLandingSkip: this._getValueUnbox(configDTO.webLandingSkip),
			paywallFirstScreen: this._getValueUnbox(configDTO.paywallFirstScreen),
			webLandingType: this._getValueUnbox(configDTO.webLandingType),
			webOnboardingType: this._getValueUnbox(configDTO.webOnboardingType),
			webOnboardingTypeMan: this._getValueUnbox(configDTO.webOnboardingTypeMan),
			webPrepaywallType: this._getValueUnbox(configDTO.webPrepaywallType),
			webPaywallType: this._getValueUnbox(configDTO.webPaywallType),
			webSubscription: this._getValueUnbox(configDTO.webSubscription),
			payproConfig: this._getValueUnbox(configDTO.payproConfig),
		}

		logConfig("got config before a/b test addoption", config)

		/* ----------------------- */
		/* apply a/b testing */
		/* ----------------------- */

		const setNewValue = <K extends keyof IDConfig, V extends IDConfig[K]>(paramName: K, newValue: V) => {
			config[paramName] = newValue
		}
		// get AB test values
		const abTest = this._getDomainUnbox(configDTO.webAbTest)
		// сейчас у нас аб тестинг только одного параметра
		// TODO: научить работать с массивом
		if (abTest.length === 1) {
			const abTestValue = abTest[0]
			if (abTestValue.active === "yes") {
				const abIndex = getABIndex(abTestValue)
				// get value from AB test with appropriate index
				const valueDTO = configDTO[abTestValue.param]
				const newValueForDomain = this._getDomainUnbox<SortBox<any>[]>(valueDTO)
				const newValueForAbSortIndexTest = this._getSortUnbox(newValueForDomain, abIndex)
				setNewValue(abTestValue.param, newValueForAbSortIndexTest)
			}
		}
		logConfig("got config after a/b test apply", config)

		/* ----------------------- */
		/* apply translations      */
		/* ----------------------- */

		if (!!langCode) {
			const res = this._getValueUnbox(configDTO.webSubscription)
			const labelLocal = res.locales[langCode]
			if (!!labelLocal) {
				if (Array.isArray(config.webSubscription)) {
					// config.webSubscription[0].labels.button = "labelLocal.button"
					// config.webSubscription[0].labels.description = "labelLocal.description"
					// config.webSubscription[0].labels.title = "labelLocal.title"
				} else {
					config.webSubscription.labels.button = labelLocal.button
					config.webSubscription.labels.description = labelLocal.description
					config.webSubscription.labels.title = labelLocal.title
				}
			}
		}

		logConfig("got config after localization", config)

		return config
	}
}

export const dConfigService = new DConfigService()

const logConfig = (message: string, config: IDConfig) => {
	// console.log("#############################################")
	// console.log(`CONFIG SERVICE: ${message}`)
	// console.log(JSON.stringify(config, null, 1))
	// console.log("#############################################")
}
