import { Trans } from "@lingui/macro"
import { PaywallFooter } from "../styles"

// const currentYear = new Date().getFullYear()

export function Copyright() {
	return (
		<PaywallFooter>
			<p>
				{/* Copyright ® 2019-2022 Glowbe. */}
				<Trans>Copyright ® 2019-2022 Glowbe.</Trans>
				<br />
				<Trans>All Rights Reserved</Trans>
			</p>
		</PaywallFooter>
	)
}
