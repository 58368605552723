import { AnalyticsPlugin } from "analytics"
import type { AdvancedMatching, Options } from "react-facebook-pixel"

export type FBPixelConfig = {
	pixelId: string
	matching?: AdvancedMatching
	options?: Options
}
export function facebookPixelPlugin(config: FBPixelConfig): AnalyticsPlugin {
	const { pixelId, matching, options } = config
	let instance: typeof import("react-facebook-pixel")
	let loaded = false
	return {
		name: "facebook-ads",
		config,
		initialize: async () => {
			const module = await import("react-facebook-pixel")
			instance = module.default
			//console.log("Initialize facebook pixel")
			instance.init(pixelId, matching, options)
			loaded = true
		},
		page: () => {
			instance.pageView()
		},
		track: ({ payload: { event, properties } }: any) => {
			instance.track(event, properties)
		},
		identify: () => {},
		loaded: () => loaded,
	}
}
