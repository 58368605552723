import React, { ReactElement, useContext, useEffect, useState } from "react"
import { useQueryParams } from "~/hooks/use-query-params"
import { navigatorLangCode } from "~/services/service/i18n-service"
import { useConfig } from "../config-context"

// TODO: fix types
const QuestionsContext = React.createContext<any | undefined | null>(undefined)

export interface IQuestionsProvider {
	children: ReactElement
	// TODO: add airtable service type
	service: any
}

export function QuestionsProvider({ children, service }: IQuestionsProvider): ReactElement {
	const [questions, setQuestions] = useState<IQuestionsProvider | null>(null)
	const [areas, setAreas] = useState<any[] | null>(null)
	const [goals, setGoals] = useState<any[] | null>(null)

	const config = useConfig()
	const onboardingTypeUrl = useQueryParams().get("onboardingType")
	const onboardingType = !onboardingTypeUrl ? config?.webOnboardingType : config?.webOnboardingTypeMan

	useEffect(() => {
		const fetcher = service.tableFetcher("onboarding_type")
		fetcher.records.then((data: any) => {
			//console.log("___AIR DATA___", data)
			const mappedData = data.map((each: any) => each.fields)
			const foundQuestionsIds = mappedData.find((question: any) => question.id === onboardingType)

			if (foundQuestionsIds) {
				const foundScreensToFilterBy = foundQuestionsIds.screens || []

				service.tableFetcher("onboarding_question").records.then((data: any) => {
					const mappedScreensData = data.map((each: any) => each.fields)
					const filteredScreens = foundScreensToFilterBy.map((screen: any) =>
						mappedScreensData.find((defaultScreen: any) => defaultScreen.id === screen)
					)
					//console.log("___AIR SCREENS___", filteredScreens)
					setQuestions(filteredScreens)
				})
			}
		})
	}, [service, onboardingType])

	const splittedLangCode =
		navigatorLangCode === "zh-CN" || navigatorLangCode === "zh" ? "zh-CN" : navigatorLangCode.split("-")[0]

	useEffect(() => {
		const fetcher = service.tableFetcher("goals")
		fetcher.records.then((data: any) => {
			const mappedData = data.map((each: any) => each.fields)
			const foundGoals = mappedData
				.filter((goal: any) => goal.type === "goal")
				.map((goal: any) => ({
					id: goal.id,
					name:
						splittedLangCode !== "en" && splittedLangCode !== "en-GB"
							? goal[`name_${splittedLangCode}`] || goal.name
							: goal.name,
				}))
			const foundAreas = mappedData
				.filter((area: any) => area.type === "area")
				.map((area: any) => ({
					id: area.id,
					name:
						splittedLangCode !== "en" && splittedLangCode !== "en-GB"
							? area[`name_${splittedLangCode}`] || area.name
							: area.name,
				}))

			//console.log("___AREAS GOALS___", foundAreas, foundGoals)

			setAreas(foundAreas)
			setGoals(foundGoals)
		})
	}, [service, splittedLangCode])

	//console.log("___AREAS GOALS____", areas, goals, splittedLangCode, navigatorLangCode)

	return <QuestionsContext.Provider value={{ questions, areas, goals }}>{children}</QuestionsContext.Provider>
}

export const useQuestions = () => {
	const config = useContext(QuestionsContext)

	if (config === undefined) {
		throw new Error("questions must be used within QuestionsProvider")
	}

	return config
}
