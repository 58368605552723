import { t, Trans } from "@lingui/macro"
import WhiteSpinner from "~/assets/WhiteSpinner.svg"
import { Spinner } from "~/components/buttons/action-button/action-button.styles"
import { DPrepaywallType } from "~/services/config/config-types"

import { StripeSubscriptionPriceInfo } from "~/subscriptions-web/server-api"
import { getLocalizedPlanName } from "~/utils/localize"
import { AlphaPrepaywall } from "../../prepaywalls/alpha-prepaywall/alpha-prepaywall"
import { BravoPrepaywall } from "../../prepaywalls/bravo-prepaywall/bravo-prepaywall"

export interface PrePaywallProps {
	webPrepaywallType: DPrepaywallType
	agreed: boolean
	setAgreed: any
	paywallPrice: StripeSubscriptionPriceInfo
	name?: string
	hideLoading?: boolean
}

export function PrePaywall({
	webPrepaywallType,
	agreed,
	paywallPrice,
	setAgreed,
	name,
	hideLoading = false,
}: PrePaywallProps) {
	const isDaily = paywallPrice && paywallPrice.intervalCount === 1 && paywallPrice.interval === "day"

	const getTitle = () => {
		if (name) return name
		if (isDaily) return t`Daily`

		return (
			paywallPrice &&
			`${paywallPrice?.intervalCount}-${paywallPrice?.interval.charAt(0).toUpperCase()}${paywallPrice?.interval.slice(
				1
			)}`
		)
	}

	const paywallPriceWrapper =
		hideLoading || paywallPrice !== null ? (
			<>
				<Trans>Your</Trans> {getLocalizedPlanName(getTitle())} <br />
				<Trans>Personalized Course</Trans>
			</>
		) : (
			<Spinner alt="" src={WhiteSpinner} />
		)

	switch (webPrepaywallType) {
		case "prepaywall-alpha":
			return <AlphaPrepaywall onClickNext={() => setAgreed(true)} agreed={agreed} paywallPrice={paywallPriceWrapper} />
		case "prepaywall-bravo":
			return <BravoPrepaywall onClickNext={() => setAgreed(true)} agreed={agreed} />
		default:
			return (
				<h1>
					<Trans>ERROR LOADINg PREPAYWALL</Trans>
				</h1>
			)
	}
}
