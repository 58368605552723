import { AnimatePresence } from "framer-motion"
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"

import { Backdrop, ModalContainer } from "./styles"

export type PopupCloseHandler = () => void

export interface PopupRef {
	open: () => void
	close: () => void
	setCloseHandler: (handler: PopupCloseHandler) => void
	setNode: (node: JSX.Element) => void
	openContent: (node: JSX.Element) => void
}

export const PopupComponent = forwardRef<PopupRef>((_, ref) => {
	const [open, setOpen] = useState(false)
	const childrenRef = useRef<JSX.Element | null>(null)
	const closeHandlerRef = useRef<PopupCloseHandler | null>(null)

	useImperativeHandle(ref, () => ({
		open: () => {
			setOpen(true)
		},
		close: () => {
			setOpen(false)
		},
		setNode: (node: JSX.Element) => {
			childrenRef.current = node
		},
		setCloseHandler: (handler: PopupCloseHandler) => {
			closeHandlerRef.current = handler
		},
		openContent: (node: JSX.Element) => {
			childrenRef.current = node
			setOpen(true)
		},
	}))

	useEffect(() => {
		if (open) {
			document.querySelector("body")?.classList.add("no-scroll")
		} else {
			document.querySelector("body")?.classList.remove("no-scroll")
			closeHandlerRef.current?.()
			childrenRef.current = null
			closeHandlerRef.current = null
		}
	}, [open])

	const handleClose = () => setOpen(false)

	return (
		<AnimatePresence exitBeforeEnter>
			{open && (
				<Backdrop onClick={handleClose}>
					<ModalContainer onClick={(e) => e.stopPropagation()}>{childrenRef.current}</ModalContainer>
				</Backdrop>
			)}
		</AnimatePresence>
	)
})
