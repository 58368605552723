// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode"
import { firebaseApp } from "~/services/firebase/firebase-app"
import { callFirebaseFunction } from "./call-firebase-function"
import { RESPONSE_CODE } from "./response-codes"

export interface GetMobileSignInTokenParams {
	token: string
}
export interface GetMobileSignInTokenResponse {
	// This sub enum helps us to control possible error codes returns by the function
	// and provide front-end developers with consitent data.
	code:
		| RESPONSE_CODE.FORBIDDEN
		| RESPONSE_CODE.BAD_REQUEST
		| RESPONSE_CODE.USER_NOT_EXIST
		| RESPONSE_CODE.NOT_FOUND
		| RESPONSE_CODE.NOT_VALID
		| RESPONSE_CODE.TOO_MANY_REQUESTS
		| RESPONSE_CODE.INCORRECT
		| RESPONSE_CODE.UNEXPECTED_ERROR
		| RESPONSE_CODE.SUCCESS

	message: string

	data?: {
		token?: string
		waitTime?: number
	}
}

/**
 * Returns token to sign in. User should exist. When call this function no one user should be signed in.
 * Process the following codes, throw an error in other cases.
 * RESPONSE_CODE.USER_NOT_EXIST
 * RESPONSE_CODE.TOO_MANY_REQUESTS
 * RESPONSE_CODE.INCORRECT
 * RESPONSE_CODE.SUCCESS
 * RESPONSE_CODE.NOT_VALID
 */
export const getMobileSignInToken = async (
	params: GetMobileSignInTokenParams
): Promise<GetMobileSignInTokenResponse> => {
	const response = await callFirebaseFunction<GetMobileSignInTokenParams, GetMobileSignInTokenResponse>(
		"getMobileSignInToken",
		params
	)

	// console.log("+++SUBS PARAMS+++", params)
	// console.log("+++SUBS PARAMS TOKEN+++", params.token)
	// console.log("+++RES TOKEN+++", JSON.parse(atob(params.token.split(".")[1])))
	// console.log("+++RES decode+++", jwt_decode(params.token))

	// localStorage.setItem("email", (jwt_decode(params.token) as any).email)

	// var base64Url = params.token.split(".")[1]
	// var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
	// var jsonPayload = decodeURIComponent(
	// 	window
	// 		.atob(base64)
	// 		.split("")
	// 		.map(function (c) {
	// 			return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
	// 		})
	// 		.join("")
	// )

	// const res = JSON.parse(jsonPayload)

	// console.log("+++RES+++", res)

	// const currentUser = firebaseApp.auth().currentUser
	// const authToken = await currentUser?.getIdToken()
	// const uuid = currentUser?.uid

	// console.log("+++AUTH TOKEN+++", authToken, uuid)

	console.log("+++RESPONSE 0+++", response, params)

	return response
}
