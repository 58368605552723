import { useHistory } from "react-router"
import { useQueryParams } from "~/hooks/use-query-params"
import { EchoLanding } from "./echo-landing"

function buildQuery(
	onboardingTypeURL: string | null,
	email: string | null,
	lang: string | null,
	goalsNames : string | null,
	goalsIds : string | null,
	areasIds : string | null,
	areasNames : string | null,
	amplitudeId: string | null) {
	if (onboardingTypeURL || lang || email) {
		let currPath = ""
		let isSetted = false
		if (onboardingTypeURL) {
			currPath += `onboardingType=${onboardingTypeURL}`
			isSetted = true
		}
		if (lang) {
			if (isSetted) {
				currPath += `&lang=${lang}`
			} else {
				currPath += `lang=${lang}`
			}
			isSetted = true
		}
		if (email) {
			if (isSetted) {
				currPath += `&user_email=${email}`
			} else {
				currPath += `user_email=${email}`
			}
			isSetted = true
		}
		if (goalsNames) {
			if (isSetted) {
				currPath += `&goals_names=${goalsNames}`
			} else {
				currPath += `goals_names=${goalsNames}`
			}
			isSetted = true
		}
		if (goalsIds) {
			if (isSetted) {
				currPath += `&goals_ids=${goalsIds}`
			} else {
				currPath += `goals_ids=${goalsIds}`
			}
			isSetted = true
		}
		if (areasIds) {
			if (isSetted) {
				currPath += `&areas_ids=${areasIds}`
			} else {
				currPath += `areas_ids=${areasIds}`
			}
			isSetted = true
		}
		if (areasNames) {
			if (isSetted) {
				currPath += `&areas_names=${areasNames}`
			} else {
				currPath += `areas_names=${areasNames}`
			}
			isSetted = true
		}
		if (amplitudeId) {
			if (isSetted) {
				currPath += `&amplitude_id=${amplitudeId}`
			} else {
				currPath += `amplitude_id=${amplitudeId}`
			}
			isSetted = true
		}
		return currPath
	} else {
		return null
	}
}

export const EchoLandingContainer = () => {
	const history = useHistory()
	const onboardingType = useQueryParams().get("onboardingType")
	const onboardingTypeURL = useQueryParams().get("onboardingType")
	const queryString = window.location.search
	const urlParams = new URLSearchParams(queryString)
	const goalsNames = urlParams.get("goals_names")
	const amplitudeId = urlParams.get("amplitude_id")
	const goalsIds = urlParams.get("goals_ids")
	const areasIds = urlParams.get("areas_ids")
	const areasNames = urlParams.get("areas_names")
	const lang = urlParams.get("lang")
	const email = urlParams.get("user_email")
	const currPath = buildQuery(onboardingTypeURL,
		email, lang, goalsNames, goalsIds, areasIds, areasNames, amplitudeId)
	const onNext = () => {
		history.push(currPath ? `/personal-course?${currPath}` : "/personal-course")
	}

	return <EchoLanding onNext={onNext} onboardingType={onboardingType} />
}
