import { ActionButton, ActionButtonState } from "@components/buttons"
import { EmailInput } from "@components/inputs"
import { defineMessage, t, Trans } from "@lingui/macro"
import { useEffect, useState } from "react"
import { useWindowLayout } from "~/hooks/use-window-layout"
import { i18nCustom } from "~/services/service/i18n-service"

import { useUpgradeAnonymousUser } from "~/subscriptions-web/hooks/use-upgrade-anonymous-user"
import { callFirebaseFunction } from "~/subscriptions-web/server-api/call-firebase-function"

import { Wrapper, ConfirmationWrapper, Content, Title, Description, Form } from "./confirm-email-screen.styles"

export interface IConfirmEmailScreenProps {
	currentEmail: string
	confirmEmail: (email?: string) => void
	onNext?: () => void
	onPrev?: () => void
}

export const ConfirmEmailScreen = (props: IConfirmEmailScreenProps) => {
	const size = useWindowLayout()

	const { userEmailUpdatingError, isUserEmailUpdating } = useUpgradeAnonymousUser(props.onNext)
	const [email, setEmail] = useState("")
	const [clicked, setClicked] = useState(false)

	const [isNotValid, setIsNotValid] = useState(false)

	const [isEmailSent, setIsEmailSent] = useState(false)

	const sendEmail = async () => {
		if (!isEmailSent) {
			setIsEmailSent(true)
			await callFirebaseFunction("sendManualEmailUser", { email: localStorage.getItem("email") })
		}
	}

	useEffect(() => {
		setEmail(props.currentEmail)
	}, [props.currentEmail])

	const onChangeEmail = (value: string, isNotValid: boolean) => {
		setEmail(value)
		setIsNotValid(isNotValid)
		setClicked(false)
	}

	const onConfirmEmail = async (evt: any) => {
		setClicked(true)

		evt.preventDefault()

		if (!isNotValid) {
			localStorage.setItem("email", email)
			sendEmail()
			// if (!isEmailSent) {
			// 	setIsEmailSent(true)
			// 	await callFirebaseFunction("sendManualEmailUser", { email })
			// }
			props.confirmEmail(email)
		}
	}

	defineMessage({ message: "Updating user email" })
	defineMessage({ message: "Confirm" })

	return (
		<Wrapper size={size}>
			<ConfirmationWrapper size={size}>
				<Form>
					<Content>
						<Title size={size}>
							<Trans>Welcome to Glowbe!</Trans>
							<br />
							<Trans>Confirm your email</Trans>
						</Title>
						<Description size={size}>
							<Trans>Your email address</Trans>
						</Description>
						<EmailInput
							size={size}
							errorPosition={size === "Mobile" ? "close" : "far"}
							placeholder="your@email.com"
							value={email}
							onChange={onChangeEmail}
							showError={clicked}
						/>
						{size === "Desktop" && (
							<span>
								<ActionButton
									size={size}
									type="submit"
									state={isUserEmailUpdating ? ActionButtonState.Disabled : ActionButtonState.Regular}
									onClick={onConfirmEmail}
								>
									{isUserEmailUpdating ? i18nCustom._("Updating user email") : i18nCustom._("Confirm")}
								</ActionButton>
							</span>
						)}
					</Content>
					{size === "Mobile" && (
						<span>
							<ActionButton
								size={size}
								type="submit"
								state={isUserEmailUpdating ? ActionButtonState.Disabled : ActionButtonState.Regular}
								onClick={onConfirmEmail}
							>
								{isUserEmailUpdating ? i18nCustom._("Updating user email") : i18nCustom._("Confirm")}
							</ActionButton>
						</span>
					)}
				</Form>
				{!!userEmailUpdatingError ? userEmailUpdatingError.message : null}
			</ConfirmationWrapper>
		</Wrapper>
	)
}
