import { defineMessage, t, Trans } from "@lingui/macro"
import { TextBody } from "~/components2/atoms/Text"
import { DPaywallType } from "~/services/config/config-types"
import { i18nCustom } from "~/services/service/i18n-service"
import { StripeSubscriptionPriceInfo } from "~/subscriptions-web/server-api"

import { DesktopTrialText, InfoBreak, MobileTrialText, TrialInfo } from "../styles"

export interface TrialInformationProps {
	paywallType: DPaywallType
	// eslint-disable-next-line camelcase
	trialPeriodDays: number
	paywallPrice: StripeSubscriptionPriceInfo | { amount: number; intervalCount: number; interval: string } | null
}

export function TrialInformation({ paywallType, trialPeriodDays, paywallPrice }: TrialInformationProps) {
	const trialDate = new Date(new Date().getTime() + trialPeriodDays * 24 * 60 * 60 * 1000 ?? 0 * 24 * 60 * 60 * 1000)
	const trialMonth = trialDate.getMonth() + 1
	const trialDateText = `${trialDate.getDate()}/${trialMonth < 10 ? "0" : ""}${trialMonth}/${trialDate.getFullYear()}`

	// console.log("___DATE___", trialDate, trialDate.getDate(), new Date().getTime(), trialPeriodDays)

	defineMessage({ message: "Days" })
	defineMessage({ message: "Day" })

	const intervalCount = paywallPrice?.intervalCount ?? 1
	const interval = paywallPrice?.interval ? `${paywallPrice.interval}${intervalCount > 1 ? "s" : ""}` : "day"
	const amount = (paywallPrice?.amount ?? 0) / 100

	// const daily = t`Daily`

	// const period =
	// 	interval === "day" && intervalCount === 1 ? daily.toLowerCase() : t`every` + ` ${intervalCount} ${interval}`

	return (
		<TrialInfo>
			<TextBody size="small" color="#828691">
				<MobileTrialText>
					<Trans>Your</Trans> {trialPeriodDays} <Trans>day</Trans> <Trans>trial will last until</Trans> {trialDateText}.{" "}
					<Trans>
						Cancel the subscription at any time at least 24 hours before the due date if you want to avoid the renewal
						charge.
					</Trans>{" "}
					<Trans>If you decide Glowbe is right for you, on</Trans> {trialDateText} <Trans>you will be charged</Trans>{" "}
					{amount} <Trans>for the next</Trans> {intervalCount} {interval}.{" "}
					<Trans>Glowbe will automatically charge your card</Trans> {amount} <Trans>every</Trans> {intervalCount}{" "}
					{interval}{" "}
					<Trans>until you cancel the subscription so you don't forget the date or lose your progress.</Trans>
				</MobileTrialText>
				<DesktopTrialText>
					<Trans>Your</Trans> {trialPeriodDays} <Trans>day</Trans> <Trans>trial will last until</Trans> {trialDateText}.{" "}
					<Trans>
						Cancel the subscription at any time at least 24 hours before the due date if you want to avoid the renewal
						charge.
					</Trans>{" "}
					<Trans>If you decide Glowbe is right for you, on</Trans> {trialDateText} <Trans>you will be charged</Trans>{" "}
					{amount} <Trans>for the next</Trans> {intervalCount} {interval}.{" "}
					<Trans>Glowbe will automatically charge your card</Trans> {amount} <Trans>every</Trans> {intervalCount}{" "}
					{interval}{" "}
					<Trans>until you cancel the subscription so you don't forget the date or lose your progress.</Trans>
				</DesktopTrialText>
			</TextBody>
		</TrialInfo>
	)
}
