/**
 * General process with client button https://medium.com/analytics-vidhya/paypal-subscription-in-react-1121c39b26be
 * how to cancel subscription in paypal account https://www.youtube.com/watch?v=QFnxBZOq-8I
 */

import React, { useEffect, useState } from "react"
import ReactDOM from "react-dom"

declare global {
	interface Window {
		paypal: any
	}
}

export interface PaypalOptions {
	clientId?: string
	merchantId?: string
	currency?: number | string
	intent?: string
	commit?: boolean | string
	vault?: boolean | string
	component?: string
	disableFunding?: string
	disableCard?: string
	integrationDate?: string
	locale?: string
	buyerCountry?: string
	debug?: boolean | string
}

export type OnApproveData = {
	billingToken?: string | null
	facilitatorAccessToken: string
	orderID: string
	payerID?: string | null
	paymentID?: string | null
	subscriptionID?: string | null
	authCode?: string | null
}
export type OnApproveActions = {
	order: {
		capture: () => Promise<any>
		redirect: (redirectURL: string) => void
		restart: () => void
	}
}
export type OnApproveCallback = (data: OnApproveData, actions: OnApproveActions) => void

export interface PayPalButtonProps {
	ref?: any
	amount?: number | string
	currency?: number | string
	shippingPreference?: "NO_SHIPPING" | "GET_FROM_FILE" | "SET_PROVIDED_ADDRESS"
	onSuccess?: Function
	catchError?: Function
	onError?: Function
	createOrder?: Function
	createSubscription?: Function
	onApprove?: OnApproveCallback
	style?: object
	options?: PaypalOptions
	onButtonReady?: Function
	onClick?: Function
	onCancel?: Function
	// funding?: object,
}

const onApproveLocal = ({
	data,
	actions,
	onSuccess,
	catchError,
}: Pick<PayPalButtonProps, "onSuccess" | "catchError"> & { data: any; actions: any }) => {
	return actions.order
		.capture()
		.then((details: any) => {
			if (onSuccess) {
				return onSuccess(details, data)
			}
		})
		.catch((err: any) => {
			if (catchError) {
				return catchError(err)
			}
		})
}

const createOrderLocal = ({
	data,
	actions,
	currency,
	options,
	amount,
	shippingPreference,
}: Pick<PayPalButtonProps, "currency" | "options" | "amount" | "shippingPreference"> & { data: any; actions: any }) => {
	var currencyCode
	if (currency) {
		currencyCode = currency
	} else if (options && options.currency) {
		currencyCode = options.currency
	} else {
		currencyCode = "USD"
	}

	return actions.order.create({
		purchase_units: [
			{
				amount: {
					currency_code: currencyCode,
					value: amount!.toString(),
				},
			},
		],
		application_context: {
			shipping_preference: shippingPreference,
		},
	})
}

// shippingPreference: "GET_FROM_FILE",
export const PayPalButton = React.forwardRef(
	(
		{
			amount,
			currency,
			shippingPreference = "GET_FROM_FILE",
			onSuccess,
			catchError,
			onError,
			createOrder,
			createSubscription,
			onApprove,
			style,
			options = {
				clientId: "sb",
				currency: "USD",
			},
			onButtonReady,
			onClick,
			onCancel,
		}: // funding,
		PayPalButtonProps,
		ref
	) => {
		const [isSdkReady, setSdkReady] = useState(false)

		useEffect(() => {
			const loadPaypalSdk = () => {
				const queryParams: string[] = []

				// replacing camelCase with dashes
				if (options) {
					Object.keys(options).forEach((k) => {
						const name = k
							.split(/(?=[A-Z])/)
							.join("-")
							.toLowerCase()
						queryParams.push(`${name}=${options[k as keyof PaypalOptions]}`)
					})
				}

				const script = document.createElement("script")
				script.type = "text/javascript"
				script.src = `https://www.paypal.com/sdk/js?disable-funding=credit,card&${queryParams.join("&")}`
				script.async = true
				script.onload = () => {
					setSdkReady(true)

					if (onButtonReady) {
						onButtonReady()
					}
				}
				script.onerror = () => {
					throw new Error("Paypal SDK could not be loaded.")
				}

				document.body.appendChild(script)
			}

			if (typeof window !== "undefined" && window !== undefined && window.paypal === undefined) {
				loadPaypalSdk()
			} else if (
				typeof window !== "undefined" &&
				window !== undefined &&
				window.paypal !== undefined &&
				onButtonReady
			) {
				onButtonReady()
			}
		}, [onButtonReady, options])

		if (!isSdkReady && (typeof window === "undefined" || window.paypal === undefined)) {
			// if paypal sdk is not loaded then we do not render anything.
			return null
		}

		const Button = window.paypal.Buttons.driver("react", {
			React,
			ReactDOM,
		})

		const createOrderFn =
			amount && !createOrder
				? (data: any, actions: any) =>
						createOrderLocal({
							data,
							actions,
							currency,
							options,
							amount,
							shippingPreference,
						})
				: (data: any, actions: any) => createOrder!(data, actions)

		const onApproveFn = onSuccess
			? (data: any, actions: any) =>
					onApproveLocal({
						data,
						actions,
						onSuccess,
						catchError,
					})
			: (data: any, actions: any) => onApprove!(data, actions)

		return (
			<Button
				ref={ref}
				createOrder={createSubscription ? undefined : createOrderFn}
				createSubscription={createSubscription}
				onApprove={onApproveFn}
				style={style}
				onClick={onClick}
				onCancel={onCancel}
				onError={onError}
				// funding={funding}
			/>
		)
	}
)
