/* eslint-disable camelcase */
import { Trans, t } from "@lingui/macro"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import ArrowRight from "~/assets/ArrowRight.svg"
import BackIcon from "~/assets/Back.svg"
import LogoIcon from "~/assets/Logo.svg"
import { Loader } from "~/components/loader/loader"
import { Bubble } from "~/components2/atoms/Bubble"
import { Flexed } from "~/components2/atoms/Flexed"
import { Header } from "~/components2/atoms/Header"
import { Input } from "~/components2/atoms/Input"
import { TextBody, TextHead } from "~/components2/atoms/Text"
import { useAuthContext } from "~/subscriptions-web/context"
import { payproRepository } from "~/subscriptions-web/paypro/repository"
import { callFirebaseFunction } from "~/subscriptions-web/server-api/call-firebase-function"
import {
	Subscription,
	SubscriptionProvider,
	isSubscriptionCancelled,
} from "~/subscriptions-web/subscription/subscription"

export interface ProfileScreenProps {
	subscription: Subscription | null
	isStatusToProvideService: boolean
	isStatusToSuspendService: boolean
	isSubscriptionCancelled: boolean
	canSubscriptionBeCancelled: boolean

	isCanceling: boolean
	cancelSubscription: () => void
	cancelError: string
	email: string
}

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background-color: #f6f6f6;
	box-sizing: border-box;

	z-index: 50;

	overflow: hidden;
`

const Level = styled.div`
	position: absolute;
	bottom: 0;
	left: 0%;

	width: 100%;
	height: calc(100% - 64px);

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	padding-bottom: 44px;
	box-sizing: border-box;

	transition: left 0.4s;

	@media (orientation: landscape) {
		top: 100px;
	}
`

const Level1 = styled(Level)``

const Level2 = styled(Level)`
	left: 100%;
`

const Content = styled.div`
	position: relative;
	width: 100%;
	height: auto;

	padding: 0 20px;

	display: flex;
	flex-direction: column;

	box-sizing: border-box;
	overflow-y: scroll;

	@media (orientation: landscape) {
		width: 480px;
	}

	&::-webkit-scrollbar {
		width: 0px;
	}

	&::-webkit-scrollbar-track {
		background: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: none;
		outline: none;
		border-radius: 50px;
	}
`

// TODO: вынести в отдельный компонент
const Bubbles = styled.div`
	width: 100%;
	height: auto;
	padding: 0;

	> div {
		width: 100%;
		min-height: 60px;
		padding: 10px 20px;
		margin: 0;
		box-sizing: border-box;

		box-shadow: inset 0px 1px 0px #ebebeb;

		> img {
			margin-top: 8px;
		}

		:first-child {
			box-shadow: none;
		}
	}
`

const Title = styled(TextBody)`
	width: 70%;
`

const TopBar = styled.div`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 64px;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	padding: 0;
	box-sizing: border-box;

	@media (orientation: landscape) {
		top: 100px;
	}
`

const GoBack = styled.div`
	position: absolute;
	top: 12px;
	left: 20px;

	width: 40px;
	height: 40px;

	display: flex;
	justify-content: center;
	align-items: center;

	margin: 0;
	border-radius: 32px;
	background-color: rgba(88, 86, 214, 0.08);

	@media (orientation: landscape) {
		left: 80px;
		width: 64px;
		height: 64px;
	}
`

const Logo = styled.img`
	position: relative;
	width: 120px;
	height: auto;

	@media (orientation: landscape) {
		display: none;
	}
`

export const ProfileScreen = ({
	subscription,
	isStatusToProvideService,
	isStatusToSuspendService,
	// isSubscriptionCancelled,
	canSubscriptionBeCancelled,

	cancelSubscription,
	isCanceling,
	cancelError,
	email,
}: ProfileScreenProps) => {
	const [currentLevel, changeLevel] = useState(1)

	const [newEmail, setNewEmail] = useState(email)

	const [localSubscription, setLocalSubscription] = useState<Subscription | null>(null)
	const { currentUser } = useAuthContext()

	const accountRef = useRef<HTMLDivElement>(null)
	const personalRef = useRef<HTMLDivElement>(null)

	const refs = [accountRef, personalRef]

	// console.log("+++PROFILE SUBSCRIPTION 0+++", subscription)

	const getSub = async () => {
		const response: any = await callFirebaseFunction("paddleSubscriptionGetInfo", {})


		// @ts-ignore
		if (!response?.data) {
			return
		}

		const {
			checkout_id,
			subscriptionStartDate,
			subscriptionEndDate,
			status,
			subscription_id,
			subscription_plan_id,
			cancel_url,
		} = (response as any)?.data

		const startDate = new Date(1970, 0, 1)
		const endDate = new Date(1970, 0, 1)

		startDate.setSeconds(subscriptionStartDate._seconds)
		endDate.setSeconds(subscriptionEndDate._seconds)

		const fetchedSubscription: Subscription = {
			id: checkout_id,
			periodStart: startDate,
			periodEnd: endDate,
			status,
			provider: SubscriptionProvider.Paddle,
			planId: subscription_plan_id,
			productId: subscription_id,
			cancelUrl: cancel_url,
		}


		if (response && (response as any).data) {
			setLocalSubscription({ ...fetchedSubscription })
		}
	}

	useEffect(() => {
		if (subscription) {
			setLocalSubscription(subscription)
		} else {
			getSub()
		}
	}, [subscription])

	const goToLevel = (level: "prev" | "next") => {
		const newLevel = level === "prev" ? currentLevel - 1 : currentLevel + 1
		const currentRef = refs[currentLevel - 1]
		const nextRef = refs[newLevel - 1]

		changeLevel(newLevel)

		if (nextRef && nextRef.current && currentRef && currentRef.current) {
			nextRef.current.style.left = "0%"
			currentRef.current.style.left = level === "next" ? "-100%" : "100%"
		}
	}

	const onChangeEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
		const mail = evt.currentTarget.value

		setNewEmail(mail)
	}

	const handleCancelSubscription = async () => {
		if (!localSubscription || isSubscriptionCancelled(localSubscription)) return

		// TODO: check this when integrate with backend
		if (localSubscription.provider === "paypro") {
			await payproRepository.cancelSubscription(currentUser?.uid!, localSubscription.subscriptionId!)
		} else if (localSubscription!.cancelUrl) {
			window.open(localSubscription!.cancelUrl, "_blank")
		} else {
			cancelSubscription()
		}
	}

	const getCancelText = () => {
		if (!localSubscription) {
			return
		}
		if (isSubscriptionCancelled(localSubscription)) {
			return t`Subscription was cancelled`
		}
		return isCanceling ? t`Canceling...` : t`Cancel subscription`
	}

	return (
		<>
			{localSubscription ? (
				<Wrapper>
					<Header desktopSimplified simplified />
					<TopBar>
						{currentLevel > 1 && (
							<GoBack onClick={() => goToLevel("prev")}>
								<img alt="" src={BackIcon} />
							</GoBack>
						)}
						<Logo alt="" src={LogoIcon} />
					</TopBar>
					<Level1 ref={accountRef}>
						<Content>
							<TextHead centered size="small" margin="30px 0 0">
								<Trans>Manage Account</Trans>
							</TextHead>

							<TextBody size="small" color="#828691" margin="24px 0 8px">
								<Trans>Account</Trans>
							</TextBody>

							<Bubble padding="0">
								<Bubbles>
									<Flexed align="flex-start" onClick={() => goToLevel("next")}>
										<Title color="#000000" size="big">
											<Trans>Personal Info</Trans>
										</Title>
										<img alt="" src={ArrowRight} />
									</Flexed>
								</Bubbles>
							</Bubble>

							<TextBody size="small" color="#828691" margin="24px 0 8px">
								<Trans>Your Subscription</Trans>
							</TextBody>

							<Bubble padding="0" margin="0 0 14px">
								<Bubbles>
									<Flexed align="flex-start">
										<Title color="#828691" size="big">
											<Trans>Trial Period</Trans>
										</Title>
										<img alt="" src={ArrowRight} />
									</Flexed>
									<Flexed align="flex-start">
										<Title color="#000000" size="big">
											<Trans>Creation Date</Trans>
										</Title>
										<TextBody size="big" color="#828691">
											{getDateString(localSubscription.periodStart) ?? null}
										</TextBody>
									</Flexed>
									<Flexed align="flex-start">
										<Title color="#000000" size="big">
											<Trans>Expiration Date</Trans>
										</Title>
										<TextBody size="big" color="#828691">
											{getDateString(localSubscription?.periodEnd) ?? null}
										</TextBody>
									</Flexed>
									<Flexed align="flex-start">
										<Title color="#828691" size="big">
											<Trans>Payment Data</Trans>
										</Title>
										<img alt="" src={ArrowRight} />
									</Flexed>
									{localSubscription?.status !== "canceled" && (
										<Flexed
											onClick={handleCancelSubscription}
											align="flex-start"
											// onClick={() => history.push("/cancel")}
										>
											<Title color={isSubscriptionCancelled(localSubscription) ? "#828691" : "tomato"} size="big">
												{/*Cancel Subscription*/}
												{getCancelText()}
												{/* <Trans>Cancel subscription</Trans> */}
											</Title>
										</Flexed>
									)}
								</Bubbles>
							</Bubble>
							<Title style={{ width: "100%", textAlign: "center" }}>
								{localSubscription.status === "canceled" && "Subscription was canceled"}
							</Title>
						</Content>
					</Level1>
					<Level2 ref={personalRef}>
						<Content>
							<TextHead centered size="small" margin="30px 0 0">
								<Trans>Personal Info</Trans>
							</TextHead>

							{/*return (*/}
							{/*    <div>*/}
							{/*        <br />*/}
							{/*        { startDate && `Current period start: ${startDate.toLocaleString()}`}<br />*/}
							{/*        { endDate && `Current period end: ${endDate.toLocaleString()}`}<br />*/}
							{/*        { subscription.status && `Status: ${subscription.status}`}<br />*/}
							{/*        { subscription.provider && `Billing provider: ${subscription.provider}`}<br />*/}

							{/*        { isSubscriptionCancelled && 'Subscription will be
							canceled at end of current period.'}<br />*/}
							{/*        { canSubscriptionBeCancelled && <CancelButton />*/}
							{/*        }*/}
							{/*        {cancelError}*/}
							{/*    </div>*/}
							{/*);*/}
							{/* <TextBody size="small" color="#828691" margin="24px 0 8px">
					 	<Trans>Name</Trans>
						</TextBody>
						<Input value={name} type="login" placeholder={t`Enter name here`} onChange={onChangeName} width="100%" />
					*/}

							<TextBody size="small" color="#828691" margin="24px 0 8px">
								<Trans>Email</Trans>
							</TextBody>
							<Input
								value={newEmail}
								type="email"
								placeholder="youremail@gmail.com"
								readonly
								onChange={onChangeEmail}
								width="100%"
							/>
						</Content>
					</Level2>
				</Wrapper>
			) : (
				<Loader />
			)}
		</>
	)
}

function getDateString(date: Date | undefined) {
	if (date) {
		return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()}.${
			date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
		}.${date.getFullYear()}`
	}
}
