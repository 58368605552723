import { Trans } from "@lingui/macro"
import React, { useEffect, useState } from "react"
import { Loader } from "~/components/loader/loader"
import { amplitudeService } from "~/services/amplitude"

import { useAuthContext } from "~/subscriptions-web/context"
import { stripeSubscriptionCancel } from "~/subscriptions-web/subscription/stripe-subscription-cancel"
// import { paddleSubscriptionCancel } from "~/subscriptions-web/subscription/paddle-subscription-cancel"
import { subscriptionsGetCurrent } from "~/subscriptions-web/subscription/stripe-subscriptions-get-current"
import {
	isSubscriptionStatusToProvideService,
	isSubscriptionStatusToSuspendService,
	Subscription,
	SubscriptionStatus,
} from "~/subscriptions-web/subscription/subscription"
import { ProfileScreen } from "../Screens/profile-screen"

interface ProfilePageState {
	isLoaded: boolean
	subscriptions: Subscription[]
}

interface CancelState {
	isCanceling: boolean
	cancelError: any
}

export const ProfilePage = () => {
	const { currentUser } = useAuthContext()

	const [state, setState] = useState<ProfilePageState>({
		isLoaded: false,
		subscriptions: [],
	})
	const [cancelState, setCancelState] = useState<CancelState>({
		isCanceling: false,
		cancelError: "",
	})

	useEffect(() => {
		if (!state.isLoaded) {
			subscriptionsGetCurrent(currentUser!.uid).then((subscriptions) => {
				setState({
					isLoaded: true,
					subscriptions,
				})
			})
		}
	}, [currentUser, state, setState])

	if (!state.isLoaded) {
		return (
			<Loader />
			// <div>
			// 	<Trans>PROFILE PAGE</Trans>
			// 	<br />
			// 	<br />
			// 	<Trans>loading...</Trans>
			// </div>
		)
	}

	const currSubscription = state.subscriptions.length > 0 ? state.subscriptions[0] : null

	const isStatusToProvideService =
		!!currSubscription && !!currSubscription.status && isSubscriptionStatusToProvideService(currSubscription.status)

	const isStatusToSuspendService =
		!!currSubscription && !!currSubscription.status && isSubscriptionStatusToSuspendService(currSubscription.status)

	const isSubscriptionCancelled = isStatusToProvideService && currSubscription!.status! === SubscriptionStatus.CANCELLED
	const canSubscriptionBeCancelled = isStatusToProvideService && !isSubscriptionCancelled

	const cancelSubscription = async () => {
		if (!canSubscriptionBeCancelled) {
			throw new Error("Error: Subscription can not be canceled. Something wrong in the workflow.")
		}

		setCancelState({
			isCanceling: true,
			cancelError: "",
		})

		const { success, error } = await stripeSubscriptionCancel(currSubscription!)

		if (success && !error) {
			const subscriptions = await subscriptionsGetCurrent(currentUser!.uid)
			setState({
				isLoaded: true,
				subscriptions,
			})
			amplitudeService.logEvent("[All] Button | Subscriptions canceled")
		}
		let cancelError = !!error && typeof error === "string" ? error : ""
		cancelError = !!error && error.message ? error.message : cancelError
		setCancelState({
			isCanceling: false,
			cancelError,
		})
		return success && !error
	}

	return (
		<div>
			<Trans>PROFILE PAGE</Trans>
			<br />
			<br />
			<ProfileScreen
				subscription={currSubscription}
				isStatusToProvideService={isStatusToProvideService}
				isStatusToSuspendService={isStatusToSuspendService}
				isSubscriptionCancelled={isSubscriptionCancelled}
				canSubscriptionBeCancelled={canSubscriptionBeCancelled}
				cancelSubscription={cancelSubscription}
				isCanceling={cancelState.isCanceling}
				cancelError={cancelState.cancelError}
				email={currentUser!.email!}
			/>
		</div>
	)
}
