import { Trans } from "@lingui/macro"
import { PlatformMarket } from "~/components2/atoms/PlatformMarket"
import { TextBody } from "~/components2/atoms/Text"
import { Markets, MarketsWrapper } from "../styles"

export function AvailableOn() {
	return (
		<MarketsWrapper>
			<TextBody centered size="big" color="#828691" margin="40px 0 16px">
				<Trans>Available on</Trans>
			</TextBody>

			<Markets>
				<PlatformMarket platform="ios" />
				<PlatformMarket platform="android" />
			</Markets>
		</MarketsWrapper>
	)
}
