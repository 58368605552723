import React, { Dispatch, forwardRef, SetStateAction, useCallback } from "react"
import { amplitudeService } from "~/services/amplitude"
import { ZipInput } from "./styles"

export type PaywallZipInputProps = React.HTMLProps<HTMLInputElement> & {
	addressZip: [string, Dispatch<SetStateAction<string>>]
}

export const PaywallZipInput = forwardRef(function RxPaywallZipInput(
	{ addressZip, ...props }: PaywallZipInputProps,
	ref: React.ForwardedRef<HTMLInputElement>
) {
	const [addressZipState, setAddressZip] = addressZip

	const handleChange = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setAddressZip(event.currentTarget.value)
		},
		[setAddressZip]
	)

	const handleFocus = useCallback((event: React.FocusEvent<HTMLInputElement>) => {
		amplitudeService.logEvent("[All] Paywall | Zip Code")
	}, [])

	return (
		<ZipInput>
			<input
				ref={ref}
				placeholder="ZIP"
				maxLength={10}
				pattern="\d*"
				onFocus={handleFocus}
				onChange={handleChange}
				value={addressZipState}
				{...props}
			/>
		</ZipInput>
	)
})
