import { Trans } from "@lingui/macro"
import { useState, useEffect } from "react"
import styled from "styled-components"

import { Spinner } from "~/components/buttons/action-button/action-button.styles"
import { i18nCustom } from "~/services/service/i18n-service"
import ImgLogo from "./assets/spinner.svg"

const Wrap = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`

const Title = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;

	margin: 12px 0 0;

	color: #000000;
`

type Props = {
	from?: number
	interval?: number
}

export const CountLoader = ({ from = 5, interval = 1000 }: Props) => {
	const [curTime, setCurrentTime] = useState<number | string>(from)
	useEffect(() => {
		const timer = setInterval(() => {
			setCurrentTime((prev) => {
				const texPref = i18nCustom._("Loading")

				if (typeof prev === "string") {
					let ending = prev.replace(texPref, "")
					return texPref + (ending.length === 1 ? "..." : ending.slice(0, ending.length - 1))
				} else {
					return prev === 1 ? texPref + "..." : prev - 1
				}
			})
		}, interval)

		return () => {
			clearInterval(timer)
		}
	}, [])

	return (
		<Wrap>
			{false && <Trans>Loading</Trans>}
			<Spinner reverse src={ImgLogo} />
			<Title>{curTime}</Title>
		</Wrap>
	)
}
