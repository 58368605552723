import { Elements } from "@stripe/react-stripe-js"
import React, { useEffect, useRef } from "react"
import { useBeforeunload } from "react-beforeunload"
import { BrowserRouter } from "react-router-dom"
import { createGlobalStyle } from "styled-components"
import { Popup, PopupComponent, PopupRef } from "~/components/layouts/popup"
import { ConfigProvider } from "~/contexts/config-context"
import { I18nServiceProvider } from "~/contexts/i18n-service-context"
import { QuestionsProvider } from "~/contexts/questions-context/questions-context"
import { useYandexMetrika, YandexMetrikaProvider } from "~/contexts/yandex-metrika-context"
import { airtableService } from "~/services/airtable"
import { amplitudeService } from "~/services/amplitude"
import { dConfigService } from "~/services/config/config-service"
// import { navigatorLangCode, i18nCustomService } from "~/services/service/i18n-service"
import { i18nCustomService } from "~/services/service/i18n-service"
import { yandexMetrikaService } from "~/services/service/yandex-metrika-service"
import { stripeAppPromise } from "~/subscriptions-web/apps/stripe-app"
import { AuthProvider } from "~/subscriptions-web/context"
import { PreferenceProvider } from "~/subscriptions-web/context"
import { App } from "./organisms/App"

const GlobalStyle = createGlobalStyle`
	html {
		height: -webkit-fill-available;
	}
	body {
		margin: 0;
		font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
			'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
			sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		min-height: 100vh;
		/* mobile viewport bug fix */
		min-height: -webkit-fill-available;
	}
	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
			monospace;
	}
`
export function Root() {
	const popupRef = useRef<PopupRef>(null)
	// useBeforeunload(() => "You'll lose your data!")

	useEffect(() => {
		Popup.applyRef(popupRef)
	}, [])

	const yandexMetrika = useYandexMetrika()
	useEffect(() => {
		yandexMetrika.ymTrackPage("/")
	}, [yandexMetrika])

	useEffect(() => {
		if (process.env.REACT_APP_PADDLE_ID && window.Paddle) {
			console.warn("process.env.REACT_APP_PADDLE_ID", process.env.REACT_APP_PADDLE_ID)
			window.Paddle.Setup({
				vendor: +process.env.REACT_APP_PADDLE_ID,
				eventCallback: function (data: any) {
					amplitudeService.logEvent("[Paddle Test] Paddle eventCallback log", {
						event_name: data.event,
						event_data: data.eventData,
					})
				},
			})
			if (process.env.REACT_APP_PADDLE_ID === "7505") {
				console.warn("@SANBOX")
				window.Paddle.Environment.set("sandbox")
			}
		}

		// const paddleSubscriptionGetCurrent = async (params: any): Promise<any> => {
		// 	const response = await callFirebaseFunction("paddleSubscriptionGetInfo", params)
		// 	// if (response.status !== API_RESPONSE_STATUS.SUCCESS) {
		// 	// 	throw new Error(
		// 	// 		`SERVER returned error: status:${response.status} message:${(response.data as ErrorData).message} code:${
		// 	// 			(response.data as ErrorData).error
		// 	// 		}.`
		// 	// 	)
		// 	// }
		// 	console.log("+++RESPONSE+++", response)
		// 	// return response.data as IGetPayLinkResponse
		// }

		// // paddleSubscriptionGetInfo

		// paddleSubscriptionGetCurrent({})
	}, [])

	// dConfigService.fetchConfig("ru1")

	return (
		<React.StrictMode>
			<GlobalStyle />
			<I18nServiceProvider service={i18nCustomService}>
				<ConfigProvider service={dConfigService}>
					<BrowserRouter>
						<QuestionsProvider service={airtableService as any}>
							<AuthProvider>
								<PreferenceProvider>
									<Elements stripe={stripeAppPromise}>
										<YandexMetrikaProvider service={yandexMetrikaService}>
											<App />
										</YandexMetrikaProvider>
										<PopupComponent ref={popupRef} />
									</Elements>
								</PreferenceProvider>
							</AuthProvider>
						</QuestionsProvider>
					</BrowserRouter>
				</ConfigProvider>
			</I18nServiceProvider>
		</React.StrictMode>
	)
}
