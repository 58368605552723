// import { useHistory } from "react-router-dom"
import { Trans } from "@lingui/macro"
import styled from "styled-components"
import { Header } from "~/components2/atoms/Header"
import { useUserStatus } from "~/subscriptions-web/hooks"
import { OnboardingNavScreen } from "~/views/onboarding/navigation/onboarding-screen/onboarding-nav-screen"
// import { HRollScreen } from "~/views/onboarding/navigation/hroll-screen/hroll-screen"
import { ConfirmEmailScreenFlow } from "~/views/success/screens/confirm-email-screen-flow"
import { DownloadAppScreenContainer } from "~/views/success/screens/download-screen/download-app-screen.container"

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;

	background-color: #f6f6f6;
	box-sizing: border-box;

	z-index: 50;
`

export const PersonalCoursePage = () => {
	const {
		userNotSignedIn,
		userSignedInAnonymously,
		userSignedInWithEmailNotVerified,
		userSignedInWithEmailVerified,
		isProSubscriptionActive,
	} = useUserStatus()

	if (userNotSignedIn || (userSignedInAnonymously && !isProSubscriptionActive)) {
		// 2. IF USER NEVER SPECIFIED IT'S EMAIL AND DO NOT HAVE ACTIVE PURCHASE,
		// EACH TIME THE USER COMES HERE WE ASK IT TO ANSWER QUESTIONS FROM VERY BEGINING,
		// GENERATE COURSE, AND ASK THE USER TO PAY FOR THE COURSE.
		// console.log("onb typ is here", onboardingType)

		return <OnboardingNavScreen />
	}

	if (userSignedInAnonymously) {
		console.log("user signed in anonymously")
	}

	if (userSignedInWithEmailNotVerified) {
		console.log("user signed in with email not verified")
	}

	if (userSignedInAnonymously || userSignedInWithEmailNotVerified) {
		if (isProSubscriptionActive) {
			// 3. IF USER PASSED ONBOARDING AND PURCHASE THE COURSE,
			// BUT NEVER SIGNED IN THROUGH MOBILE APP OR WEB SITE (OVERWISE EMAIL WOULD BE VERIFIED).
			// EACH TIME THE USER COMES HERE WE ASK THE USER TO SPECIFY EMAIL AND DOWNLOAD THE APP.
			// IT'S POSSIBLE USER TRIED TO SIGN IN IN MOBILE APP BUT FOUND OUT THAT SPECIFIED EMAIL BY USER WAS INCORRECT.
			// HERE THE USER CAN ALWAYSE FIX THE MISTAKE UNTILE SIGN IN WITH THE CODE.
			// <SignUpScreen/>
			return <ConfirmEmailScreenFlow />
		} else {
			return <OnboardingNavScreen />
		}
	}

	if (userSignedInWithEmailVerified) {
		// 4. IF USER SIGNED IN THROUGH THE WEB SITE IT CAN ALWAYSE GET THE LINK TO DOWNLOAD THE APP
		return (
			<Wrapper>
				<Header lightBurger desktopSimplified />
				<DownloadAppScreenContainer />
			</Wrapper>
		)
	}

	// 5. DEFAULT CASE - SHOULD HAPPEN NEVER AS ALL CASES ARE COVERED ABOVE
	return (
		<>
			<Trans>Something went wrong. Try to refresh the page and try again.</Trans>
		</>
	)
}
