import { useEffect } from "react"

const payproOrigin = "https://store.payproglobal.com"
const payproMsgEvent = "thankyou-page-reached"

export function usePayproEvent({
	handlePaymentWaiting,
	handlePaymentSuccess,
}: {
	handlePaymentSuccess: () => void
	handlePaymentWaiting: () => void
}) {
	useEffect(() => {
		const onMessage = ({ origin, data }: MessageEvent<any>) => {
			//console.log("event", origin, data)
			if (origin === payproOrigin && data?.event === payproMsgEvent) {
				switch (data.status) {
					case "purchase_successful":
						return handlePaymentSuccess()
					case "purchase_waiting":
						return handlePaymentWaiting()
					case "purchase_unsuccessful":
					default:
						break
				}
			}
		}
		window.addEventListener("message", onMessage)

		return () => {
			window.removeEventListener("message", onMessage)
		}
	}, [])
}
