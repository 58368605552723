import { PayproSubscription } from "~/subscriptions-web/paypro/types"
import { Subscription, SubscriptionProvider, SubscriptionStatus } from "../subscription"

const subscriptionStatusFromPaypro = (data: PayproSubscription): SubscriptionStatus => {
	if (data.valid === false) {
		return SubscriptionStatus.CLOSED
	}

	if (data.cancellationTimestamp) {
		return SubscriptionStatus.CANCELLED
	}

	if (data.trial === true) {
		return SubscriptionStatus.TRIALING
	}

	return SubscriptionStatus.ACTIVE
}

export const subscriptionFromPaypro = (payproRawData: PayproSubscription): Subscription => {
	const subscription: Subscription = {
		id: payproRawData.transactionId.toString(),
		periodStart: new Date(payproRawData.from),
		periodEnd: new Date(payproRawData.to),
		status: subscriptionStatusFromPaypro(payproRawData),
		provider: SubscriptionProvider.Paypro,
		planId: payproRawData.productId,
		productId: payproRawData.productId,
		subscriptionId: payproRawData.originalTransactionId.toString(),
	}

	return subscription
}
