import { CardElement, CardNumberElement } from "@stripe/react-stripe-js"
import {
	CreateTokenCardData,
	Stripe,
	StripeCardElement,
	StripeCardNumberElement,
	StripeElements,
	Token,
} from "@stripe/stripe-js"

export interface CardInfo {
	cardData: "CardElement" | "CardNumberElement"
	name?: string
	addressZip?: string
	addressCountry?: string
}

/**
 * Gets and returns stripe card element that is within Elements provider.
 * If there is no one then throws exception.
 * @param cardData
 */
const getCardElement = (
	elements: StripeElements,
	cardData: "CardElement" | "CardNumberElement"
): StripeCardElement | StripeCardNumberElement => {
	try {
		const stripeCardElement =
			cardData === "CardElement" ? elements.getElement(CardElement) : elements.getElement(CardNumberElement)

		if (!stripeCardElement) {
			throw new Error("STRIPE: No CardElement is rendered in the current Elements provider tree.")
		}

		return stripeCardElement
	} catch (ex) {
		console.error(ex)
		throw ex
	}
}

const createCardToken = async (
	stripe: Stripe,
	stripeCardElement: StripeCardElement | StripeCardNumberElement,
	cardInfo: CardInfo
): Promise<Token> => {
	try {
		let data: CreateTokenCardData = {}
		if (cardInfo.addressZip) {
			data = {
				...data,
				address_zip: cardInfo.addressZip,
			}
		}
		if (cardInfo.addressCountry) {
			data = {
				...data,
				address_country: cardInfo.addressCountry,
			}
		}
		if (cardInfo.name) {
			data = {
				...data,
				name: cardInfo.name,
			}
		}

		const { token, error: tokenError } = await stripe!.createToken(stripeCardElement, data)

		if (tokenError || !token) {
			const msg = tokenError?.message || "STRIPE: Error in the payment data form. Can not create token."
			throw new Error(msg)
		}

		return token
	} catch (ex) {
		console.error(ex)
		throw ex
	}
}

const createPaymentMethod = async (stripe: Stripe, cardTokenId: string) => {
	try {
		const { paymentMethod, error } = await stripe!.createPaymentMethod({
			type: "card",
			card: {
				token: cardTokenId,
			},
			//card: stripeCardElement,
		})

		if (error || !paymentMethod) {
			const msg = error?.message || "STRIPE: Error in the payment data form."
			throw new Error(msg)
		}

		return paymentMethod
	} catch (ex) {
		console.error(ex)
		throw ex
	}
}

/**
 * Creates payment method.
 * If smth fails then throws exception.
 */
export const getPaymentMethodWithCard = async (
	stripe: Stripe,
	elements: StripeElements,
	cardInfo: CardInfo
): Promise<string> => {
	// 1. DEFINE CARD ELEMENT WE USE TO GET PAYMENT DATA.
	// if there is no one then throws exception.
	const cardElement = getCardElement(elements!, cardInfo.cardData)
	//console.log("STRIPE: Card element is got.")

	// 2. CREATE CARD TOKEN
	// if there is an error then throws exception
	const cardToken = await createCardToken(stripe, cardElement, cardInfo)
	//console.log("STRIPE: Card token is created.")

	// 3. CREATE PAYMENT METHOD ON STRIPE SERVER
	// thows an error if there is no paymentMethod
	const paymentMethod = await createPaymentMethod(stripe, cardToken.id)
	//console.log(`STRIPE: Payment method is created ${paymentMethod.id}`)

	return paymentMethod.id
}
