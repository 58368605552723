import { AnalyticsPlugin } from "analytics"

export type TTPixelConfig = {
	pixelId: string
	matching?: any
	options?: {
		debug: boolean
	}
}
export function tiktokPixelPlugin(config: TTPixelConfig): AnalyticsPlugin {
	const { pixelId, matching, options } = config
	let instance: typeof import("tiktok-pixel").default
	let loaded = false
	return {
		name: "tiktok-ads",
		config,
		initialize: async () => {
			const module = await import("tiktok-pixel")
			instance = module.default
			instance.init(pixelId, matching, options)
			loaded = true
		},
		page: () => {
			instance.pageView()
		},
		track: ({ payload: { event, properties } }: any) => {
			instance.track(event, properties)
		},
		identify: () => {},
		loaded: () => loaded,
	}
}
