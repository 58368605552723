import { LayoutType } from "@components/shared/layouts"
import { t } from "@lingui/macro"
import styled, { css } from "styled-components"
import { Block } from "~/components2/atoms/Block"
import { Bubble } from "~/components2/atoms/Bubble"
import { IReview } from "~/components2/atoms/Reviews"
import { TextHead } from "~/components2/atoms/Text"

export const Wrapper = styled.div<{ windowHeight: number }>`
	position: relative;
	width: 100%;
	/* height: auto;
	min-height: 100%; */
	height: ${({ windowHeight }) => windowHeight};
	min-height: ${({ windowHeight }) => windowHeight};

	box-sizing: border-box;
	overflow-y: scroll;
	overflow-x: hidden;

	padding-bottom: 0px;

	&::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	&::-webkit-scrollbar-track {
		background: none;
	}

	&::-webkit-scrollbar-thumb {
		background: none;
		outline: none;
		border-radius: 50px;
	}
`

export const ContentWrapper = styled.div`
	position: relative;
	width: calc(100% + 1px);
	height: 100%;

	box-sizing: border-box;

	background: #f6f6f6;

	@media (orientation: landscape) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&::-webkit-scrollbar {
		width: 0px;
	}

	&::-webkit-scrollbar-track {
		background: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: none;
		outline: none;
		border-radius: 50px;
	}
`

export const Content = styled.div<{ windowHeight: number }>`
	position: absolute;
	width: calc(100% + 1px);
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	/* padding: 20px 20px 0; */
	box-sizing: border-box;
	z-index: 100;
	padding: 0 20px;

	/* top: 100%; */
	top: ${({ windowHeight }) => windowHeight + 100}px;

	@media (orientation: landscape) {
		top: ${({ windowHeight }) => windowHeight}px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	> img {
		margin: 10px 0;
	}

	> p {
		text-align: center;
		margin-bottom: 0;
	}
`

export const NarrowContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	background: #5856ea;
`

export const CharlieCard = styled.img`
	width: 350px;
	height: auto;
	border-radius: 18px;
	margin-bottom: -40px;
`

export const NarrowContent = styled(Content)<{ agreed: boolean }>`
	${({ agreed }) =>
		agreed
			? ""
			: css`
					height: auto;
					overflow-y: scroll;
					padding-bottom: 100px;
			  `};

	margin-top: -26px;

	> img {
		margin-top: 20px;
	}

	&::-webkit-scrollbar {
		width: 0;
	}

	&::-webkit-scrollbar-track {
		background: 0;
	}

	&::-webkit-scrollbar-thumb {
		background: none;
		outline: none;
		border-radius: 50px;
	}

	@media (orientation: landscape) {
		width: 640px;
	}
`

export const PaywallVideoWrapper = styled.div`
	position: relative;
	border-radius: 30px;
	overflow: hidden;
	margin-top: 10px;
	width: auto;
	height: auto;

	> video {
		position: relative;
		max-width: 100%;
		height: auto;
		margin: 0;
	}
`

export const ChoosenItems = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	padding-right: 20px;

	margin-bottom: 12px;
`

export const Item = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 6px 12px;
	font-weight: 500;

	background: #6967ec;
	border-radius: 40px;

	margin: 4px 4px 4px 0;
	color: #ffffff;
`

export const Shadow = styled.div<{ withShadow?: boolean }>`
	position: fixed;
	bottom: 0;
	width: 100%;
	height: auto;
	padding: 44px 0 30px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 200;

	background: ${({ withShadow }) =>
		withShadow ? "linear-gradient(to top, rgba(88, 86, 234, 1), rgba(88, 86, 234, 1), rgba(88, 86, 234, 0))" : "none"};

	@media (orientation: landscape) {
		width: 720px;
		left: calc(50% - 360px);
	}
`

export const Background = styled.div<{ agreed: boolean }>`
	position: ${({ agreed }) => (agreed ? "absolute" : "fixed")};
	top: 0;
	right: 0;
	width: 100%;
	height: auto;
	z-index: 10;

	box-sizing: border-box;

	background: #5856ea;

	@media (max-width: 320px) {
		height: 140%;
	}
`

export const PinkCircleWrapper = styled.img`
	position: absolute;
	top: 0;
	right: 0;

	/* TODO: refactor later */
	display: none;

	@media (orientation: landscape) {
		display: none;
	}
`

export const PinkCircleDesktopWrapper = styled.img`
	position: absolute;
	top: 70px;
	left: 0;

	@media (orientation: portrait) {
		display: none;
	}
`

export const OrangeCircleDesktopWrapper = styled.img`
	position: absolute;
	top: -10%;
	right: -30%;
	width: 60%;

	@media (orientation: portrait) {
		display: none;
	}
`

export const WhiteStarWrapper = styled.img`
	position: absolute;
	top: 50%;
	right: 0;

	@media (orientation: landscape) {
		display: none;
	}
`

export const DesktopWhiteStarWrapper = styled.img`
	position: absolute;
	top: 505px;
	left: 136px;

	@media (orientation: portrait) {
		display: none;
	}
`

export const PinkStarWrapper = styled.img`
	position: absolute;
	top: 103px;
	right: 206px;

	@media (orientation: portrait) {
		display: none;
	}
`

export const DarkArea = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	z-index: 300;

	opacity: 0;
	visibility: hidden;

	transition: opacity 0.4s;

	@media (orientation: landscape) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`

export const Popup = styled.div`
	position: absolute;
	top: -100%;
	left: 20px;
	width: calc(100% - 40px);
	height: auto;
	padding: 20px;

	transition: top 0.4s;

	background: #ffffff;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 24px;
	box-sizing: border-box;

	@media (orientation: landscape) {
		position: relative;
		width: 483px;
		left: 0;

		box-sizing: content-box;
	}

	> p {
		color: #828691;
		margin-top: 0;

		@media (max-width: 320px) {
			font-size: 16px;
			line-height: 26px;
			margin-bottom: 0;

			:first-child {
				font-size: 26px;
				line-height: 28px;
			}
		}
	}

	> div {
		width: 100%;
		margin-bottom: 0;
	}
`

export const ExpandedArea = styled.div`
	position: relative;
	width: 100%;
	margin: 70px 0 0;
	margin: 0;
	/* padding: 0; */

	box-sizing: border-box;

	@media (orientation: landscape) {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`

export const TimeWrapper = styled.div<{ windowHeight: number; isDark?: boolean }>`
	position: relative;
	display: inline-block;
	width: calc(100% + 40px);
	margin: -1px 0 -100px -20px;
	padding: 0 20px;
	box-sizing: border-box;

	@media (orientation: portrait) {
		top: -100px;
	}

	@media (orientation: landscape) {
		display: flex;
		justify-content: center;
		align-items: center;
		margin: -1px 0 0 0;
		/* top: -80px; */
		top: 0;
		margin-bottom: 20px;
		margin-top: 0;
	}

	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 50%;
		background: ${({ isDark }) => (isDark ? "#32237D" : "#5856ea")};
		top: 0;
		left: 0;
	}
`

export const TimeBlock = styled(Bubble)`
	background: #ffffff;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 16px;
	padding: 16px;
	margin: 40px 0;

	@media (orientation: landscape) {
		width: 640px;

		> p {
			max-width: 420px;

			&:nth-child(2) {
				position: absolute;
				right: 18px;
				top: 14px;
				margin: 0;
			}
		}
	}
`

export const TrialInfo = styled.div`
	margin-bottom: 24px;
`

export const Markets = styled.div`
	width: 100%;
	@media (orientation: portrait) {
		> div:first-child {
			margin-bottom: 12px;
		}
	}

	@media (orientation: landscape) {
		display: flex;
		flex-direction: row;

		> div:first-child {
			margin-right: 12px;
		}
	}
`

export const WideBlock = styled(Block)`
	position: relative;

	> p {
		font-size: 20px !important;
		line-height: 24px !important;
		margin-bottom: 10px;
		/* opacity: 0.4; */
	}

	> div > div {
		font-size: 20px !important;
		line-height: 24px !important;
	}

	&:last-child {
		/* background: #5856ea; */
		@media (orientation: portrait) {
			width: calc(100% + 40px);
			padding: 0 20px 100px;
		}

		box-sizing: border-box;
	}
`

export const NarrowBlock = styled(WideBlock)`
	@media (orientation: landscape) {
		width: 380px;

		/* border: 3px solid red; */
	}
`

export const MediumBlock = styled(Block)`
	@media (orientation: landscape) {
		width: 480px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
`

export const DesktopLogo = styled.img`
	@media (orientation: portrait) {
		display: none;
	}

	position: absolute;
	top: 44px;
	left: 80px;
`

export const MobileLogo = styled.img`
	@media (orientation: landscape) {
		display: none;
	}
`

export const monthNames = [
	t`January`,
	t`February`,
	t`March`,
	t`April`,
	t`May`,
	t`June`,
	t`July`,
	t`August`,
	t`September`,
	t`October`,
	t`November`,
	t`December`,
]

export const benefits = [t`Focusing on Skincare Routine`, t`Hundreds of exercises and articles`, t`24/7 expert support`]
export const charlieBenefits = [
	t`Unlimited access to content`,
	t`Exclusive personal course and mini courses`,
	t`100+ useful exercises`,
	t`24/7 expert support`,
	t`New content every month`,
	t`Cancel anytime`,
]
export const echoPostBenefits = [t`After each payment the check will sent on your e-mail`, t`Cancel anytime`]

export const reviewData: IReview[] = [
	{
		title: t`Amazing experience`,
		date: "07/07/2020",
		author: t`laquitaelliott`,
		comment: t`It’s amazing! Doing my second week of massages now. I’m WAY less puffy in the mornings!`,
	},
	{
		title: t`Love it!`,
		date: "21/05/2020",
		author: t`Nadine Petrolli`,
		comment: t`👍 Simple exercises, clear instructions, visible effect.`,
	},
	{
		title: t`Easy to follow `,
		date: "24/11/2020",
		author: t`madizen655`,
		comment: t`I’m 25 and have had allergies my whole life that have puffed up my face. Doing these lymph draining exercises have helped so much. I’m seeing results after one week!`,
	},
	{
		title: t`Relaxing`,
		date: "14/12/2020",
		author: t`wonder1woman`,
		comment: t`Tbh, at first I thought the subscription was too expensive, but now I can do myself what I used to pay my beautician 20 bucks per visit for. Win-win 🙂 `,
	},
	{
		title: t`I love this!`,
		date: "02/09/2020",
		author: t`veganism008`,
		comment: t`Love it! The exercises and massages are simple, so I enjoy doing them everyday. They have given me amazing results. Thank you so much for bringing them to us.`,
	},
	{
		title: t`Amazing`,
		date: "17/01/2021",
		author: t`EsraaSultan`,
		comment: t`I enjoyed the journey and loved it, each day my face feel smother and firmer`,
	},
	{
		title: t`Love`,
		date: "04/05/2020",
		author: t`614hart`,
		comment: t`I can literally feel the circulation in my face! I feel really relaxed and excited to add this to my daily self-care`,
	},
	{
		title: t`Best app`,
		date: "11/02/2021",
		author: t`Iman Morsi`,
		comment: t`It is very simple and you can see immediate results , no chemicals`,
	},
]

export const PaymentBubble = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: auto;

	padding: 20px;

	box-sizing: border-box;

	background: #ffffff;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 24px;
`

export const PaymentBlock = styled.div<{ fullwidth?: boolean }>`
	/* margin-top: 16px; */
	display: flex;
	flex-direction: column;

	margin: 0;

	${({ fullwidth }) =>
		fullwidth
			? css`
					width: 100%;
			  `
			: css`
					width: 50%;
					:last-child {
						margin-left: 8px;
					}
			  `}
`

export const PaymentTitle = styled.p`
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 24px;
	line-height: 34px;
	letter-spacing: -0.03em;

	color: #000000;

	margin-bottom: 6px;
	margin-top: 0;
`

export const PaymentLogos = styled.div<{ paywallType?: string }>`
	display: flex;
	flex-direction: row;
	margin: 0;

	${({ paywallType }) =>
		paywallType && paywallType === "bravo"
			? css`
					> img {
						width: 137px;
						height: 30px;
						margin-right: 6px;
					}
			  `
			: css`
					> img {
						width: 44px;
						height: 28px;
						margin-right: 6px;
					}
			  `}
`

export const PaymentLabel = styled.p`
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	letter-spacing: -0.03em;
	text-transform: capitalize;

	color: #828691;

	opacity: 0.5;

	width: 100%;

	margin: 16px 0 4px;
`

export const PaymentDataInput = styled.div`
	position: relative;
	padding: 14px 20px;
	background: #f6f6f6;
	border-radius: 16px;

	> img {
		position: absolute;
		top: calc(50% - 7px);
		right: 22px;
		width: 9px;
		height: 14px;
	}
`

export const PaymentMultiInputWrapper = styled.div`
	position: relative;
	width: calc(100% - 20px);
	padding: 14px 0 14px 20px;
	background: #f6f6f6;
	border-radius: 16px;
	display: block;
	box-sizing: content-box;
`

export const ExpirationInput = styled.div`
	display: inline-block;
	width: 36%;
`

export const CVCInput = styled.div`
	display: inline-block;
	width: 28%;

	box-sizing: border-box;
`

export const ZipInput = styled.div`
	position: relative;
	display: inline-block;
	box-sizing: border-box;
	width: 36%;

	> input {
		position: absolute;
		top: -6px;
		display: block;
		width: 100%;

		margin-top: -20px;

		background: #f6f6f6;
		font-weight: 500;
		/* border-radius: 16px; */
		color: #32325d;
		font-style: normal;
		font-family: Helvetica, sans-serif !important;
		font-size: 21px;
		/* padding: 14px 20px; */
		::placeholder {
			color: #aab7c4;
		}

		box-sizing: border-box;

		border: none;
		outline: none;
	}
`

export const TextBreak = styled.div`
	margin: 6px 0;
	position: relative;
	display: block;
`

export const MobileTrialText = styled.span`
	font-size: 16px !important;
	@media (orientation: landscape) {
		display: none;
	}
`
export const DesktopTrialText = styled.span`
	@media (orientation: portrait) {
		display: none;
	}
`

export const MarketsWrapper = styled.div`
	width: 744px;

	@media (orientation: portrait) {
		display: none;
	}
`

export const TimerBox = styled.div`
	position: fixed;
	top: -40px;
	left: calc(50% - 126px);
	width: 254px;
	height: 40px;
	background: #ffffff;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.05);
	border-radius: 12px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 6px 12px;
	box-sizing: border-box;
	z-index: 9999;
	transition: top 0.3s;

	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: -0.03em;

	color: #1e1e1e;

	text-align: center;

	@media (orientation: landscape) {
		left: auto;
		right: 20px;
	}

	> p {
		display: inline-block;
	}

	> span {
		display: inline-block;
		margin: 0 0 0 6px;
		font-variant-numeric: tabular-nums;
		color: #5856ea;
	}
`

export const PaymentError = styled.p`
	width: 100%;
	text-align: center;
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 18px;
	line-height: 28px;
	letter-spacing: -0.03em;
	color: #fd3e3e;
`

export const InfoBreak = styled.div`
	width: 100%;
	height: 12px;
`

export const InfoTitle = styled.span`
	color: black;
`

export const PaywallFooter = styled.div`
	width: 100%;
	height: auto;
	padding: 0;

	margin: 36px 0 44px 0;

	> p {
		margin: 0;
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 18px;
		line-height: 26px;
		text-align: center;
		letter-spacing: -0.03em;

		color: #828691;
	}

	a {
		text-decoration: none;
		color: #302478;
	}
`

export const MobileBreak = styled.br`
	@media (orientation: landscape) {
		display: none;
	}
`

export const ReviewsWrapper = styled.div`
	@media (orientation: landscape) {
		width: 744px;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;

		> p {
			display: none;
		}
	}
	margin-bottom: 40px;
`

export const TryTitle = styled(TextHead)<{ layout: keyof typeof LayoutType }>`
	margin: ${({ layout }) => (layout === "Mobile" ? "-40px 0 14px" : "-20px 0 14px")};
`

export const PriceBubble = styled(Bubble)<{ margin: string }>`
	padding: 0;
	margin: ${({ margin }) => margin};

	&:first-child {
		margin: 40px 0 0;
	}
`

export const PriceTitle = styled.p`
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: -0.03em;

	color: #000000;

	margin: 0;

	border-bottom: 1px solid #ebebeb;

	padding: 8px 16px 8px;
`

export const Price = styled.span`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 14px 16px 16px;

	> span {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: flex-start;

		&:last-child {
			align-items: flex-end;
		}
	}
`

export const PriceLength = styled.span`
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	letter-spacing: -0.03em;

	color: #828691;

	margin: 0 0 2px;
`

export const PriceSale = styled.span<{ isSale?: boolean }>`
	display: inline;
	padding: 4px 10px;
	width: auto;

	background: ${({ isSale }) => (isSale ? "#15CE28" : "#ececec")};
	color: ${({ isSale }) => (isSale ? "#ffffff" : "#000000")};
	border-radius: 40px;
	box-sizing: border-box;
`

export const PriceValue = styled.span`
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	text-align: right;
	letter-spacing: -0.03em;

	color: #5856d6;
`

export const PriceOld = styled.span`
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 28px;
	text-align: right;
	letter-spacing: -0.03em;

	color: #828691;

	opacity: 0.4;
`

export const PriceOldSmall = styled(PriceOld)`
	font-size: 16px;
	line-height: 24px;
`

export const Crossed = styled.span<{ color?: string }>`
	text-decoration: line-through;
	text-decoration-color: ${({ color = "currentColor" }) => color};
`

export const PriceInfo = styled.p`
	font-family: Inter, sans-serif;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	/* or 150% */
	text-align: center;
	letter-spacing: -0.03em;

	color: #828691;

	margin: 12px 0 24px;
`

export const PriceSeparator = styled.div`
	height: 12px;
`

export const PaymentMethodsHeader = styled.div`
	width: 100%;
	margin-top: 48px;

	> p {
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 28px;
		line-height: 110%;
		/* identical to box height, or 31px */

		text-align: center;
		letter-spacing: -0.04em;

		color: #000000;
		margin: 0;

		width: 100%;
	}

	> small {
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		/* identical to box height, or 140% */

		text-align: center;
		letter-spacing: -0.03em;

		color: #828691;

		display: block;
		width: 100%;
		margin: 8px 0 24px;
	}

	/* stylelint-disable-next-line no-descending-specificity */
	> span {
		width: 100%;
		height: 1px;
		background: #dddddd;

		display: flex;
		justify-content: center;
		align-items: center;

		margin: 35px 0 0;
	}
`

export const PaymentMethods = styled.div`
	position: relative;
	width: 100%;
	box-sizing: border-box;
	height: auto;
	/* padding: 20px; */
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 18px;
	/* display: none; */
`

export const PaymentMethodWrapper = styled.div`
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	width: calc(50% - 12px);
	height: 55px;
	margin: 6px;
	box-sizing: content-box;
`

export const PaymentPayPal = styled(PaymentMethodWrapper)`
	height: 48px;
	width: calc(50% + 12px);
	box-sizing: border-box;
	margin-top: 4px;
	top: 4px;
`

export const PaymentMethod = styled(PaymentMethodWrapper)<{ active?: boolean; isPaypal?: boolean }>`
	background: #ffffff;
	box-shadow: 0px 1.3985px 5.59402px rgba(0, 0, 0, 0.08), 0px 1.3985px 4.19551px rgba(0, 0, 0, 0.02);
	border-radius: 12px;

	padding: 0 25px;

	> img:nth-child(2) {
		margin-left: 10px;
	}

	z-index: 2;

	${({ active }) =>
		active
			? css`
					:before {
						content: "";
						position: absolute;
						top: -6px;
						left: -6px;
						width: 100%;
						height: 100%;
						border: 6px solid #c4c4c4;
						opacity: 0.24;
						border-radius: 16px;
						z-index: 1;
					}
			  `
			: ""}
`

export const MarketsSimple = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	width: 100%;
	height: auto;

	margin-bottom: 24px;

	/* stylelint-disable-next-line no-descending-specificity */
	> div {
		position: relative;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		/* stylelint-disable-next-line no-descending-specificity */
		> span {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;

			font-family: Inter, sans-serif;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			letter-spacing: -0.03em;

			color: #000000;

			/* stylelint-disable-next-line no-descending-specificity */
			> img {
				margin-right: 10px;
			}

			&:nth-child(2) {
				margin-left: 22px;
			}
		}
	}
`

export const MarketBubble = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	background: #ffffff;
	border-radius: 12px;

	padding: 6px 10px;
	box-sizing: border-box;

	margin: 12px 3px 0;

	> p {
		margin: 0;
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.03em;

		color: #000000;

		/* stylelint-disable-next-line no-descending-specificity */
		> span {
			color: #b7b9c0;
		}
	}

	/* stylelint-disable-next-line no-descending-specificity */
	> img {
		max-height: 16px;
		width: auto;
		margin-right: 8px;
	}
`

export const PayPalButtonWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: calc(50% - 12px);
	min-width: calc(50% - 12px);
	height: 55px;
	margin: 6px;

	box-sizing: content-box;

	background: #ffffff;
	box-shadow: 0px 1.3985px 5.59402px rgba(0, 0, 0, 0.08), 0px 1.3985px 4.19551px rgba(0, 0, 0, 0.02);
	border-radius: 12px;

	/* padding: 0 25px; */
	padding: 0;
	/* overflow: hidden; */

	z-index: 3000;

	/* stylelint-disable-next-line no-descending-specificity */
	> img {
		pointer-events: none;
		z-index: 2000;
		width: auto;
		height: 26px;
		position: absolute;
		/* top: calc(50% - 13px); */
		/* left: 0; */
		margin-left: 0;
	}

	/* filter: invert(1); */
`

export const PayPalButtonContainer = styled.div`
	position: absolute;
	width: 100%;
	min-width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 4000;
	box-sizing: border-box;
	padding: 0;

	/* border: 2px solid red; */

	/* cursor: pointer; */

	> button {
		margin: 0;
	}
	opacity: 0;
`

export const ApplePayWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 200px;

	margin-top: 46px;
	margin-bottom: 16px;

	> p {
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: bold;
		font-size: 28px;
		line-height: 110%;

		text-align: center;
		letter-spacing: -0.04em;

		color: #000000;
		margin: 0;

		width: 100%;
	}

	> small {
		font-family: Inter, sans-serif;
		font-style: normal;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;

		text-align: center;
		letter-spacing: -0.03em;

		color: #828691;

		display: block;

		width: 100%;

		margin: 8px 0 24px;
	}

	/* stylelint-disable-next-line no-descending-specificity */
	> span {
		width: 100%;
		height: 1px;
		background: #dddddd;

		display: flex;
		justify-content: center;
		align-items: center;

		margin: 35px 0 0;
	}
`

export const ApplePayWrapperEcho = styled(ApplePayWrapper)`
	height: auto;
	margin-top: 24px;
	margin-bottom: 32px;

	> span p {
		background-color: #f6f6f6;
		padding: 0 12px;
		font-family: Inter, sans-serif;
		font-weight: 500;
		font-size: 20px;
		line-height: 28px;
		color: #828691;
	}
`

export const ApplePayBtn = styled.div`
	position: relative;
	width: 100%;
	height: 56px;

	background: #000000;
	box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.06), 0px 10px 16px rgba(88, 86, 214, 0.16);
	border-radius: 16px;

	display: flex;

	justify-content: center;
	align-items: center;

	/* stylelint-disable-next-line no-descending-specificity */
	> img {
		height: 28px;
		width: auto;
	}
`
